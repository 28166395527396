<template>
    <v-text-field
        v-model="text"
        outlined
        :disabled="!!exportedAt"
        :persistent-hint="!!exportedAt"
        :hint="exportedAt ? $t('saleam.exportedAt', { date: exportedAt }) : ''"
        :label="label || $t('saleam.id')" />
</template>

<script>

export default {
    name: 'hdv-sale-am-vacation-id',
    props: {
        value: { type: String },
        label: { type: String },
        exportedAt: { type: [String, Date] }
    },
    computed: {
        text: {
            get() {
                return this.value
            },
            set(v) {
                const value = (v || '').toUpperCase().replace(/[^A-Z0-9-]/g, '')
                this.$emit('input', value)
            }
        }
    }
}
</script>
