<template>
    <v-container fluid>
        <v-row>
            <hdv-base-secondary-menu>
                <template #content>
                    <hdv-sale-main-menu :disabled="!selected" />
                </template>
            </hdv-base-secondary-menu>
            <v-col class="pa-0 hdv-container">
                <router-view @toggle-left="v => left = v"></router-view>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import HdvSaleMainMenu from '@/components/sale/HdvSaleMainMenu'
import HdvBaseSecondaryMenu from '@/components/base/HdvBaseSecondaryMenu'

export default {
    name: 'sale-home',
    components: {
        HdvSaleMainMenu,
        HdvBaseSecondaryMenu
    },
    data: () => ({
        left: true
    }),
    computed: {
        selected() {
            return this.$store.getters['sale/selected']
        }
    },
    watch: {
        $route() {
            this.$route.meta.secondaryMenu = 'hdv-sale-main-menu'
        }
    },
    mounted() {
        this.$route.meta.secondaryMenu = 'hdv-sale-main-menu'
    },
    async beforeRouteEnter(to, from, next) {
        from.meta.secondaryMenu = 'hdv-sale-main-menu'
        to.meta.secondaryMenu = 'hdv-sale-main-menu'
        next()
    }
}
</script>

<style lang="sass">
.markdown ul li, .markdown ul li p
    margin-bottom: 0 !important

.hdv-container
    height: calc(100vh - 64px)
    overflow-x: hidden
    overflow-y: auto

</style>
