<template>
    <hdv-base-actionable-list-item
        :enabled="action"
        :to="sorting ? undefined : { name: 'contentstoryform', params: { contentId: content.id } }">
        <v-list-item-avatar rounded>
            <v-img v-if="content.image_url_preview" :src="content.image_url_preview | imagecrop({ w: 80, f: 'cover' })" />
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title>{{ content.title }}</v-list-item-title>
            <v-list-item-subtitle>{{ date }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="unactionable">
            <v-btn icon>
                <v-icon color="primary">$vuetify.icons.baseRight</v-icon>
            </v-btn>
        </v-list-item-action>
    </hdv-base-actionable-list-item>
</template>

<script>
import HdvBaseActionableListItem from '@/components/base/HdvBaseActionableListItem'

export default {
    name: 'acs-content-story-list-item',
    components: { HdvBaseActionableListItem },
    props: {
        content: { type: Object },
        action: { type: Boolean },
        sorting: { type: Boolean }
    },
    computed: {
        lang() {
            return this.$store.getters['ln/currentWithRegion']
        },

        date() {
            const formatter = new Intl.DateTimeFormat(this.lang, {
                dateStyle: 'full'
            })
            return formatter.format(new Date(this.content.date))
        }
    }
}
</script>
