<template>
    <v-card>
        <v-form v-model="valid" @submit.stop.prevent="submit">
            <v-card-text v-if="am && sale">
                <h3 class="text-h5 mb-5">{{ $t('saleam.intro') }}</h3>
                <div v-marked="$t('saleam.introText')"></div>
                <div v-for="vacation in am" :key="vacation.id">
                    <hdv-sale-am-vacation-id
                        v-model="vacation.am_id"
                        :label="vacation.title" />
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    rounded
                    color="primary"
                    type="submit"
                    :disabled="!valid"
                    :loading="loading">
                    {{ $t('base.save') }}
                </v-btn>
                <v-spacer />
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
import HdvSaleAmVacationId from '@/components/sale-am/HdvSaleAmVacationId'

export default {
    name: 'sale-am',
    components: {
        HdvSaleAmVacationId
    },
    props: {
        saleId: { type: [String, Number] },
        disabled: { type: Boolean }
    },
    data: () => ({
        loading: false,
        valid: false,
        sale: null,
        am: null
    }),
    watch: {
        saleId: 'load'
    },
    mounted() {
        this.load()
    },
    methods: {
        load() {
            if (this.disabled) {
                return
            }
            this.loading = true
            return Promise
                .all([
                    this.$store.dispatch('sale/get', { id: this.saleId }),
                    this.$store.dispatch('sale/getAm', { id: this.saleId })
                ])
                .then(data => {
                    this.sale = data[0]
                    this.am = data[0].vacations.map(vacation => {
                        const am = data[1].find(a => a.vacation_id === vacation.id) || {}
                        return {
                            id: vacation.id,
                            title: vacation.title,
                            am_id: am.am_id,
                            exported_at: am.exported_at
                        }
                    })
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        submit() {
            this.loading = true
            return this.$store.dispatch('sale/updateAm', { saleId: this.sale.id, am: this.am })
                .then(sale => {
                    this.$success(sale, { message: 'saleam.saved' })
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
