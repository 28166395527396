<template>
    <v-navigation-drawer
        app
        dark
        permanent
        v-model="drawer"
        :mini-variant="mini">

        <v-list class="pt-0">
            <v-toolbar flat>
                <v-btn icon text @click="mini = !mini">
                    <v-icon v-if="mini">$vuetify.icons.baseMenuRight</v-icon>
                    <v-icon v-else>$vuetify.icons.baseMenuLeft</v-icon>
                </v-btn>
            </v-toolbar>

            <v-list-group
                v-for="(group, gindex) in items"
                :key="gindex"
                color="menuActive"
                group="/"
                :prepend-icon="group.icon">
                <template #activator>
                    <v-list-item-title>{{ $t(group.text) }}</v-list-item-title>
                </template>
                <template v-for="(item, index) in group.items">
                    <v-divider v-if="item.divider" :key="index" />
                    <v-list-item v-else
                        :key="item.text"
                        :to="item.to"
                        @click="(item.click && item.click(item)) || null">
                        <v-list-item-action>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list-group>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    name: 'hdv-base-main-menu',
    props: {
        value: { type: Boolean }
    },
    data: () => ({
        drawer: true,
        mini: true
    }),
    watch: {
        mini(v) {
            this.$emit('mini', v)
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'menu.website',
                    icon: '$vuetify.icons.menuWebsite',
                    items: [
                        { to: { name: 'salehome' }, icon: '$vuetify.icons.sale', text: 'sale.menu' },
                        { to: { name: 'department' }, icon: '$vuetify.icons.department', text: 'department.menu' },
                        { to: { name: 'teamlist' }, icon: '$vuetify.icons.team', text: 'team.menu' },
                        { to: { name: 'eventlist' }, icon: '$vuetify.icons.event', text: 'event.menu' },
                        { to: { name: 'contentstory' }, icon: '$vuetify.icons.story', text: 'contentstory.menu' }
                        /*
                        { to: { name: 'content' }, icon: '$vuetify.icons.component', text: 'content.menu' },
                        { to: { name: 'contentcategory' }, icon: '$vuetify.icons.contentCategory', text: 'contentcategory.menu' },
                        { to: { name: 'contentfaq' }, icon: '$vuetify.icons.faq', text: 'contentfaq.menu' },
                        { to: { name: 'contentpage' }, icon: '$vuetify.icons.page', text: 'contentpage.menu' }
                        */
                    ]
                }
            ]
        }
    }
}
</script>
