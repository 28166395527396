<template>
    <v-alert text color="info">
        <v-row align="center" no-gutters>
            <v-col class="grow">
                <h3 class="text-h5">{{ $t('teamnx.title') }}</h3>
                <p v-if="!id">{{ $t('teamnx.import') }}</p>
                <p v-else v-marked="$t('teamnx.imported')"></p>
            </v-col>
            <v-col class="shrink">
                <v-btn
                    outlined
                    rounded
                    color="info"
                    @click="importFromNx"
                    :loading="loading">
                    {{ $t('teamnx.btn') }}
                </v-btn>

                <v-btn
                    v-if="id"
                    rounded
                    color="warning"
                    @click="remove"
                    :disabled="loading">
                    {{ $t('team.remove') }}
                </v-btn>
            </v-col>
        </v-row>
    </v-alert>
</template>

<script>
export default {
    name: 'hdv-team-nx-import',
    props: {
        id: { type: [String, Number] },
        nxId: { type: [String, Number] }
    },
    data: () => ({
        loading: false
    }),
    methods: {
        importFromNx() {
            this.loading = true
            return this.$store.dispatch('team/import', { id: this.id, nxId: this.nxId })
                .then(team => {
                    if (!this.id) {
                        // on redirige sur la page d'édition de la team web
                        this.$router.replace({ name: 'teamform', params: { teamId: team.id } })
                    }
                    this.$success(team, { message: 'teamnx.btnImported' })
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        remove() {
            this.loading = true
            return this.$store.dispatch('team/remove', { id: this.id })
                .then(team => {
                    this.$router.replace({ name: 'teamlist' })
                    this.$success(team, { message: 'team.removed' })
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
