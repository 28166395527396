<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        min-width="auto">
        <template #activator="{ on, attrs }">
            <v-text-field
                v-model="dateFormatted"
                :label="$t('event.date') + ' *'"
                readonly
                outlined
                dense
                :rules="[$required]"
                v-bind="attrs"
                v-on="on">
                <template #append-outer>
                    <slot name="append-outer"></slot>
                </template>
            </v-text-field>
        </template>
        <vlab-base-card-title :title="$t('event.date')" @close="menu = false" />
        <v-date-picker
            v-model="date"
            :min="min"
            :max="max"
            no-title
            scrollable />
    </v-menu>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'

export default {
    name: 'hdv-event-date',
    components: { VlabBaseCardTitle },
    props: {
        value: { type: String },
        min: { type: [String, Date] },
        max: { type: [String, Date] }
    },
    data: () => ({
        menu: false
    }),
    computed: {
        date: {
            get() {
                return this.value
            },
            set(v) {
                this.menu = false
                this.$emit('input', v)
            }
        },

        dateFormatted() {
            if (!this.date) {
                return null
            }
            return this.$i18n.t('event.dateformat', { date: this.date })
        }
    }
}
</script>
