<template>
    <v-input
        class="acs-base-phone--field v-text-field v-text-field--outlined v-text-field--is-booted v-text-field--enclosed"
        :class="{
            [`${colour}--text`]: true,
            'v-input--is-focused': isFocused
        }"
        :messages="messages"
        :error-messages="errMsg"
        :error="!!errMsg.length">

        <template #message="{ message }">
            <div v-marked="message"></div>
        </template>

        <fieldset aria-hidden="true">
            <legend>{{ label + (required ? ' *' : '') }}</legend>
        </fieldset>

        <vue-tel-input
            ref="phone"
            v-model="phone"
            :disabled="disabled"
            :required="required"
            mode="international"
            @validate="validate"
            @focus="isFocused = true"
            @blur="onBlur"
            :auto-default-country="autoDefault"
            valid-characters-only>
        </vue-tel-input>
    </v-input>
</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

export default {
    name: 'acs-base-phone-field',
    components: { VueTelInput },
    props: {
        value: { type: String },
        disabled: { type: Boolean },
        required: { type: Boolean },
        label: { type: String },
        hint: { type: String },
        country: { type: String },
        colour: { type: String, default: 'primary' },
        autoDefault: { type: Boolean, default: false },
        rules: { type: Array, default: () => [] }
    },
    data: () => ({
        phone: null,
        isFocused: false,
        errMsg: []
    }),
    computed: {
        messages() {
            const msg = []
            if (this.hint) {
                msg.push(this.hint)
            }
            return msg
        }
    },
    watch: {
        value() {
            this.init()
        },
        country(v) {
            this.setCountry(v)
        }
    },
    mounted() {
        this.setCountry(this.country)
        this.init()
    },
    methods: {
        init() {
            this.phone = this.value || ''
        },

        setCountry(countryCode) {
            const c = this.$refs.phone.allCountries.find(c => c.iso2.toLowerCase() === (countryCode || '').toLowerCase())
            if (c) {
                this.$refs.phone.choose(c)
                this.phone = ''
                this.$nextTick(() => this.phone = this.value || '')
            }
        },

        validate(data) {
            this.errMsg = []
            if (data.valid === false) {
                this.errMsg.push(this.$i18n.t('rules.phone'))
            }
            this.$emit('input', data.valid ? data.number : null)
        },

        onBlur() {
            this.isFocused = false
            this.rules.forEach(rule => {
                const res = rule(this.phone)
                if (res !== true) {
                    this.errMsg.push(res)
                }
            })
        }
    }
}
</script>

<style lang="sass">
.acs-base-phone--field
    min-height: 56px

    .vue-tel-input
        width: 100%
        border: none
        display: flex
        align-items: center
        box-shadow: none

    legend
        font-size: 80%

    input
        min-height: none
</style>
