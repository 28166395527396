<template>
    <v-container>
        <v-row>
            <v-col :class="cls">
                <slot name="default" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'hdv-base-container',
    props: {
        large: { type: Boolean }
    },
    computed: {
        cls() {
            if (this.large) {
                return ''
            }
            return 'px-0'
        }
    }
}
</script>
