<template>
    <v-card
        :class="selected ? 'grey lighten-3' : ''"
        class="ma-4 d-flex flex-column"
        min-height="300"
        min-width="300"
        max-width="300">
        <v-card-title class="flex-grow-1">
            {{ vacation.title }}
        </v-card-title>
        <hdv-seo-popup
            type="vacation"
            :id="vacation.id"
            :title="vacation.title"
            :visible="hover"
        />
        <hdv-sale-vacation-popup :sale-id="saleId" :id="vacation.id" :title="vacation.title" :visible="hover" />

        <v-divider />

        <hdv-sale-vacation-highlight-selector
            :lot-id="vacation.lot_highlight_id"
            :sale-id="saleId"
            :vacation-id="vacation.id" />

        <v-card-text>
            {{ $t('salevacations.state') }}
        </v-card-text>
        <v-simple-table class="lots">
            <tbody>
                <tr>
                    <td>{{ $t('salevacations.lotsRange') }}</td>
                    <td class="text-end">
                        <strong v-if="counts" :class="{ 'error--text': counts.web !== counts.nx }">{{ counts.lot_from }} - {{ counts.lot_to }}</strong>
                    </td>
                </tr>
                <tr>
                    <td>{{ $t('salevacations.onNx') }}</td>
                    <td class="text-end"><strong v-if="counts">{{ counts.nx }}</strong></td>
                </tr>
                <tr>
                    <td>{{ $t('salevacations.onWeb') }}</td>
                    <td class="text-end">
                        <strong v-if="counts" :class="{ 'error--text': counts.web !== counts.nx }">{{ counts.web }}</strong>
                    </td>
                </tr>
                <tr>
                    <td>{{ $t('salevacations.onAm') }}</td>
                    <td class="text-end">
                        <strong v-if="counts" :class="{ 'error--text': counts.am !== counts.nx }">{{ counts.am }}</strong>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
        <v-divider />
        <v-card-actions class="mt-4">
            <hdv-sale-vacation-list-import-btn
                :label="$t('salevacations.btnWeb')"
                icon="$vuetify.icons.importSale"
                web
                @click:import="ev => onImport('web', ev)" />
            <v-spacer />
            <hdv-sale-vacation-list-import-btn
                :label="$t('salevacations.btnAm')"
                icon="$vuetify.icons.importAm"
                @click:import="ev => onImport('am', ev)" />
        </v-card-actions>
    </v-card>
</template>

<script>
import HdvSaleVacationListImportBtn from '@/components/sale-vacation-list/HdvSaleVacationListImportBtn'
import HdvSaleVacationHighlightSelector from '@/components/sale-vacation-list/HdvSaleVacationHighlightSelector'
import HdvSaleVacationPopup from '@/components/sale-vacation/HdvSaleVacationPopup'
import HdvSeoPopup from '@/components/seo/HdvSeoPopup'

export default {
    name: 'hdv-sale-vacation-list',
    components: {
        HdvSaleVacationListImportBtn,
        HdvSaleVacationHighlightSelector,
        HdvSaleVacationPopup,
        HdvSeoPopup
    },
    props: {
        saleId: { type: String },
        selected: { type: Boolean },
        vacation: { type: Object },
        counts: { type: Object },
        hover: { type: Boolean }
    },
    data: () => ({
        loading: false
    }),
    methods: {
        onImport(to, data) {
            data.to = data.to || to
            data.vacation = this.vacation
            data.total = ['web', 'all'].includes(data.to) ? this.counts.nx : this.counts.web
            this.$emit('click:import', data)
        }
    }
}
</script>

<style lang="sass" scoped>
.lots
    background-color: transparent !important
</style>
