<template>
    <v-text-field
        v-model="title"
        outlined
        :rules="required ? [$required] : []"
        :label="$t('event.name') + (required ? ' *' : '')"
        :name="name"
        :error-messages="errorMessages" />
</template>

<script>
import FieldError from '@/shared/mixins/FieldError'

export default {
    name: 'hdv-event-name',
    props: {
        value: { type: String },
        required: { type: Boolean }
    },
    mixins: [FieldError],

    computed: {
        title: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    }
}
</script>
