<template>
    <v-menu offset-y>
        <template #activator="{ on, attrs }">
            <v-btn text :small="small" :icon="iconBtn" :dark="dark" v-on="on" v-bind="attrs">
                <v-icon>{{ icon }}</v-icon>
                <template v-if="!iconBtn">{{ label }}</template>
            </v-btn>
        </template>
        <v-list dense>
            <v-list-item v-if="!noImage" @click="onClick(true)">
                <v-list-item-icon>
                    <v-icon color="success">$vuetify.icons.importImgYes</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('salevacations.importImgYes') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="onClick(false)">
                <v-list-item-icon>
                    <v-icon color="error">$vuetify.icons.importImgNo</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('salevacations.importImgNo') }}</v-list-item-title>
            </v-list-item>
            <v-divider v-if="web" />
            <v-list-item v-if="web" @click="onClick(false, 'all')">
                <v-list-item-icon>
                    <v-icon color="error">$vuetify.icons.importImgNo</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('salelotlist.importAll')}}: {{ $t('salevacations.importImgNo') }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: 'hdv-sale-vacation-list-import-btn',
    props: {
        label: { type: String },
        icon: { type: String },
        dark: { type: Boolean },
        small: { type: Boolean },
        iconBtn: { type: Boolean },
        web: { type: Boolean },
        noImage: { type: Boolean }
    },
    methods: {
        onClick(withImages, to) {
            this.$emit('click:import', { withImages, to })
        }
    }
}
</script>
