import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        stories: [],
        types: [
            { id: 'youtube' },
            { id: 'newspaper' }
        ]
    },

    getters: {
        stories: state => state.stories,
        types: state => state.types
    },

    mutations: {
    },

    actions: {
        async list({ state }, { search }) {
            const res = await Vue.prototype.$api.get('/contents/stories', {
                params: {
                    search,
                    mapping: 'list',
                    include_disabled: true
                }
            })
            state.stories = res.data
            return state.stories
        },

        async get(context, { storyId }) {
            const res = await Vue.prototype.$api.get(`/contents/stories/${storyId}`, {
                meta: { lang: '*' }
            })
            return res.data
        },

        async add({ state }, { story }) {
            const res = await Vue.prototype.$api.post('/contents/stories', story)

            state.stories.push({ ...res.data })
            return res.data
        },

        async update({ state }, { storyId, story }) {
            await Vue.prototype.$api.put(`/contents/stories/${storyId}`, story)

            const index = state.stories.findIndex(c => c.id === storyId)
            if (index !== -1) {
                state.stories.splice(index, 1, { ...story })
            }
        },

        async remove({ state }, { storyId }) {
            await Vue.prototype.$api.delete(`/contents/stories/${storyId}`)

            const index = state.stories.findIndex(c => c.id === storyId)
            if (index !== -1) {
                state.stories.splice(index, 1)
            }
        },

        async removeByIndexes({ state, dispatch }, { indexes }) {
            const items = state.stories.filter((_, i) => indexes.indexOf(i) !== -1).slice()
            return Promise.all(items.map(item => dispatch('remove', { storyId: item.id })))
        }
    }
}
