<template>
    <v-card flat :loading="loading">
        <v-card-text>
            <div v-marked="$t('salespecialhighlight.text')"></div>

            <hdv-sale-special-highlight-search
                v-model="highlight"
                :sale-id="saleId"
                :special-id="specialId"
                @input="add" />

        </v-card-text>
        <v-list three-line class="pt-0" :disabled="loading">
            <hdv-base-actionable-list
                v-model="action"
                :total="highlights.length"
                :selected.sync="selected"
                @close="selected = []"
                @remove="removeItems"
                :force-visible="true"
                :disabled="loading">
                <template v-if="selected" #remove>
                    <v-card-text>{{ $t('salespecialhighlight.removeText', { count: selected.length, name: selected.length && selected[0].title }) }}</v-card-text>
                </template>
                <template #search>
                    <v-btn rounded outlined small @click="toggleSort" :disabled="loading || !highlights.length">
                        {{ $t(sorting ? 'base.sorting' : 'base.sort') }}
                    </v-btn>
                </template>
            </hdv-base-actionable-list>

            <v-list-item-group v-model="selected" :multiple="action">
                <draggable
                    :list="highlights"
                    :disabled="loading"
                    class="list-group"
                    v-bind="dragOptions"
                    @start="$emit('drag', true)"
                    @end="$emit('drag', false)">
                    <div v-for="highlight in highlights" :key="highlight.id">
                        <v-divider />
                        <hdv-sale-highlight-list-item
                            :action="action"
                            :highlight="highlight"
                            :lot="highlight.lot"
                            :item="highlight.lot.item"
                            hide-star />
                    </div>
                </draggable>
            </v-list-item-group>
        </v-list>
    </v-card>
</template>

<script>
import HdvBaseActionableList from '@/components/base/HdvBaseActionableList'
import HdvSaleHighlightListItem from '@/components/sale-highlight/HdvSaleHighlightListItem'
import HdvSaleSpecialHighlightSearch from '@/components/sale-special-highlight/HdvSaleSpecialHighlightSearch'
import draggable from 'vuedraggable'

export default {
    name: 'hdv-sale-special-highlight',
    components: {
        HdvBaseActionableList,
        HdvSaleHighlightListItem,
        HdvSaleSpecialHighlightSearch,
        draggable
    },
    props: {
        lots: { type: Array },
        saleId: { type: String },
        specialId: { type: String }
    },
    data: () => ({
        highlights: [],
        loading: false,
        show: false,
        editItem: null,
        highlight: null,
        sorting: false,
        dragOptions: {
            animation: 0,
            group: 'description',
            disabled: false,
            ghostClass: 'ghost'
        },
        // actionable list
        action: false,
        selected: []
    }),
    watch: {
        specialId: 'load'
    },
    mounted() {
        return this.load()
    },
    methods: {
        load() {
            this.highlights = this.lots.slice()
        },

        add(lot) {
            if (!lot) {
                return
            }
            this.loading = true
            return this.$store.dispatch('spec/addHighlight', { lotId: lot.id, specialId: this.specialId, saleId: this.saleId })
                .then(highlight => {
                    if (!this.highlights.find(h => h.id === highlight.id)) {
                        this.highlights.push(highlight)
                    }
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        removeItems() {
            this.loading = true
            return this.$store.dispatch('spec/removeHighlightByIndexes', { specialId: this.specialId, saleId: this.saleId, lots: this.highlights, indexes: this.selected })
                .then(res => {
                    this.selected = []
                    this.$success(res, { message: 'salespecialhighlight.removed', count: this.selected.length })
                    this.action = false
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        toggleSort() {
            if (!this.sorting) {
                this.sorting = true
                return
            }
            this.loading = true
            return this.$store
                .dispatch('spec/sortLots', {
                    saleId: this.saleId,
                    specialId: this.specialId,
                    highlightIds: this.highlights.map(high => high.id)
                })
                .then(res => {
                    this.sorting = false
                    return this.$success(res, { message: this.$i18n.t('salehighlight.sortSaved') })
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
