<template>
    <v-text-field
        v-model="alias"
        outlined
        :label="$t('contentcategory.alias') + '*'"
        :rules="[$required, $length(100)]" />
</template>

<script>

export default {
    name: 'hdv-content-faq-alias',
    props: {
        value: { type: String }
    },
    computed: {
        alias: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    }
}
</script>
