<template>
    <v-autocomplete
        v-if="saleslist.length"
        v-model="sale"
        :items="saleslist"
        :label="dense ? $t('saleselector.select') : null"
        item-value="nx_id"
        item-text="title"
        :placeholder="$t('saleselector.select')"
        return-object
        :class="{ 'hdv-sale-selector': !dense }"
        :outlined="dense"
        :menu-props="!dense ? { maxWidth: 400 } : {}"
        :clearable="clearable">
        <template v-if="!dense" #selection="{ item }">
            <div class="px-3">
                <h3 class="text-h5">{{ item.title }}</h3>
                <p class="mt-5 text-caption">{{ $t('sale.dateformat', { start: item.date_start, end: item.date_end }) }}</p>
            </div>
            <span class="pl-3 text-caption">{{ $t('saleselector.choose') }}</span>
        </template>
        <template #item="{ item, on, attrs }">
            <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('sale.dateformat', { start: item.date_start, end: item.date_end }) }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-autocomplete>
</template>

<script>
export default {
    name: 'hdv-sale-list-selector',
    props: {
        value: { type: [String, Number] },
        defaultValue: { type: [String, Number] },
        dense: { type: Boolean, default: false },
        clearable: { type: Boolean, default: false },
        returnObject: { type: Boolean, default: false },
        includeNx: { type: Boolean, default: false }
    },
    computed: {
        sale: {
            get() {
                const id = this.value
                const sale = this.saleslist.find(sale => sale.id === id || sale.nx_id === id)
                this.$emit('selected', sale)
                return sale
            },
            set(v) {
                if (this.returnObject) {
                    return this.$emit('input', v)
                }
                this.$emit('input', v?.id || null)
            }
        },
        saleslist() {
            const websales = this.$store.getters['sale/sales']
            const websalesIndexed = websales.reduce((acc, sale) => {
                acc[sale.nx_id] = sale
                return acc
            }, {})

            const nxsales = this.$store.getters['sale/nxSales']

            const sales = nxsales.map(nx => {
                const web = websalesIndexed[nx.id] || {}
                return {
                    imported: !!web.id,
                    nx_id: nx.id,
                    id: web.id || nx.id,
                    title: web.title || nx.title,
                    date_start: web.date_start || nx.date_start,
                    date_end: web.date_end || nx.date_end
                }
            })

            if (!this.includeNx) {
                return sales.filter(sale => sale.imported)
            }
            return sales
        }
    },
    mounted() {
        return this.$store.dispatch('sale/sales')
            .catch(err => this.$err(err))
    }
}
</script>

<style lang="sass">
.hdv-sale-selector
    input
        padding-left: 16px
</style>
