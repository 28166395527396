<template>
    <v-text-field
        v-model="title"
        outlined
        counter
        :maxlength="max"
        :label="$t('seo.title')"
        :rules="[$length(max)]"
        :name="name"
        :error-messages="errorMessages" />
</template>

<script>
import FieldError from '@/shared/mixins/FieldError'

export default {
    name: 'hdv-seo-title',
    props: {
        value: { type: String }
    },

    mixins: [FieldError],

    data: () => ({
        max: 40
    }),

    computed: {
        title: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    }
}
</script>
