<template>
    <v-select
        v-model="categories"
        outlined
        multiple
        item-text="name"
        item-value="id"
        :items="items"
        :label="$t('team.category')">
    </v-select>
</template>

<script>

export default {
    name: 'hdv-team-category-selector',
    props: {
        value: { type: Array, default: () => [] }
    },
    computed: {
        categories: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },
        items() {
            return this.$store.getters['team/categories']
        }
    }
}
</script>
