<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        min-width="auto">
        <template #activator="{ on, attrs }">
            <v-text-field
                v-model="dateFormatted"
                :label="$t('content.publicationdate') + ' *'"
                readonly
                outlined
                :rules="[$required]"
                v-bind="attrs"
                v-on="on" />
        </template>
        <vlab-base-card-title :title="$t('content.publicationdate')" @close="menu = false" />
        <v-date-picker
            v-model="date"
            :min="min"
            :max="max"
            no-title
            scrollable />
    </v-menu>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'

export default {
    name: 'hdv-content-publication-date',
    components: { VlabBaseCardTitle },
    props: {
        value: { type: [String, Date] },
        min: { type: [String, Date] },
        max: { type: [String, Date] }
    },
    data: () => ({
        menu: false
    }),
    computed: {
        date: {
            get() {
                return this.value
            },
            set(v) {
                this.menu = false
                this.$emit('input', v)
            }
        },

        lang() {
            return this.$store.getters['ln/currentWithRegion']
        },

        dateFormatted() {
            if (!this.date) {
                return null
            }
            const formatter = new Intl.DateTimeFormat(this.lang)
            return formatter.format(new Date(this.date))
        }
    }
}
</script>
