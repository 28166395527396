import Vue from 'vue'
import Vuex from 'vuex'

import VlabBaseLnStore from '@/shared/store/VlabBaseLnStore'
import VlabBaseLoadStore from '@/shared/store/VlabBaseLoadStore'
import VlabAuthStore from '@/shared/store/VlabAuthStore'
import VlabMessageStore from '@/shared/store/VlabMessageStore'
import VlabPublicSiteStore from '@/shared/store/VlabPublicSiteStore'
import VlabClassStore from '@/shared/store/VlabClassStore'
import VlabFormFieldErrorMessageStore from '@/shared/store/VlabFormFieldErrorMessageStore'

import HdvBaseTopStore from '@/store/HdvBaseTopStore'
import HdvEventStore from '@/store/HdvEventStore'
import HdvSaleStore from '@/store/HdvSaleStore'
import HdvSaleSpecialStore from '@/store/HdvSaleSpecialStore'
import HdvSaleHighlightStore from '@/store/HdvSaleHighlightStore'
import HdvSaleItemStore from '@/store/HdvSaleItemStore'
import HdvSaleLotStore from '@/store/HdvSaleLotStore'
import HdvContentStore from '@/store/HdvContentStore'
import HdvContentCategoryStore from '@/store/HdvContentCategoryStore'
import HdvContentFaqStore from '@/store/HdvContentFaqStore'
import HdvContentPageStore from '@/store/HdvContentPageStore'
import HdvContentStoryStore from '@/store/HdvContentStoryStore'
import HdvDepartmentStore from '@/store/HdvDepartmentStore'
import HdvTeamStore from '@/store/HdvTeamStore'
import HdvSeoStore from '@/store/HdvSeoStore'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        ln: VlabBaseLnStore,
        load: VlabBaseLoadStore,
        auth: VlabAuthStore,
        msg: VlabMessageStore,
        pub: VlabPublicSiteStore,
        cls: VlabClassStore,
        formerr: VlabFormFieldErrorMessageStore,
        top: HdvBaseTopStore,
        res: HdvEventStore,
        sale: HdvSaleStore,
        spec: HdvSaleSpecialStore,
        high: HdvSaleHighlightStore,
        item: HdvSaleItemStore,
        lot: HdvSaleLotStore,
        ct: HdvContentStore,
        cat: HdvContentCategoryStore,
        dp: HdvDepartmentStore,
        faq: HdvContentFaqStore,
        page: HdvContentPageStore,
        story: HdvContentStoryStore,
        team: HdvTeamStore,
        seo: HdvSeoStore
    }
})
