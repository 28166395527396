<template>
    <v-dialog v-model="show" max-width="600" :persistent="loading" scrollable>
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs">
                <v-btn v-show="visible" color="primary" fab absolute right small v-on="on" v-bind="attrs">
                    <v-icon small>$vuetify.icons.seo</v-icon>
                </v-btn>
            </slot>
        </template>
        <v-form v-model="valid" @submit.stop.prevent="submit" ref="form">
            <v-card>
                <vlab-base-card-title :title="title" :dismissible="!loading" @close="show = false" single-line />
                <v-card-text>
                    <hdv-seo-form ref="seo" :type="type" :id="id" @loading="v => loading = v" />
                    <slot name="advanced"></slot>
                </v-card-text>
                <v-card-actions>
                    <v-btn rounded block color="primary" type="submit" :disabled="!valid" :loading="loading">
                        {{ $t('base.save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'
import HdvSeoForm from '@/components/seo/HdvSeoForm'

export default {
    name: 'hdv-seo-popup',

    components: { VlabBaseCardTitle, HdvSeoForm },

    props: {
        type: { type: String },
        id: { type: String },
        title: { type: String },
        activator: { type: Boolean },
        visible: { type: Boolean, default: true },
        closeOnSave: { type: Boolean }
    },

    data: () => ({
        loading: false,
        show: false,
        valid: false
    }),

    watch: {
        show(v) {
            v && this.$refs.seo && this.$refs.seo.load()
        }
    },

    methods: {
        submit() {
            return this.$refs.seo.submit(this.$refs.form)
                .then(() => {
                    if (this.closeOnSave) {
                        this.show = false
                    }
                })
        }
    }
}
</script>
