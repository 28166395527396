<template>
    <v-card>
        <v-form v-model="valid" @submit.stop.prevent="submit" :disabled="loading" ref="form">
            <v-card-text>
                <hdv-seo-form ref="seo" type="sale" :id="saleId" @loading="v => loading = v" />
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    rounded
                    color="primary"
                    type="submit"
                    :disabled="!valid"
                    :loading="loading">
                    {{ $t('base.save') }}
                </v-btn>
                <v-spacer />
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
import HdvSeoForm from '@/components/seo/HdvSeoForm'

export default {
    name: 'sale-seo',
    components: {
        HdvSeoForm
    },
    props: {
        saleId: { type: String }
    },
    data: () => ({
        valid: false,
        loading: false
    }),
    watch: {
        saleId() {
            this.$refs.seo && this.$refs.seo.load()
        }
    },
    methods: {
        submit() {
            return this.$refs.seo.submit(this.$refs.form)
        }
    }
}
</script>
