<template>
    <v-text-field
        v-model="url"
        outlined
        :label="$t('salecatalog.issuu')"
        :rules="[$url]" />
</template>

<script>

export default {
    name: 'hdv-sale-special-issuu',
    props: {
        value: { type: String }
    },
    computed: {
        url: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    }
}
</script>
