<template>
    <v-row>
        <v-col class="pr-0" cols="12" lg="4" sm="6">
            <hdv-department-list />
        </v-col>
        <v-col class="px-0" cols="12" lg="8" sm="6">
            <router-view></router-view>
        </v-col>
    </v-row>
</template>

<script>
import HdvDepartmentList from '@/components/department-list/HdvDepartmentList'

export default {
    name: 'department',
    components: {
        HdvDepartmentList
    }
}
</script>
