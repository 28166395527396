<template>
    <v-text-field
        :label="(label || $t('user.email')) + (!optional ? ' *' : '')"
        :rules="rules"
        :disabled="disabled"
        :persistent-hint="!!hint"
        :hint="hint"
        v-model="email"
        autocapitalize="none"
        :autocomplete="disableAutocomplete ? 'off' : 'on'"
        :autofocus="focus && $vuetify.breakpoint.mdAndUp"
        name="email"
        type="email"
        outlined />
</template>

<script>
import Autofill from '@/shared/mixins/Autofill'
import lodash from 'lodash'

export default {
    name: 'acs-user-email-field',
    mixins: [Autofill],
    props: {
        value: { type: String },
        disabled: { type: Boolean },
        disableAutocomplete: { type: Boolean },
        optional: { type: Boolean },
        focus: { type: Boolean },
        label: { type: String },
        hint: { type: String }
    },
    computed: {
        email: {
            get() {
                return this.value || undefined
            },
            set(v) {
                this.$emit('input', lodash.trim(v))
            }
        },
        rules() {
            const rules = []
            if (!this.optional) {
                rules.push(this.$required)
            }
            rules.push(this.$emailed)
            return rules
        }
    }
}
</script>
