import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        popup: false,
        popupData: {}
    },

    getters: {
        popup: state => state.popup,
        popupData: state => state.popupData
    },

    mutations: {
        showPopup(state, data) {
            state.popup = true
            state.popupData = data || {}
        },

        hidePopup(state) {
            state.popup = false
        }
    },

    actions: {
        async get(context, { type, id }) {
            const res = await Vue.prototype.$api.get('/seo', {
                meta: { lang: '*' },
                params: {
                    type,
                    id
                }
            })

            return res.data
        },

        async set(context, { type, id, seo }) {
            await Vue.prototype.$api.post('/seo', {
                type,
                id,
                seo
            })
        }
    }
}
