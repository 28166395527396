<template>
    <v-row>
        <v-col class="pr-0" cols="12" lg="4" sm="6">
            <hdv-content-story-list />
        </v-col>
        <v-col class="px-0" cols="12" lg="8" sm="6">
            <router-view></router-view>
        </v-col>
    </v-row>
</template>

<script>
import HdvContentStoryList from '@/components/content-story-list/HdvContentStoryList'

export default {
    name: 'content-faq',
    components: {
        HdvContentStoryList
    }
}
</script>
