<template>
    <v-autocomplete
        v-model="selected"
        :label="$t('salehighlight.searchLot')"
        :items="lots"
        :search-input.sync="search"
        :loading="loading"
        item-text="lot_nb"
        item-value="id"
        return-object
        outlined>
        <template #item="{ item }">
            <div class="py-3 d-flex">
                <v-avatar
                    rounded
                    size="52"
                    color="grey lighten-3">
                    <v-img v-if="item.item.images.length" :src="item.item.images[0].path_preview | imagecrop({ w: 52, h: 52, f: 'cover' })" alt="image" />
                </v-avatar>
                <div class="pl-3">
                    <v-list-item-title>{{ item.item.title }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('salehighlight.estimates', { down: item.item.estimate_down, up: item.item.estimate_up }) }}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{ $t('salehighlight.lot', { lot_nb: item.lot_nb }) }}</v-list-item-subtitle>
                </div>
            </div>
        </template>
        <template #append-outer>
            <slot name="append-outer"></slot>
        </template>
    </v-autocomplete>
</template>

<script>
import lodash from 'lodash'

export default {
    name: 'hdv-sale-highlight-lot-search',
    props: {
        saleId: { type: String },
        value: { type: Object, default: () => ({}) }
    },
    data: () => ({
        search: null,
        lots: [],
        loading: false
    }),
    computed: {
        selected: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },
    watch: {
        search: lodash.debounce(function(v) {
            this.load(v)
        }, 500)
    },
    methods: {
        load(lotNb) {
            if (!lotNb) {
                return
            }
            this.loading = true
            return this.$store.dispatch('high/searchLots', { saleId: this.saleId, lotNb: lotNb })
                .then(lots => {
                    this.lots = lots.map(lot => {
                        lot.lot_nb = lot.lot_nb + lot.lot_nb_ext
                        return lot
                    })
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
