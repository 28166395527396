<template>
    <v-card>
        <vlab-base-card-title :title="$t('contentpage.form')" :dismissible="!loading" @close="back" />
        <v-row>
            <v-col>
                <v-form v-model="valid" @submit.prevent.stop="submit" :disabled="loading">
                    <v-card-text v-if="page">
                        <hdv-content-page-disabled v-model="page.disabled" />

                        <hdv-base-alias-field v-model="page.alias" />

                        <hdv-base-i18n-area>
                            <template #default="{ ln, isDefault }">
                                <hdv-content-slug v-model="page.slug_i18n[ln]" :required="isDefault" />

                                <hdv-content-page-title
                                    v-model="page.name_i18n[ln]"
                                    @input="v => onTitleChange(v, isDefault)"
                                    :required="isDefault" />
                            </template>
                        </hdv-base-i18n-area>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer />
                        <v-btn
                            rounded
                            color="primary"
                            type="submit"
                            :disabled="!valid"
                            :loading="loading">
                            {{ $t('base.save') }}
                        </v-btn>
                        <v-spacer />
                    </v-card-actions>
                </v-form>
            </v-col>
            <v-col v-if="page && editMode">
                <hdv-content-page-component-list
                    :page-id="page.id"
                    :components="page.components"
                    @refresh="load" />
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'
import HdvBaseI18nArea from '@/components/base/HdvBaseI18nArea'
import HdvContentPageDisabled from '@/components/content-page/HdvContentPageDisabled'
import HdvContentPageTitle from '@/components/content-page/HdvContentPageTitle'
import HdvContentSlug from '@/components/content/HdvContentSlug'
import HdvContentPageComponentList from '@/components/content-page/HdvContentPageComponentList'
import HdvBaseAliasField from '@/components/base/HdvBaseAliasField'

export default {
    name: 'content-page-form',
    components: {
        VlabBaseCardTitle,
        HdvBaseI18nArea,
        HdvContentPageDisabled,
        HdvContentPageTitle,
        HdvContentSlug,
        HdvContentPageComponentList,
        HdvBaseAliasField
    },
    props: {
        pageId: { type: String },
        parent: { type: Object }
    },
    data: () => ({
        loading: false,
        valid: false,
        page: null
    }),
    computed: {
        editMode() {
            return this.pageId !== 'add'
        }
    },
    watch: {
        pageId: 'load'
    },
    mounted() {
        this.load()
    },
    methods: {
        load() {
            this.loading = true
            return Promise
                .all([
                    this.editMode ? this.$store.dispatch('page/get', { pageId: this.pageId }) : null
                ])
                .then(data => {
                    this.page = data[0] || {
                        id: null,
                        components: [],
                        slug_i18n: {},
                        name_i18n: {}
                    }
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        submit() {
            this.loading = true
            const action = this.editMode
                ? 'page/edit'
                : 'page/add'

            return this.$store.dispatch(action, { pageId: this.page.id, page: this.page, parent: this.parent })
                .then(page => {
                    this.$success(page, { message: 'contentpage.saved' })
                    if (!this.editMode) {
                        return this.$router.push({ name: 'contentpage' })
                    }
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        onTitleChange(value, isDefault) {
            if (isDefault) {
                this.page.name = value
            }
        },

        back() {
            this.$router.push({ name: 'contentpage' })
        }
    }
}
</script>
