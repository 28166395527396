import SaleHome from '@/views/SaleHome'
import SaleForm from '@/views/SaleForm'
import SaleSpecial from '@/views/SaleSpecial'
import SaleSpecialForm from '@/views/SaleSpecialForm'
import SaleLot from '@/views/SaleLot'
import SaleHighlight from '@/views/SaleHighlight'
import SaleAm from '@/views/SaleAm'
import SaleSeo from '@/views/SaleSeo'

export default [
    {
        path: '/sales',
        name: 'salehome',
        component: SaleHome,
        props: true,
        meta: {
            title: 'sale.menu',
            bodyScroll: true
        },
        children: [
            {
                path: ':saleId',
                name: 'saleform',
                component: SaleForm,
                props: route => ({
                    saleId: route.params.saleId,
                    nx: false
                }),
                meta: {
                    title: 'sale.form',
                    bodyScroll: true
                }
            },
            {
                path: ':saleId/import',
                name: 'saleadd',
                component: SaleForm,
                props: route => ({
                    saleId: route.params.saleId,
                    nx: true
                }),
                meta: {
                    title: 'sale.form',
                    bodyScroll: true
                }
            },
            {
                path: ':saleId/specials',
                name: 'salespecial',
                component: SaleSpecial,
                props: true,
                meta: {
                    title: 'sale.form',
                    bodyScroll: true
                },
                children: [{
                    path: ':specialId',
                    name: 'salespecialform',
                    component: SaleSpecialForm,
                    props: true,
                    meta: {
                        title: 'sale.form'
                    }
                }]
            },
            {
                path: ':saleId/lots',
                name: 'salelot',
                component: SaleLot,
                props: true,
                meta: {
                    title: 'sale.form',
                    bodyScroll: true
                }
            },
            {
                path: ':saleId/highlights',
                name: 'salehighlight',
                component: SaleHighlight,
                props: true,
                meta: {
                    title: 'sale.form',
                    bodyScroll: true
                }
            },
            {
                path: ':saleId/am',
                name: 'saleam',
                component: SaleAm,
                props: true,
                meta: {
                    title: 'sale.form',
                    bodyScroll: true
                }
            },
            {
                path: ':saleId/seo',
                name: 'saleseo',
                component: SaleSeo,
                props: true,
                meta: {
                    title: 'sale.form',
                    bodyScroll: true
                }
            }
        ]
    }
]
