<template>
    <div :class="{ 'd-flex': inline }" class="mb-3">
        <vlab-base-upload-btn
            ref="upload"
            class="grow"
            rounded
            :block="!inline"
            :multiple="false"
            :auto-start="autoStart"
            :loading="loading"
            :url="url"
            :label="label"
            :axios="$api"
            :accept="accept"
            @upload:start="onUpload(true)"
            @upload:finish="onUpload(false)"
            @upload:success="onSuccess"
        />

        <a v-if="preview" :href="!loading ? src : '#'" target="_blank" class="px-3">
            <v-img :src="preview | imagecrop({ w: 400, f: 'cover' })" :max-height="previewHeight" :max-width="previewHeight" contain>
                <template #placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                </template>
            </v-img>
        </a>
        <v-btn v-else icon disabled>
            <v-icon>$vuetify.icons.baseNoImage</v-icon>
        </v-btn>

        <v-btn icon color="red" :disabled="!preview || loading" @click="$emit('remove', { preview, src })">
            <v-icon>$vuetify.icons.baseDelete</v-icon>
        </v-btn>
    </div>
</template>

<script>
import VlabBaseUploadBtn from '@/shared/components/base/VlabBaseUploadBtn'

export default {
    name: 'hdv-base-image',
    components: { VlabBaseUploadBtn },
    props: {
        value: { type: String },
        dense: { type: Boolean },
        autoStart: { type: Boolean },
        departmentId: { type: String },
        label: { type: String },
        url: { type: String },
        preview: { type: String },
        src: { type: String },
        previewHeight: { type: [String, Number], default: 60 },
        inline: { type: Boolean, default: true },
        accept: {
            type: [String, Array],
            default() {
                return ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/webp']
            }
        }
    },
    data: () => ({
        loading: false
    }),
    methods: {
        onUpload(started) {
            this.loading = started
            this.$emit('loaded', started)
        },
        upload() {
            return this.$refs.upload.upload()
        },
        onSuccess(file) {
            this.$emit('update:preview', file.response.url_preview)
            this.$emit('update:src', file.response.url)
        }
    }
}
</script>
