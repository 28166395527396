<template>
    <v-card flat>
        <div class="my-5 px-5" v-marked="$t('contentcomponent.help')"></div>
        <v-list>
            <hdv-base-actionable-list
                v-model="action"
                :total="components.length"
                :selected.sync="selected"
                @close="selected = []"
                @remove="removeItems"
                :force-visible="true"
                :disabled="loading">
                <template v-if="selected" #remove>
                    <v-card-text>{{ $t('contentcomponent.removeText', { count: selected.length, name: selected.length && selected[0].title }) }}</v-card-text>
                </template>

                <template #search>
                    <v-btn rounded outlined small @click="addItem" :disabled="loading" class="mr-3">
                        {{ $t('base.add') }}
                    </v-btn>
                    <v-btn rounded outlined small @click="toggleSort" :disabled="loading">
                        {{ $t(sorting ? 'base.sorting' : 'base.sort') }}
                    </v-btn>
                </template>
            </hdv-base-actionable-list>

            <v-list-item-group v-model="selected" :multiple="action">

                <draggable
                    :list="components"
                    :disabled="!sorting || loading"
                    class="list-group"
                    v-bind="dragOptions"
                    @start="$emit('drag', true)"
                    @end="$emit('drag', false)">
                    <template v-for="content in components">
                        <div :key="content.component_id">
                            <hdv-content-page-component-list-item
                                :action="action"
                                :content="content"
                                :sorting="sorting"
                                @click="!sorting && showEditItem(content)" />
                            <v-divider />
                        </div>
                    </template>
                </draggable>

            </v-list-item-group>
            <hdv-base-empty-list :value="!loading && !components.length" :text="$t('contentcomponent.empty')" />
            <vlab-base-loader v-model="loading" />

            <hdv-content-page-component-form
                v-model="edit"
                :component="editComponent"
                @save="v => editItem(v)" />
        </v-list>
    </v-card>
</template>

<script>
import HdvContentPageComponentListItem from '@/components/content-page/HdvContentPageComponentListItem'
import HdvContentPageComponentForm from '@/components/content-page/HdvContentPageComponentForm'
import HdvBaseEmptyList from '@/components/base/HdvBaseEmptyList'
import HdvBaseActionableList from '@/components/base/HdvBaseActionableList'
import draggable from 'vuedraggable'

export default {
    name: 'hdv-content-page-component-list',
    components: { HdvContentPageComponentListItem, HdvContentPageComponentForm, HdvBaseEmptyList, HdvBaseActionableList, draggable },
    props: {
        pageId: { type: String },
        components: { type: Array }
    },
    data: () => ({
        loading: false,
        sorting: false,
        edit: false,
        editComponent: null,
        dragOptions: {
            animation: 0,
            group: 'description',
            disabled: false,
            ghostClass: 'ghost'
        },
        // actionable list
        action: false,
        selected: []
    }),
    methods: {

        addItem() {
            this.editComponent = {
                params: {}
            }
            this.edit = true
        },

        showEditItem(item) {
            this.editComponent = {
                id: item.component_id,
                content_id: item.id,
                params: item.params
            }
            this.edit = true
        },

        editItem(item) {
            this.loading = true
            const action = item.id ? 'page/updateComponent' : 'page/addComponent'
            return this.$store
                .dispatch(action, {
                    pageId: this.pageId,
                    componentId: item.id,
                    component: item,
                    components: this.components
                })
                .then(res => {
                    this.$emit('refresh')
                    this.$success(res, { message: 'contentcomponent.saved' })
                    this.edit = false
                    this.editComponent = null
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        removeItems() {
            this.loading = true
            return this.$store.dispatch('page/removeComponentByIndexes', { pageId: this.pageId, components: this.components, indexes: this.selected })
                .then(res => {
                    this.selected = []
                    this.$success(res, { message: 'contentcomponent.removed', count: this.selected.length })
                    this.action = false
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        toggleSort() {
            if (!this.sorting) {
                this.sorting = true
                return
            }
            // on enregistre l'état du sort
            this.sorting = false
            this.loading = true
            return this.$store.dispatch('page/sortsComponents', { pageId: this.pageId, componentIds: this.components.map(c => c.component_id) })
                .then(res => this.$success(res, { message: 'contentcomponent.sortsaved' }))
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
