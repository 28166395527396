<template>
    <v-autocomplete
        v-model="selected"
        :items="teams"
        :label="$t('teamselector.label')"
        item-value="id"
        :item-text="getText"
        clearable
        outlined>
    </v-autocomplete>
</template>

<script>
export default {
    name: 'hdv-team-selector',

    props: {
        value: { type: String }
    },

    data: () => ({
        loading: false
    }),

    computed: {
        selected: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v || null)
            }
        },

        teams() {
            return this.$store.getters['team/teams']
        }
    },

    mounted() {
        return this.load()
    },

    methods: {
        load() {
            this.loading = true
            return this.$store.dispatch('team/teams')
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        getText(team) {
            return `${team.firstname} ${team.lastname}`
        }
    }
}
</script>
