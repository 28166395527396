import Vue from 'vue'
import moment from 'moment'
import store from '../store'
import lodash from 'lodash'

const formatters = {}

Vue.filter('num', (value, currency) => {
    if (!currency) {
        return value
    }
    const n = new Intl.NumberFormat(store.getters['ln/currentWithRegion'], { style: 'currency', currency: currency })
    return n.format(value)
})

Vue.filter('number', value => {
    const params = {
        ln: store.getters['ln/currentWithRegion']
    }
    const hash = JSON.stringify(params)

    if (!formatters[hash]) {
        formatters[hash] = new Intl.NumberFormat(params.ln)
    }
    const formatter = formatters[hash]
    return formatter.format(value)
})

/**
 * Formate une date avec moment
 */
Vue.filter('date', (value, format) => {
    if (!value) {
        return
    }
    return moment(value).format(format)
})

Vue.filter('daterange', (from, to, formats) => {
    if (!from || !to) {
        return
    }
    return moment().rangeDate(from, to, formats)
})

Vue.filter('imagecrop', (image, params = {}) => {
    if (!params.w) {
        // width is mandatory
        return image
    }

    const data = Object
        .entries(params)
        // ensure width is first parameter
        .reduce((acc, [key, value]) => key === 'w' ? acc : acc.concat([`${key}${value}`]), ['', `w${params.w}`])

    const arry = image.split('')
    arry.splice(image.lastIndexOf('.'), 0, data.join('-'))
    return arry.join('')
})

Vue.filter('makeLotsRange', (lotNbs) => {
    // on enlève les doublons de n° (dus aux lots A) et on trie ASC
    const nbs = lodash.uniq(lotNbs).sort()

    return nbs
        .reduce((groups, lotNb) => {
            let index
            if (groups.length && groups[groups.length - 1].end + 1 === lotNb) {
                index = groups.length - 1
            } else {
                index = groups.length
            }
            if (!groups[index]) {
                groups[index] = {
                    start: lotNb
                }
            }
            groups[index].end = lotNb
            return groups
        }, [])
        // on génère la string pour chaque groupe
        .map(group => {
            if (group.start === group.end) {
                return group.start
            }
            return `${group.start}:${group.end}`
        })
        .join(',')
})
