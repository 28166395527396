<template>
    <v-autocomplete
        v-model="selected"
        :items="departments"
        :label="$t('departmentselector.label')"
        :search-input.sync="search"
        item-value="id"
        item-text="title"
        small-chips
        deletable-chips
        cache-items
        multiple
        clearable
        outlined>
    </v-autocomplete>
</template>

<script>
import lodash from 'lodash'

export default {
    name: 'hdv-department-selector',

    props: {
        value: { type: Array, default: () => [] }
    },

    data: () => ({
        loading: false,
        search: null
    }),

    computed: {
        selected: {
            get() {
                return this.value || []
            },
            set(v) {
                this.$emit('input', v?.length ? v : null)
            }
        },

        departments() {
            console.log(this.$store.getters['dp/departments'])
            return this.$store.getters['dp/departments']
        }
    },

    watch: {
        search: 'onSearch'
    },

    mounted() {
        return this.load(this.selected)
    },

    methods: {
        load(ids) {
            this.loading = true
            return this.$store.dispatch('dp/list', { search: this.search, ids })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        onSearch: lodash.debounce(function() {
            this.load()
        }, 800)
    }
}
</script>
