<template>
    <hdv-base-actionable-list-item
        :enabled="action"
        :ripple="!sorting"
        :class="{ sorting: !sorting }"
        class="draggable-item"
        :to="sorting ? undefined : { name: 'contentfaqform', params: { contentId: content.id } }">
        <v-list-item-content>
            <v-list-item-title>{{ content.title }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="unactionable">
            <v-btn icon>
                <v-icon v-if="!sorting" color="primary">$vuetify.icons.baseRight</v-icon>
                <v-icon v-else color="primary">$vuetify.icons.baseSort</v-icon>
            </v-btn>
        </v-list-item-action>
    </hdv-base-actionable-list-item>
</template>

<script>
import HdvBaseActionableListItem from '@/components/base/HdvBaseActionableListItem'

export default {
    name: 'acs-content-faq-list-item',
    components: { HdvBaseActionableListItem },
    props: {
        content: { type: Object },
        action: { type: Boolean },
        sorting: { type: Boolean }
    }
}
</script>

<style lang="sass">
.sorting
    cursor: default !important
</style>
