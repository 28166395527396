<template>
    <v-select
        v-model="category"
        :items="items"
        :disabled="disabled"
        :loading="loading"
        outlined
        clearable
        persistent-hint
        item-value="id"
        item-text="name"
        :dense="dense"
        :label="($t('content.category'))" />
</template>

<script>

export default {
    name: 'hdv-content-category',
    props: {
        value: { type: String },
        dense: { type: Boolean },
        disabled: { type: Boolean }
    },
    data: () => ({
        loading: false
    }),
    computed: {
        items() {
            return this.$store.getters['cat/categories']
        },
        category: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },
    mounted() {
        return this.load()
    },
    methods: {
        load() {
            this.loading = true
            return this.$store.dispatch('cat/list', {})
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
