<template>
    <v-container>
        <v-row class="text-center">
            <v-col cols="12">
                <v-card max-width="400" flat>
                    <v-card-text>
                        Page introuvable
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn color="primary" to="/">Retour à l'accueil</v-btn>
                        <v-spacer />
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'not-found'
}
</script>
