<template>
    <v-dialog v-model="show" max-width="500" :persistent="loading">
        <v-card>
            <vlab-base-card-title :title="$t('salelotimport.title')" @close="show = false" :dismissible="!loading" hide-spacer>
                <template #buttons>
                    <v-spacer />
                    <v-btn x-small @click="togglePause" :color="pause ? 'green' : undefined">
                        {{ $t(pause ? 'salelotimport.restart' : 'salelotimport.pause') }}
                    </v-btn>
                </template>
            </vlab-base-card-title>
            <v-card-text class="pt-4">
                <div v-marked="text"></div>
                <template v-if="pushData">
                    <v-alert :value="!!pushData.errorMessage" type="error">
                        {{ pushData.errorMessage }}
                    </v-alert>
                </template>
            </v-card-text>
            <v-progress-linear
                :value="progress"
                height="25"
                dark
                :color="progressColor">
                <template #default="{ value }">
                    <div>
                        <strong>{{ current }} / {{ total }}</strong> ({{ Math.ceil(value) }}%)
                    </div>
                </template>
            </v-progress-linear>
        </v-card>
    </v-dialog>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'

export default {
    name: 'hdv-sale-lot-list-import',
    components: { VlabBaseCardTitle },

    props: {
        saleId: { type: String }
    },

    data: () => ({
        pushData: null,
        loading: false,
        show: false,
        pause: false,
        text: null,
        total: 0,
        current: 0,
        limit: 4,
        progressColor: 'success',
        uploadedLotNb: []
    }),

    computed: {
        progress() {
            const ratio = this.current / (this.total || 1) * 100
            return ratio > 100 ? 100 : ratio
        }
    },

    beforeDestroy() {
        this.pushData = undefined
    },

    methods: {
        async start(data) {
            const sale = await this.$store.dispatch('sale/get', { id: this.saleId, include_vacations: true, include_categories: true })

            this.current = 0
            this.total = data.total
            this.loading = true
            this.pause = false
            this.pushData = undefined
            this.progressColor = data.withImages ? 'green darken-1' : 'red darken-1'

            data.nx_sale_id = sale.nx_id

            if (data.vacation) {
                this.text = this.$i18n.t('salelotimport.vacation', {
                    vacation: data.vacation.title,
                    image: this.$i18n.t(data.withImages ? 'salelotimport.with' : 'salelotimport.without'),
                    dest: this.$i18n.t(data.to === 'web' ? 'salelotimport.web' : 'salelotimport.am')
                })
            } else {
                this.text = this.$i18n.t('salelotimport.lots', {
                    range: data.lots_range,
                    image: this.$i18n.t(data.withImages ? 'salelotimport.with' : 'salelotimport.without'),
                    dest: this.$i18n.t(data.to === 'web' ? 'salelotimport.web' : 'salelotimport.am')
                })
            }

            if (['web', 'all'].includes(data.to)) {
                this.limit = 4
                this.show = true
                this.uploadedLotNb = []
                return this.pushFinish(this.pushLots(data, sale.vacations, 0), data)
                    .then(() => {
                        if (data.to === 'all') {
                            if (data.updated_at && this.uploadedLotNb.length) {
                                data.updated_at = undefined
                                data.lots_range = this.$options.filters.makeLotsRange(this.uploadedLotNb)
                            }
                            return this.pushAm(data)
                        }
                    })
            } else {
                return this.pushAm(data)
            }
        },

        pushAm(data) {
            return this.$store
                .dispatch('lot/importAmQueue', {
                    saleId: this.saleId,
                    range: data.lots_range,
                    updated_at: data.updated_at,
                    vacationIds: data.vacation?.id || [],
                    withImages: data.withImages
                })
                .then(res => {
                    this.$success(res, { message: 'salelotimport.queued' })
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = true))
        },

        pushFinish(promise) {
            return promise
                .then(async() => {
                    if (this.pause) {
                        return
                    }
                    this.$success({}, { message: 'salelotimport.finished' })
                    this.$emit('import:done')
                    this.loading = false
                    await this.$nextTick()
                    this.show = false
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        pushLots(data, vacations, offset) {
            const params = {
                nxSaleId: data.nx_sale_id,
                saleId: this.saleId,
                offset,
                limit: this.limit,
                range: data.lots_range,
                updated_at: data.updated_at,
                nxVacationIds: data.vacation?.nx_id || [],
                vacationIds: data.vacation?.id || [],
                withImages: data.withImages
            }

            return this.$store.dispatch('lot/listFromNx', params)
                .then(lots => Promise.all(lots.map(lot => {
                    // on récupère la vacation si on ne l'a pas
                    if (data.vacation?.id) {
                        lot.vacationId = data.vacation.id
                        const category = data.vacation.categories.find(c => c.nx_id === lot.category?.id || lot.category_id)
                        lot.categoryId = category?.id
                    } else {
                        const vacation = vacations.find(v => v.nx_id === lot.vacation?.id || lot.vacation_id)
                        if (!vacation) {
                            throw new Error(this.$i18n.t('salelotimport.novacation', { lotNbFull: `${lot.lotId}${lot.lotIdExt}` }))
                        }
                        lot.vacationId = vacation.id
                        const category = vacation.categories.find(c => c.nx_id === lot.category?.id || lot.category_id)
                        lot.categoryId = category?.id
                    }
                    this.uploadedLotNb.push(lot.lotId)
                    return this.$store.dispatch('lot/import', { saleId: this.saleId, nxLot: lot, lot, withImages: data.withImages }).then(() => {
                        this.current += 1
                    })
                })))
                .catch(err => {
                    this.pushData = { data, vacations, offset, errorMessage: this.$err(err, { skip: true, returnMessage: true }) }
                    this.pause = true
                    throw err
                })
                .then(promises => {
                    // promises peut n'en avoir aucune, car un import en action groupées
                    // sur AM, avec certains lots pas encore sur le site web, ça biaise
                    // le total.
                    if (promises.length && this.current < this.total) {
                        if (this.pause) {
                            this.pushData = { data, vacations, offset: offset + this.limit }
                            return false
                        }
                        return this.pushLots(data, vacations, offset + this.limit)
                    }
                    return true
                })
        },

        togglePause() {
            if (!this.pause) {
                this.loading = false
                this.pause = true
                return
            }
            this.pause = false
            if (!this.pushData) {
                return
            }
            this.loading = true
            this.pushData.errorMessage = null
            return this.pushFinish(this.pushLots(this.pushData.data, this.pushData.vacations, this.pushData.offset))
        }
    }
}
</script>
