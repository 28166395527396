<template>
    <hdv-base-actionable-list-item
        :enabled="action"
        :to="{ name: team.imported ? 'teamform' : 'teamadd', params: { teamId: team.id } }"
        :class="cls">
        <v-list-item-content>
            <v-list-item-title>{{ team.firstname }} {{ team.lastname }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="unactionable">
            <v-btn icon>
                <v-icon color="primary">$vuetify.icons.baseRight</v-icon>
            </v-btn>
        </v-list-item-action>
    </hdv-base-actionable-list-item>
</template>

<script>
import HdvBaseActionableListItem from '@/components/base/HdvBaseActionableListItem'

export default {
    name: 'acs-team-list-item',
    components: { HdvBaseActionableListItem },
    props: {
        team: { type: Object },
        action: { type: Boolean }
    },
    computed: {
        cls() {
            if (this.team.to_remove) {
                return 'warning'
            }
            if (!this.team.imported) {
                return 'red lighten-3'
            }
            return ''
        }
    }
}
</script>
