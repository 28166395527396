<template>
    <v-select
        v-model="selected"
        outlined
        multiple
        :items="items"
        :label="$t('salespecial.categories')"
        clearable />
</template>

<script>

export default {
    name: 'hdv-sale-special-categories',
    props: {
        value: { type: Array, default: () => [] },
        label: { type: String },
        items: { type: Array }
    },
    computed: {
        selected: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    }
}
</script>

<style lang="sass">
.v-select-list .v-subheader
    height: auto
    padding-top: 0.3em
    padding-bottom: 0.3em
    background-color: #eee
    border-top: 1px solid #ccc
    border-bottom: 1px solid #ccc
</style>
