<template>
    <v-text-field
        v-model="text"
        outlined
        disabled
        :label="$t('team.lastname')"
        persistent-hint
        :hint="$t('team.syncHint')" />
</template>

<script>

export default {
    name: 'hdv-team-lastname',
    props: {
        value: { type: String }
    },
    computed: {
        text: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    }
}
</script>
