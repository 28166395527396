<template>
    <v-dialog v-model="show" :max-width="maxWidth" :persistent="persistent">
        <template #activator="{ on }">
            <v-list-item v-on="on"
                :color="color"
                :class="btnClass">
                <slot name="label">
                    <v-list-item-content>
                        <v-list-item-title>{{ label }}</v-list-item-title>
                    </v-list-item-content>
                </slot>
            </v-list-item>
        </template>
        <v-card>
            <v-form v-model="valid" @submit.stop.prevent="submit" :disabled="persistent">
                <vlab-base-card-title :color="color" :title="messageTitle" @close="show = false" />
                <slot name="content">
                    <v-card-text v-marked="message"></v-card-text>
                </slot>
                <v-divider />
                <v-card-actions>
                    <v-btn
                        rounded outlined block
                        type="submit"
                        :color="color"
                        :disabled="!valid"
                        :loading="loading">
                        {{ messageBtn }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'

export default {
    name: 'vlab-base-message-list-item',
    components: { VlabBaseCardTitle },

    props: {
        maxWidth: { type: [String, Number], default: 400 },
        label: { type: String },
        color: { type: String, default: 'primary' },
        disabled: { type: Boolean },
        loading: { type: Boolean },
        value: { type: Boolean },
        btnClass: { type: [String, Object] },
        messageTitle: { type: String },
        messageBtn: { type: String },
        message: { type: String }
    },

    data: () => ({
        valid: false
    }),

    computed: {
        persistent() {
            return this.loading || this.disabled
        },

        show: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },

    methods: {
        submit() {
            this.$emit('submit')
        }
    }
}
</script>
