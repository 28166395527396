<template>
    <v-list>
        <hdv-base-actionable-list
            v-model="action"
            :unactionable="true"
            :total="teams.length"
            :selected.sync="selected"
            @close="selected = []"
            @remove="removeItems"
            :force-visible="true"
            :disabled="loading">
            <template #header>
                <v-btn color="primary" small rounded :outlined="!active" @click="active = !active">actifs</v-btn>
                <hdv-team-category-list />
            </template>
            <template #search>
                <v-text-field
                    v-model="search"
                    hide-details
                    single-line
                    clearable
                    prepend-inner-icon="$vuetify.icons.baseSearch" />
            </template>
        </hdv-base-actionable-list>

        <v-pagination
            v-if="teams.length"
            v-model="page"
            color="primary"
            class="my-4"
            :disabled="loading"
            :length="total"
        />

        <v-list-item-group v-model="selected" :multiple="action">
            <template v-for="(team, i) in teamsChunk">
                <hdv-team-list-item :key="`${i}-${team.id}`" :action="action" :team="team" />
                <v-divider :key="i" />
            </template>
        </v-list-item-group>
        <hdv-base-empty-list :value="!loading && !teams.length" :text="$t('teamlist.empty')" />
        <vlab-base-loader v-model="loading" />
    </v-list>
</template>

<script>
import HdvTeamListItem from '@/components/team-list/HdvTeamListItem'
import HdvTeamCategoryList from '@/components/team-category/HdvTeamCategoryList'
import HdvBaseEmptyList from '@/components/base/HdvBaseEmptyList'
import HdvBaseActionableList from '@/components/base/HdvBaseActionableList'
import lodash from 'lodash'

export default {
    name: 'hdv-team-list',
    components: { HdvTeamListItem, HdvTeamCategoryList, HdvBaseEmptyList, HdvBaseActionableList },
    data: () => ({
        loading: false,
        search: null,
        filter: null,
        active: true,
        // actionable list
        action: false,
        selected: [],
        // pagination
        limit: 20,
        page: 1
    }),
    computed: {
        teamslist() {
            const webteams = this.$store.getters['team/teams']
            const webteamsIndexed = webteams.reduce((acc, team) => {
                acc[team.nx_id] = team
                return acc
            }, {})

            const teams = this.$store.getters['team/nxTeams'].map(nx => {
                const web = webteamsIndexed[nx.id] || {}
                delete webteamsIndexed[nx.id]
                return {
                    ...(web.id ? web : nx),
                    imported: !!web.id,
                    id: web.id || nx.id
                }
            })

            // il se peut qu'un team soit sur le site web, mais ses droits ont
            // été enlevé de Nx. Dans ce cas, on affiche ces teams en haut pour
            // suppression
            teams.unshift(...Object.entries(webteamsIndexed).map(([nxId, team]) => ({
                ...team,
                imported: true,
                to_remove: true
            })))

            return teams
        },
        teams() {
            const tokens = lodash.deburr(lodash.trim(this.filter)).toLowerCase().split(' ').filter(t => t.length >= 3)
            return this.teamslist.filter(item => {
                if (this.active && !item.imported) {
                    return false
                }
                if (!tokens.length) {
                    return true
                }
                const query = lodash.deburr(`${item.firstname} ${item.lastname}`).toLowerCase()
                for (let i = 0; i < tokens.length; i += 1) {
                    if (query.indexOf(tokens[i]) === -1) {
                        return false
                    }
                }
                return true
            })
        },
        teamsChunk() {
            const offset = (this.page - 1) * this.limit
            return this.teams.slice(offset, offset + this.limit)
        },
        total() {
            return Math.ceil(this.teams.length / this.limit)
        },
        language() {
            return this.$store.getters['ln/current']
        }
    },
    watch: {
        search: 'doSearch',
        active: 'doSearch'
    },
    mounted() {
        this.loading = true
        return this.$store.dispatch('team/teams')
            .catch(err => this.$err(err))
            .finally(() => (this.loading = false))
    },
    methods: {
        removeItems() {
            this.loading = true
            return this.$store.dispatch('team/removeByIndexes', { indexes: this.selected })
                .then(res => {
                    this.selected = []
                    this.$success(res, { message: 'team.removed' })
                    this.$router.push({ name: 'teamlist' })
                    this.action = false
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        doSearch: lodash.debounce(function() {
            this.page = 1
            this.filter = this.search
        }, 500)
    }
}
</script>
