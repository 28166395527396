<template>
    <v-select
        v-model="selected"
        outlined
        :items="sortedItems"
        :label="$t('salespecial.story')"
        item-text="title"
        item-value="id"
        clearable />
</template>

<script>

export default {
    name: 'hdv-sale-special-story',
    props: {
        value: { type: [String, Number] },
        items: { type: Array }
    },
    computed: {
        selected: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },

        sortedItems() {
            return this.items.slice().sort((a, b) => {
                return a.title.localeCompare(b.title)
            })
        }
    }
}
</script>
