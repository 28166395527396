export default {
    namespaced: true,

    state: {
        lang: null,
        siteUrl: null,
        siteDefaultLang: 'fr'
    },

    getters: {
        url: state => url => {
            const param = state.siteDefaultLang !== state.lang
                ? `lang=${state.lang}`
                : ''

            return `${state.siteUrl}${url}?${param}`
        }
    },

    mutations: {
        setUrl(state, data) {
            state.siteUrl = data.url
        },

        setLang(state, data) {
            state.lang = data.lang
        }
    }
}
