<template>
    <v-dialog v-model="show" max-width="400">
        <v-card v-if="component">
            <v-form v-model="valid" @submit.stop.prevent="submit" :disabled="loading">
                <vlab-base-card-title
                    :title="$t(label)"
                    @close="show = false" />
                <v-card-text class="mt-3">
                    <hdv-content-selector v-model="component.content_id" :params-definitions.sync="paramsDefinitions" to-detail />
                    <div v-for="p in params" :key="p.param">
                        <hdv-content-page-component-param
                            v-model="p.value"
                            :label="p.param"
                        />
                    </div>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-btn
                        color="primary"
                        type="submit"
                        block
                        rounded
                        :disabled="!valid"
                        :loading="loading">
                        {{ $t('base.save') }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'
import HdvContentSelector from '@/components/content/HdvContentSelector'
import HdvContentPageComponentParam from '@/components/content-page/HdvContentPageComponentParam'

export default {
    name: 'hdv-content-page-component-form',
    components: { HdvContentSelector, HdvContentPageComponentParam, VlabBaseCardTitle },
    props: {
        value: { type: Boolean },
        component: { type: Object },
        pageId: { type: String }
    },
    data: () => ({
        valid: false,
        paramsDefinitions: [],
        loading: false
    }),
    computed: {
        show: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },

        label() {
            return this.component.id
                ? 'contentcomponent.update'
                : 'contentcomponent.add'
        },

        params() {
            const params = { ...(this.component.params || {}) }

            // on efface les paramètres qui ne sont plus d'actualité
            Object.keys(params).forEach(key => {
                if (!this.paramsDefinitions.includes(key)) {
                    delete params[key]
                }
            })

            this.paramsDefinitions.forEach(param => {
                if (params[param] === undefined) {
                    params[param] = null
                }
            })
            return Object.entries(params).map(([param, value]) => ({
                param,
                value
            }))
        }
    },
    methods: {
        submit() {
            this.$set(this.component, 'params', this.params.reduce((acc, p) => {
                acc[p.param] = p.value
                return acc
            }, {}))
            this.$emit('save', this.component)
        }
    }
}
</script>
