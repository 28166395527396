<template>
    <div class="mb-5 hdv-md-editor" :class="{ dense }">
        <p class="my-0">{{ label }}</p>
        <template v-if="!simpletext && !hide">
            <editor
                ref="editor"
                :initialValue="msg"
                :options="options"
                :height="autoHeight ? 'auto' : (dense ? '90px' : '180px')"
                initialEditType="wysiwyg"
                @change="onChange"
                @blur="update" />

            <p v-if="max"
                class="text-right caption"
                :class="{ 'red--text': length > max, 'grey--text': length <= max }">
                {{ length }} / {{ max }}
            </p>
        </template>
        <v-textarea v-else outlined v-model="msg" :counter="max" @blur="update" />
    </div>
</template>

<script>
import lodash from 'lodash'
import '@toast-ui/editor/dist/toastui-editor.css'

import { Editor } from '@toast-ui/vue-editor'

export default {
    name: 'hdv-base-markdown-editor',
    components: { Editor },
    props: {
        value: { type: String },
        id: { type: [String, Number] },
        label: { type: String },
        max: { type: Number, default: 400 },
        simpletext: { type: Boolean },
        autoHeight: { type: Boolean },
        dense: { type: Boolean }
    },
    data: () => ({
        hide: false,
        height: 'auto',
        innerMargin: 36,
        options: {
            hideModeSwitch: true,
            usageStatistics: true,
            autofocus: false,
            toolbarItems: [
                ['heading', 'bold', 'italic', 'strike'],
                ['hr', 'quote'],
                ['ul', 'ol', 'indent', 'outdent'],
                ['table', 'link'],
                ['code', 'codeblock']
            ]
        }
    }),
    computed: {
        msg: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },
        length() {
            return (this.msg || '').length
        }
    },
    watch: {
        id() {
            // on utilise cette astuce pour rafraichir l'éditeur
            // avec le nouveau contenu
            this.hide = true
            this.$nextTick(() => (this.hide = false))
        }
    },
    methods: {
        update() {
            if (!this.simpletext) {
                this.msg = this.$refs.editor.invoke('getMarkdown')
            }
            this.$emit('valid', this.max >= this.length)
        },

        onChange: lodash.debounce(function() {
            this.update()
        }, 500)
    }
}
</script>

<style lang="sass">
.hdv-md-editor
    overflow-y: auto

    &.dense
        .toastui-editor.ww-mode
            min-height: 100px !important

        .toastui-editor-defaultUI .ProseMirror
            padding: 10px

        .toastui-editor-defaultUI-toolbar
            padding: 0px
            height: 30px

        .toastui-editor-toolbar
            height: 30px

        .toastui-editor-defaultUI-toolbar button
            width: 24px
            height: 24px
            margin: 3px 2px

        .toastui-editor-defaultUI button
            font-size: 10px

        .toastui-editor-toolbar-icons
            background-size: 350px 110px

        .toastui-editor-toolbar-divider
            margin: 7px 2px

        .toastui-editor-toolbar-icons:not(:disabled).active
            background-position-y: -17px

        .toastui-editor-toolbar-icons
            &.bold
                background-position-x: -20px
            &.italic
                background-position-x: -39px
            &.strike
                background-position-x: -56px
            &.hrline
                background-position-x: -78px
            &.more
                background-position-x: -309px
            &.quote
                background-position-x: -94px
            &.bullet-list
                background-position-x: -115px
            &.ordered-list
                background-position-x: -134px
            &.indent
                background-position-x: -173px
            &.outdent
                background-position-x: -194px
            &.table
                background-position-x: -213px
            &.link
                background-position-x: -252px
            &.code
                background-position-x: -273px
            &.codeblock
                background-position-x: -291px
            &.bold
                background-position-x: -20px

</style>
