export default {

    props: {
        name: { type: String }
    },

    watch: {
        value() {
            this.$store.commit('formerr/resetError', { name: this.name })
        }
    },

    computed: {
        errorMessages() {
            // eslint-disable-next-line
            const trigger = this.$store.getters['formerr/trigger'] // pour permettre au computed de se mettre à jour

            return this.$store.getters['formerr/getErrors'](this.name)
        }
    }
}
