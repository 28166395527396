<template>
    <v-text-field
        v-model="slug"
        outlined
        :dense="dense"
        :disabled="disabled"
        :label="(label || $t('content.slug')) + (required ? '*' : '')"
        :rules="rules"
        :name="name"
        :error-messages="errorMessages"
        @keypress="checkKey" />
</template>

<script>
import FieldError from '@/shared/mixins/FieldError'
import lodash from 'lodash'

export default {
    name: 'hdv-base-slug-field',

    props: {
        value: { type: String },
        label: { type: String },
        disabled: { type: Boolean },
        required: { type: Boolean },
        dense: { type: Boolean },
        autoFill: { type: String }
    },

    mixins: [FieldError],

    computed: {
        slug: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },

        rules() {
            const rules = [this.$length(140)]
            if (this.required) {
                rules.push(this.$required)
            }
            return rules
        }
    },

    watch: {
        autoFill(v) {
            const slug = this.prepare(v)
            if (!this.slug || this.slug.indexOf(slug) === 0 || slug.indexOf(this.slug) === 0) {
                this.slug = slug
            }
        }
    },

    methods: {
        checkKey(event) {
            const char = String.fromCharCode(event.keyCode)
            if (/^[a-z0-9_/-]+$/.test(char)) {
                return true
            }
            if (['Enter'].includes(event.code)) {
                return true
            }
            event.preventDefault()
        },

        prepare(str) {
            const result = lodash
                .deburr(str || '')
                .toLowerCase()
                .replace(/[^a-z0-9_/]/g, '-')

            const trimmed = lodash.trim(result, '-_/')

            // on remplace les tirets successifs par un seul tiret
            return trimmed.replace(/-{2,}/g, '-')
        }
    }
}
</script>
