<template>
    <v-dialog v-model="show" max-width="1000" scrollable>
        <template #activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs">
                <v-badge overlap color="primary" :content="count" :value="total > 0">
                    <v-icon>$vuetify.icons.queue</v-icon>
                </v-badge>
            </v-btn>
        </template>
        <v-card :loading="loading">
            <vlab-base-card-title :title="$t('queue.title')" @close="show = false" />
            <v-card-text class="pa-0">
                <v-tabs v-model="tab" centered text background-color="grey lighten-5">
                    <v-tab :href="`#jobs`">
                        {{ $t('queue.am.tab') }}
                    </v-tab>
                    <v-tab :href="`#list`">
                        {{ $t('queue.tab') }}
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item value="jobs">
                        <hdv-queue-jobs v-if="show" name="am.pushLots" />
                    </v-tab-item>
                    <v-tab-item value="list">
                        <v-list class="py-0" dense two-line>
                            <v-list-item-group>
                                <v-list-item v-for="queue in queues" :key="queue.id">
                                    <v-list-item-icon>
                                        <v-icon>$vuetify.icons.queue_{{ queue.id }}</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t(`queue.names.${queue.id}`) }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ queue.name }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-list-item-action-text>{{ queue.count | number }}</v-list-item-action-text>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'
import HdvQueueJobs from '@/components/queue/HdvQueueJobs'

export default {
    name: 'hdv-queue-btn',

    components: { VlabBaseCardTitle, HdvQueueJobs },

    data: () => ({
        tab: 'jobs',
        show: false,
        loading: false,
        interval: null,
        countInterval: null,
        max: 99999,
        queues: []
    }),

    computed: {
        formatter() {
            return new Intl.NumberFormat(this.$store.getters['ln/currentWithRegion'])
        },
        total() {
            return this.queues.reduce((acc, queue) => acc + queue.count, 0)
        },
        count() {
            const formatter = this.$options.filters.number
            if (this.total > this.max) {
                return '+' + formatter(this.max)
            }
            return formatter(this.total)
        },
        connected() {
            return this.$store.getters['auth/connected']
        }
    },

    watch: {
        connected: 'stop',
        show(v) {
            if (v) {
                return this.start()
            }
            return this.stop()
        }
    },

    mounted() {
        this.countInterval = window.setInterval(() => this.load(), 20000)
        this.load()
    },

    beforeDestroy() {
        window.clearInterval(this.countInterval)
        return this.stop()
    },

    methods: {
        start() {
            this.interval = window.setInterval(() => this.load(), 5000)
            this.load()
        },

        load() {
            this.loading = true
            return this.$api.get('/queues-todo')
                .then(res => res.data)
                .then(queues => (this.queues = queues))
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        stop() {
            window.clearInterval(this.interval)
        }
    }
}
</script>
