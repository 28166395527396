import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        events: [],
        count: 0,
        types: [
            { id: 'consignment' },
            { id: 'exposition' },
            { id: 'expertise' },
            { id: 'sale' }
        ]
    },

    getters: {
        events: state => state.events,
        count: state => state.count,
        types: state => state.types
    },

    mutations: {
        events(state, data) {
            state.events = data.events
        },

        add(state, data) {
            state.events.splice(0, 0, data.event)
        },

        update(state, data) {
            const index = state.events.findIndex(cal => cal.id === data.id)
            if (index !== -1) {
                state.events.splice(index, 1, data.event)
            } else {
                state.events.push(data.event)
            }
        },

        remove(state, data) {
            const index = state.events.findIndex(cal => cal.id === data.id)
            if (index !== -1) {
                state.events.splice(index, 1)
            }
        }
    },

    actions: {
        async events({ state, commit }, { search, offset, limit } = {}) {
            const [res, resCount] = await Promise.all([
                Vue.prototype.$api.get('/events', {
                    params: {
                        mapping: 'list',
                        offset,
                        limit,
                        search
                    }
                }),
                offset ? null : Vue.prototype.$api.head('/events', {
                    params: {
                        search
                    }
                })
            ])
            commit('events', { events: res.data })

            if (resCount) {
                console.log(resCount)
                state.count = Number(resCount.headers['x-hdv-list-count'] || 0)
            }

            return res.data
        },

        async create(context, data) {
            const res = await Vue.prototype.$api.post('/events', data.event)
            context.commit('add', { event: res.data })
            return res.data
        },

        async get(context, data) {
            const res = await Vue.prototype.$api.get(`/events/${data.id}`, {
                meta: { lang: '*' },
                params: {
                    mapping: null
                }
            })
            return res.data
        },

        async update({ commit }, { id, event }) {
            const res = await Vue.prototype.$api.put(`/events/${id}`, event)
            commit('update', { id, event })
            return res.data
        },

        async remove(context, data) {
            await Vue.prototype.$api.delete(`/events/${data.id}`)
            context.commit('remove', { id: data.id })
        },

        async removeByIndexes(context, data) {
            const items = context.state.events.filter((_, i) => data.indexes.indexOf(i) !== -1).slice()
            return Promise.all(items.map(item => context.dispatch('remove', { id: item.id })))
        },

        async duplicate(context, data) {
            // récupération de l'objet pour édition
            const event = await context.dispatch('get', { id: data.id })
            event.id = undefined
            event.name = `DUPLICATION ${event.name}`
            event.name_i18n[data.lng] = `DUPLICATION ${event.name_i18n[data.lng]}`
            event.date = new Date().toISOString()
            return context.dispatch('create', { event: event })
        },

        async duplicateByIndexes(context, data) {
            const items = context.state.events.filter((_, i) => data.indexes.indexOf(i) !== -1).slice()
            return Promise.all(items.map(item => context.dispatch('duplicate', {
                id: item.id,
                lng: data.lng
            })))
        }
    }
}
