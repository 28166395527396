import Vue from 'vue'
import i18next from 'i18next'

Vue.prototype.$err = function(err, data, form) {
    data = data || {
        returnMessage: false,
        skip: false
    }

    let message = ''
    if (err.response) {
        const e = err.response.data || {}
        message = e.message || err.message

        if (form && e.error?.form_field_name) {
            // on va setter des infos sur les champs correspondants
            const f = e.error.form_field_name
            const field = form.inputs.find(i => i.$parent?.$options?.propsData?.name === f)
            if (field) {
                this.$store.commit('formerr/addError', { name: f, error: message })
            }
        }
    } else if (err.request) {
        message = 'Request error'
    } else {
        message = err.message
    }
    if (data.returnMessage) {
        return message
    }
    this.$store.commit('msg/show', {
        message: message,
        type: 'error',
        ...data
    })
    if (data.skip) {
        return
    }
    throw err
}

Vue.prototype.$success = function(res, data) {
    data = data || {}
    const msg = i18next.t(data.message, (res && res.data) || res)
    delete data.message
    this.$store.commit('msg/show', {
        message: msg,
        type: 'info',
        ...data
    })
}
