<template>
    <v-card :loading="loading">
        <v-form v-model="valid" @submit.stop.prevent="submit" :disabled="loading">
            <vlab-base-card-title :title="$t('team.form')" @close="back" :dismissible="!loading" />
            <v-card-text>
                <hdv-team-nx-import
                    :id="!nx ? teamId : null"
                    :nx-id="team.nx_id || teamId" />

                <v-row>
                    <v-col>
                        <hdv-team-firstname :value="team.firstname" />
                        <hdv-team-lastname :value="team.lastname" />
                        <hdv-team-email :value="team.email" />
                        <hdv-team-tags :value="team.tags" />
                    </v-col>
                    <v-col v-if="!nx">
                        <hdv-base-image
                            :preview.sync="team.image_url_preview"
                            :src.sync="team.image_url"
                            :label="$t(`team.image`)"
                            :auto-start="!!team.id"
                            :url="`/teams/${team.id}/image`"
                            @loaded="v => loading = v" />
                    </v-col>
                </v-row>

                <template v-if="!nx">
                    <v-switch :label="$t('team.is_specialist')" v-model="team.is_specialist" />
                    <v-switch :label="$t('team.is_auctioneer')" v-model="team.is_auctioneer" />
                    <hdv-team-category-selector v-model="team.category_ids" />
                </template>
            </v-card-text>
            <v-card-actions v-if="!nx">
                <v-spacer />
                <v-btn
                    rounded
                    outlined
                    color="primary"
                    type="submit"
                    :loading="loading"
                    :disabled="!valid">
                    {{ $t('base.save') }}
                </v-btn>
                <v-spacer />
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'
import HdvTeamFirstname from '@/components/team/HdvTeamFirstname'
import HdvTeamLastname from '@/components/team/HdvTeamLastname'
import HdvTeamCategorySelector from '@/components/team/HdvTeamCategorySelector'
import HdvTeamEmail from '@/components/team/HdvTeamEmail'
import HdvTeamTags from '@/components/team/HdvTeamTags'
import HdvTeamNxImport from '@/components/team/HdvTeamNxImport'
import HdvBaseImage from '@/components/base/HdvBaseImage'

export default {
    name: 'team-form',
    props: {
        teamId: { type: [String, Number] },
        nx: { type: Boolean }
    },
    components: {
        VlabBaseCardTitle,
        HdvTeamCategorySelector,
        HdvBaseImage,
        HdvTeamFirstname,
        HdvTeamLastname,
        HdvTeamTags,
        HdvTeamEmail,
        HdvTeamNxImport
    },
    data: () => ({
        loading: false,
        valid: false,
        tab: null,
        team: {
            category_ids: []
        }
    }),
    computed: {
        edit() {
            return this.teamId !== 'add'
        }
    },
    watch: {
        $route: 'load'
    },
    mounted() {
        return this.load()
    },
    methods: {

        load() {
            if (!this.edit) {
                return
            }
            this.loading = true
            return Promise
                .all([
                    this.$store.dispatch('team/get', { id: this.teamId }),
                    this.$store.dispatch('team/categories')
                ])
                .then(([team]) => (this.team = team))
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        back() {
            this.$router.push({ name: 'teamlist' })
        },

        submit() {
            this.loading = true
            return this.$store.dispatch('team/update', { id: this.team.id, team: this.team })
                .then(team => {
                    this.$success(team, { message: 'team.saved' })
                    if (!this.edit) {
                        this.$router.push({ name: 'teamform', params: { id: team.id } })
                    }
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
