<template>
    <v-row>
        <v-col class="pr-0" cols="12" lg="3" sm="6">
            <hdv-content-list />
        </v-col>
        <v-col class="px-0" cols="12" lg="9" sm="6">
            <router-view></router-view>
        </v-col>
    </v-row>
</template>

<script>
import HdvContentList from '@/components/content-list/HdvContentList'

export default {
    name: 'content-page',
    components: {
        HdvContentList
    }
}
</script>
