<template>
    <div class="d-flex">
        <template v-for="name in names">
            <p v-if="name === 'hour'" :key="`sep-${name}`" class="mx-5">&nbsp;</p>

            <v-text-field
                :key="name"
                v-model="dateParts[name]"
                :label="$t(`shared-front:date.${name}`)"
                @click:append-outer="onSearch"
                @keypress.enter="onSearch"
                :disabled="disabled"
                :append-outer-icon="name === 'minute' ? '$vuetify.icons.baseSearch' : undefined"
                outline
            />
        </template>
    </div>
</template>

<script>
export default {
    name: 'hdv-sale-lot-list-search-latest',

    props: {
        value: { type: [Date] },
        disabled: { type: Boolean }
    },

    data: () => ({
        dateParts: {
            day: null,
            month: null,
            year: null,
            hour: null,
            minute: null
        }
    }),

    computed: {
        names() {
            return Object.keys(this.dateParts)
        }
    },

    mounted() {
        const date = new Date()
        date.setMinutes(0, 0, 0)

        this.dateParts.day = date.getDate()
        this.dateParts.month = date.getMonth() + 1
        this.dateParts.year = date.getFullYear()
        this.dateParts.hour = date.getHours()
        this.dateParts.minute = date.getMinutes()

        this.$emit('input', date)
    },

    methods: {
        async onSearch() {
            const part = this.dateParts
            const date = new Date(part.year, part.month - 1, part.day, part.hour, part.minute)

            this.$emit('input', date)
            await this.$nextTick()
            this.$emit('search', date)
        }
    }
}
</script>
