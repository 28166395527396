<template>
    <v-autocomplete
        v-model="tags"
        :items="items"
        :loading="loading"
        :search-input.sync="filter"
        chips
        small-chips
        deletable-chips
        cache-items
        multiple
        hide-no-data
        outlined
        :label="$t('department.tags')" />
</template>

<script>
import lodash from 'lodash'

export default {
    name: 'hdv-department-tags',
    props: {
        value: { type: Array },
        departmentId: { type: String }
    },
    data: () => ({
        loading: false,
        filter: null,
        items: []
    }),
    computed: {
        tags: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },
    watch: {
        filter: 'loadDebounce',
        departmentId: 'init'
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            if (this.tags) {
                this.items = this.tags.slice()
            }
        },

        load(filter) {
            if (filter && filter.length < 3) {
                return
            }
            this.loading = true
            return this.$store.dispatch('dp/listTags', { filter })
                .then(tags => (this.items = tags))
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        loadDebounce: lodash.debounce(function(filter) {
            return this.load(filter)
        }, 500)
    }
}
</script>
