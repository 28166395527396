export default {
    namespaced: true,

    state: {
        retry: false,
        loading: false,
        data: {}
    },

    getters: {
        retry: state => state.retry,
        loading: state => state.loading
    },

    mutations: {
        retry(state, data) {
            state.retry = data
        },

        loading(state, { loading, message }) {
            state.data = {
                message
            }
            state.loading = loading
        }
    }
}
