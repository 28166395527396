<template>
    <hdv-base-actionable-list-item
        :enabled="action"
        class="draggable-item"
        :to="{ name: 'departmentform', params: { departmentId: department.id } }">
        <v-list-item-avatar rounded>
            <v-img v-if="department.image_url_preview" :src="department.image_url_preview | imagecrop({ width: 80, f: 'cover' })" />
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title>{{ department.title }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="unactionable">
            <v-btn icon>
                <v-icon color="primary">$vuetify.icons.baseRight</v-icon>
            </v-btn>
        </v-list-item-action>
    </hdv-base-actionable-list-item>
</template>

<script>
import HdvBaseActionableListItem from '@/components/base/HdvBaseActionableListItem'

export default {
    name: 'acs-department-list-item',
    components: { HdvBaseActionableListItem },
    props: {
        department: { type: Object },
        action: { type: Boolean }
    }
}
</script>
