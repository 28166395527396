<template>
    <v-list-item>
        <v-avatar
            rounded
            size="52"
            color="grey lighten-3">
            <v-img v-if="lot.item.images.length && lot.web" :src="lot.item.images[0].path_preview | imagecrop({ w: 52, h: 52, f: 'cover' })" alt="image" />
            <v-img v-else-if="lot.item.images.length && !lot.web" :src="lot.item.images[0].href" alt="image" />
        </v-avatar>
        <v-list-item-content class="pl-4" :class="{ 'warning--text': !lot.web }">
            <v-list-item-title>{{ lot.item.title }}</v-list-item-title>
            <v-list-item-subtitle>{{ $t('salehighlight.estimates', { down: lot.item.estimate_down, up: lot.item.estimate_up }) }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ lot.lot_nb}}{{ lot.lot_nb_ext }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
            <hdv-sale-lot-list-actions :lot="lot" :web="lot.web" @click:import="importLot" @click:remove="removeLot" :seo="lot.web" listitem>
                <template #activator="{ on, attrs }">
                    <v-btn color="primary" icon small v-on="on" v-bind="attrs">
                        <v-icon small>$vuetify.icons.baseMenu</v-icon>
                    </v-btn>
                </template>
            </hdv-sale-lot-list-actions>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
import HdvSaleLotListActions from '@/components/sale-lot-list/HdvSaleLotListActions'

export default {
    name: 'hdv-sale-lot-list-item',
    components: { HdvSaleLotListActions },
    props: {
        lot: { type: Object }
    },
    data: () => ({
        loading: false
    }),
    methods: {
        importLot(ev) {
            ev.total = 1
            ev.lots_range = `${this.lot.lot_nb}${this.lot.lot_nb_ext || ''}`
            this.$emit('click:import', ev)
        },

        removeLot() {
            this.$emit('click:remove', { lot: this.lot })
        }
    }
}
</script>
