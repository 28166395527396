<template>
    <div :class="{ 'd-flex': inline }">
        <vlab-base-upload-btn
            ref="upload"
            class="grow mr-3"
            :block="!inline"
            :multiple="false"
            :loading="loading"
            :auto-start="model === 'sale' || !!specialId"
            :url="url"
            :label="$t(`salecatalog.upload${type}`)"
            :axios="$api"
            :accept="accept"
            rounded
            @upload:start="$emit('loaded', true)"
            @upload:success="onSuccess"
            @upload:finish="$emit('loaded', false)" />

        <slot name="append"></slot>
    </div>
</template>

<script>
import VlabBaseUploadBtn from '@/shared/components/base/VlabBaseUploadBtn'

export default {
    name: 'hdv-sale-special-upload',
    components: { VlabBaseUploadBtn },
    props: {
        value: { type: String },
        saleId: { type: String },
        specialId: { type: String },
        inline: { type: Boolean },
        image: { type: Boolean },
        pdf: { type: Boolean },
        type: { type: String },
        model: { type: String }
    },
    data: () => ({
        loading: false
    }),
    computed: {
        url() {
            if (this.model === 'sale') {
                return `/sales/${this.saleId}/${this.type}`
            }
            return `/sales/${this.saleId}/specials/${this.specialId}/${this.type}`
        },
        accept() {
            if (this.pdf) {
                return ['application/pdf']
            }
            if (this.image) {
                return ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/webp']
            }
            return []
        }
    },
    methods: {
        upload() {
            return this.$refs.upload.upload()
        },
        onSuccess(file) {
            this.$emit('input', file.response.url)
            this.$emit('success', file.response)
        }
    }
}
</script>
