<template>
    <v-dialog v-model="show" max-width="400" :persistent="loading">
        <template #activator="{ on, attrs }">
            <v-btn small icon v-on="on" v-bind="attrs">
                <v-icon>$vuetify.icons.saleCurrent</v-icon>
            </v-btn>
        </template>
        <v-card>
            <vlab-base-card-title :title="$t('salecurrent.title')" @close="show = false" :dismissible="!loading" />
            <v-card-text class="pt-5">
                <div v-marked="$t('salecurrent.text', { sale: title })"></div>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-btn rounded block color="primary" @click="setCurrent">
                    {{ $t('salecurrent.submit') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'

export default {
    name: 'hdv-sale-current-btn',
    components: { VlabBaseCardTitle },
    props: {
        saleId: { type: String },
        title: { type: String }
    },
    data: () => ({
        show: false,
        loading: false
    }),
    methods: {
        setCurrent() {
            this.loading = true
            return this.$store.dispatch('sale/setCurrentSale', { saleId: this.saleId })
                .then(() => {
                    this.show = false
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
