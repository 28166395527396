<template>
    <v-card flat>
        <vlab-base-card-title :title="$t('contentpage.menu')" :dismissible="false" hide-spacer>
            <template #buttons>
                <v-spacer />
                <v-btn rounded outlined dark small :to="{ name: 'contentpageform', params: { pageId: 'add' } }">
                    {{ $t('contentpagetree.addroot') }}
                </v-btn>
            </template>
        </vlab-base-card-title>

        <v-card-text>
            <div v-marked="$t('contentpagetree.sorthelp')"></div>

            <v-btn small rounded :outlined="disabled" color="primary" @click="disabled = !disabled">
                {{ $t(disabled ? 'contentpagetree.enabledrag' : 'contentpagetree.disabledrag') }}
            </v-btn>
        </v-card-text>

        <Tree
            v-if="pages"
            ref="tree"
            :value="pages"
            :ondragstart="drag(true)"
            :ondragend="drag(false)"
            :draggable="!loading && !disabled"
            :droppable="!loading && !disabled"
            :eachDroppable="checkDrop"
            @drop="drop"
            trigger-class="acs-tree-drag"
            edge-scroll
            :indent="20">
            <v-list-item
                slot-scope="{ node, path, tree }"
                class="px-2 hdv-content-pages primary--text"
                :class="{ dragging: dragging }"
                :to="{ name: 'contentpageform', params: { pageId: node.id } }">
                <v-list-item-icon class="acs-tree-drag mr-1">
                    <v-icon color="grey lighten-1">$vuetify.icons.baseSort</v-icon>
                </v-list-item-icon>
                <v-list-item-icon class="mr-1">
                    <v-icon @click="tree.toggleFold(node, path)" v-if="Array.isArray(node.children) && node.children.length">
                        {{ node.$folded ? '$vuetify.icons.baseMenuRight' : '$vuetify.icons.baseMenuDown' }}
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-icon>
                    <v-icon :color="node.disabled || node.readonly ? 'red' : 'green'">$vuetify.icons.disabledState</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ node.name }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action-text>
                    <hdv-content-page-tree-item-menu
                        :page="node"
                        @remove="removeItem(node)" />
                </v-list-item-action-text>
            </v-list-item>
        </Tree>

        <hdv-base-empty-list
            :value="!loading && (!Array.isArray(pages) || !pages.length)"
            :text="$t('contentpagetree.empty')" />

        <vlab-base-loader v-model="loading" />
    </v-card>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'
import HdvBaseEmptyList from '@/components/base/HdvBaseEmptyList'
import HdvContentPageTreeItemMenu from '@/components/content-page-tree/HdvContentPageTreeItemMenu'

import lodash from 'lodash'

import { Tree, Fold, Draggable } from 'he-tree-vue'
import 'he-tree-vue/dist/he-tree-vue.css'

export default {
    name: 'hdv-content-page-tree',
    components: {
        VlabBaseCardTitle,
        HdvBaseEmptyList,
        HdvContentPageTreeItemMenu,
        Tree: Tree.mixPlugins([Fold, Draggable])
    },
    data: () => ({
        loading: false,
        disabled: true,
        dragging: false,
        edit: false,
        editPage: null,
        unavailable: false
    }),
    computed: {
        pages() {
            return this.$store.getters['page/pages']
        }
    },
    mounted() {
        this.loading = true
        return this.$store.dispatch('page/pages', {})
            .catch(err => this.$err(err))
            .finally(() => (this.loading = false))
    },
    methods: {

        addItem(parent) {
            this.editPage = {
                name: 'test',
                slug: 'test',
                disabled: false,
                parent
            }
            this.edit = true
        },

        removeItem(item) {
            this.loading = true
            return this.$store.dispatch('page/remove', { pageId: item.id, page: item })
                .then(res => this.$success(res, { message: 'contentpagetree.removed' }))
                .then(() => this.$store.dispatch('page/sorts', {}))
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        checkDrop(path, tree) {
            const node = tree.getNodeByPath(path)
            return !node || node.children?.length
        },

        drag(active) {
            return () => {
                this.dragging = active
                const action = active ? 'cls/add' : 'cls/remove'
                this.$store.commit(action, { type: 'header', cls: 'hdv-scrollable' })
                return true
            }
        },

        async drop(evt) {
            // targetPath est calculé AVANT le drop. En fonction de la direction,
            // du drop (vers le bas), l'index de l'ancien parent change. L'astuce
            // ci-dessous permet de rattraper le coup
            let debugcheck = null
            for (let i = 0; i < evt.targetPath.length; i += 1) {
                if (evt.startPath[i] !== evt.targetPath[i] && i !== evt.startPath.length - 1) {
                    break
                }
                debugcheck = i
            }

            // adapt count in target tree
            for (let i = 0; i < evt.targetPath.length - 1; i += 1) {
                // targetPath est calculé AVANT le drop. En fonction de la direction,
                // du drop (vers le bas), l'index de l'ancien parent change. L'astuce
                // ci-dessous permet de rattraper le coup
                if (i === debugcheck && evt.targetPath[i] > evt.startPath[i]) {
                    evt.targetPath[i] -= 1
                }
            }
            // --

            const tree = this.pages
            const newParent = lodash.get(tree, evt.targetPath.reduce((r, a) => r.concat(a, 'children'), []).slice(0, -3))
            let promise = Promise.resolve()

            // Parent changed
            if (newParent?.id !== evt.dragNode.parent_id) {
                evt.dragNode.parent_id = newParent?.id || null
                promise = this.$store.dispatch('page/edit', {
                    pageId: evt.dragNode.id,
                    page: evt.dragNode,
                    moved: true
                })
            }

            return promise
                .then(() => this.$store.dispatch('page/sorts', {}))
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>

<style lang="sass">
.he-tree
    .tree-node
        border: 0
        margin-bottom: 0
        padding: 0

    .tree-branch.tree-placeholder
        background-color: #fff

        .tree-placeholder-node
            background-color: #eee
            min-height: 60px

.hdv-content-pages

    &.v-list-item:not(.dragging):hover
        background-color: #fff

    &.v-list-item.dragging
        border: 1px solid #ccc
        border-left-width: 3px
</style>
