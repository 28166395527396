<template>
    <v-combobox
        v-model="selected"
        :items="items"
        :menu-props="menuProps"
        small-chips
        deletable-chips
        multiple
        hide-selected
        outlined
        :label="$t('content.paramsDefinitions')">
    </v-combobox>
</template>

<script>
export default {
    name: 'hdv-content-param-definition',
    props: {
        value: { type: Array }
    },
    data: () => ({
        items: [],
        menuProps: {
            maxZindex: 110
        }
    }),
    computed: {
        selected: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },
    mounted() {
        this.items = this.value.slice()
    }
}
</script>
