<template>
    <v-text-field
        v-model="slug"
        outlined
        :dense="dense"
        :label="(label || $t('contentblock.internalslug')) + (required ? '*' : '')"
        :rules="required ? [$required] : []" />
</template>

<script>

export default {
    name: 'hdv-content-block-internal-slug',
    props: {
        value: { type: String },
        label: { type: String },
        required: { type: Boolean },
        dense: { type: Boolean }
    },
    computed: {
        slug: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    }
}
</script>
