<template>
    <v-textarea
        v-model="text"
        :label="$t('contentcategory.blocks')"
        outlined
        rows="7" />
</template>

<script>
export default {
    name: 'hdv-content-category-blocks',
    props: {
        value: { type: [Array, Object, String] }
    },
    computed: {
        text: {
            get() {
                return this.value && JSON.stringify(this.value, null, 2)
            },
            set(v) {
                try {
                    this.$emit('input', v && JSON.parse(v))
                } catch (err) {
                    // swallow error
                }
            }
        }
    }
}
</script>
