<template>
    <v-autocomplete
        v-model="selected"
        :label="$t('content.selector')"
        :items="items"
        :search-input.sync="search"
        :loading="loading"
        :dense="dense"
        :filter="() => true"
        item-text="title"
        item-value="id"
        outlined>
        <template #item="{ item }">
            <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.alias }}</v-list-item-subtitle>
            </v-list-item-content>
        </template>
        <template v-if="toDetail" #append-outer>
            <v-btn color="primary" target="_blank" :to="{ name: 'contentform', params: {contentId: selected } }" icon small class="ma-0">
                <v-icon>$vuetify.icons.baseToDetail</v-icon>
            </v-btn>
        </template>
    </v-autocomplete>
</template>

<script>
// import lodash from 'lodash'

export default {
    name: 'hdv-content-selector',
    props: {
        value: { type: String },
        paramsDefinitions: { type: Array, default: () => [] },
        dense: { type: Boolean },
        toDetail: { type: Boolean }
    },
    data: () => ({
        search: null,
        items: [],
        loading: false
    }),
    computed: {
        selected: {
            get() {
                return this.value
            },
            set(v) {
                const s = this.items.find(c => c.id === v)
                if (s) {
                    this.$emit('update:params-definitions', s.params_definitions)
                }
                this.$emit('input', v)
            }
        }
    },

    watch: {
        search: 'load'
    },

    mounted() {
        return this.fetch()
    },

    methods: {
        load() {
            this.loading = true
            return this.$store.dispatch('ct/list', { limit: 50, search: this.search })
                .then(({ contents }) => (this.items = contents))
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        fetch() {
            if (!this.selected || !!this.items.find(c => c.id === this.selected)) {
                return
            }
            this.loading = true
            return this.$store.dispatch('ct/get', { contentId: this.selected })
                .then(content => {
                    this.items.push(content)
                    this.$emit('update:params-definitions', content.params_definitions)
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
