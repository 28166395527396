<template>
    <v-row>
        <v-col class="pr-0" cols="12" lg="4" sm="6">
            <hdv-sale-special-list
                :sale-id="saleId"
                :disabled="disabled" />
        </v-col>
        <v-col class="px-0" cols="12" lg="8" sm="6">
            <router-view></router-view>
        </v-col>
    </v-row>
</template>

<script>
import HdvSaleSpecialList from '@/components/sale-special-list/HdvSaleSpecialList'

export default {
    name: 'sale-special',
    components: {
        HdvSaleSpecialList
    },
    props: {
        saleId: { type: [String, Number] },
        disabled: { type: Boolean }
    }
}
</script>
