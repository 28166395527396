<template>
    <v-card max-width="450">
        <vlab-base-card-title :title="block.alias" @close="showRemove = true">
            <template #buttons>
                <v-btn dark text icon small @click="save">
                    <v-icon small>$vuetify.icons.baseSave</v-icon>
                </v-btn>
            </template>
        </vlab-base-card-title>
        <v-card-text>
            <hdv-content-block-alias v-model="block.alias" :disabled="!!block.id" dense required />
            <hdv-content-block-type v-model="block.type" :disabled="!!block.id" dense />

            <hdv-base-i18n-area v-if="block.type">
                <template #default="{ ln, isDefault }">
                    <hdv-content-title
                        v-model="block.title_i18n[ln]"
                        @input="v => onTitleChange(v, isDefault)"
                        dense />

                    <hdv-content-description
                        v-model="block.description_i18n[ln]"
                        :label="$t('contentblock.description')"
                        dense />

                    <template v-if="block.type === 'internalslug'">
                        <hdv-content-block-internal-slug
                            v-model="block.internal_slug_i18n[ln]"
                            :required="isDefault"
                            dense />
                    </template>
                </template>
            </hdv-base-i18n-area>

            <template v-if="block.type === 'pagelink'">
                <hdv-content-page-selector v-model="block.page_id" dense />
            </template>

            <template v-if="block.type === 'image'">
                <hdv-content-block-image
                    ref="upload"
                    :content-id="contentId"
                    :block-id="block.id"
                    :preview.sync="block.image_url_preview"
                    :src.sync="block.image_url"
                    @loaded="v => loading = v"
                    small />
            </template>
        </v-card-text>

        <v-dialog v-model="showRemove" max-width="400">
            <v-card>
                <vlab-base-card-title :title="block.alias" color="red" @close="showRemove = false" />
                <v-card-text>
                    <div v-marked="$t('contentblock.remove', { alias: block.alias })"></div>
                </v-card-text>
                <v-card-actions>
                    <v-btn block color="red" rounded dark @click="remove">{{ $t('base.remove') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'
import HdvBaseI18nArea from '@/components/base/HdvBaseI18nArea'

import HdvContentBlockType from '@/components/content/HdvContentBlockType'
import HdvContentBlockAlias from '@/components/content/HdvContentBlockAlias'
import HdvContentTitle from '@/components/content/HdvContentTitle'
import HdvContentDescription from '@/components/content/HdvContentDescription'
import HdvContentPageSelector from '@/components/content-page/HdvContentPageSelector'
import HdvContentBlockImage from '@/components/content/HdvContentBlockImage'
import HdvContentBlockInternalSlug from '@/components/content/HdvContentBlockInternalSlug'
import lodash from 'lodash'

export default {
    name: 'hdv-content-block',

    components: {
        VlabBaseCardTitle,
        HdvBaseI18nArea,
        HdvContentBlockType,
        HdvContentTitle,
        HdvContentDescription,
        HdvContentBlockAlias,
        HdvContentPageSelector,
        HdvContentBlockImage,
        HdvContentBlockInternalSlug
    },

    props: {
        contentId: { type: String },
        blockId: { type: String },
        blockData: { type: Object }
    },

    data: () => ({
        loading: false,
        showRemove: false,
        block: {}
    }),

    mounted() {
        this.block = lodash.cloneDeep(this.blockData)
    },

    methods: {
        onTitleChange(value, isDefault) {
            if (isDefault) {
                this.block.title = value
            }
        },

        save() {
            if (!this.block.type || !this.block.alias) {
                return
            }
            this.loading = true
            const action = this.block.id ? 'ct/updateBlock' : 'ct/addBlock'
            return this.$store.dispatch(action, { contentId: this.contentId, blockId: this.block.id, block: this.block })
                .then(async block => {
                    if (!this.block.id) {
                        this.block.id = block.id
                        if (this.block.type === 'image') {
                            await this.$refs.upload.upload()
                        }
                    }
                    this.$success(block, { message: 'contentblock.saved' })
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        remove() {
            this.showRemove = false
            this.$emit('remove', this.blockId)
        }
    }
}
</script>
