<template>
    <v-switch
        v-model="boolean"
        :label="label"
        :rules="rules" />
</template>

<script>
export default {
    name: 'vlab-base-switch',

    props: {
        value: { type: Boolean },
        inverse: { type: Boolean },
        label: { type: String },
        rules: { type: Array, default: () => [] }
    },

    computed: {
        boolean: {
            get() {
                if (this.inverse) {
                    return !this.value
                }
                return this.value
            },
            set(v) {
                this.$emit('input', this.inverse ? !v : v)
            }
        }
    }
}
</script>
