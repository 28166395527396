<template>
    <hdv-base-actionable-list-item
        :enabled="action"
        :to="{ name: 'contentform', params: { contentId: content.id } }">
        <v-list-item-content>
            <v-list-item-title>{{ content.title }}</v-list-item-title>
            <v-list-item-subtitle>{{ content.alias }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="unactionable">
            <v-btn icon>
                <v-icon color="primary">$vuetify.icons.baseRight</v-icon>
            </v-btn>
        </v-list-item-action>
    </hdv-base-actionable-list-item>
</template>

<script>
import HdvBaseActionableListItem from '@/components/base/HdvBaseActionableListItem'

export default {
    name: 'acs-content-list-item',
    components: { HdvBaseActionableListItem },
    props: {
        content: { type: Object },
        action: { type: Boolean }
    }
}
</script>
