<template>
    <v-navigation-drawer app temporary fixed right
        class="sidebar"
        width="304"
        v-model="drawer">

        <v-list class="pt-0">
            <v-toolbar dense flat class="vlab-menu--bar">
                <vlab-base-ln-selector />
                <v-spacer />
                <v-btn icon text @click="drawer = false" color="primary">
                    <v-icon>$vuetify.icons.baseMenuRight</v-icon>
                </v-btn>
            </v-toolbar>

            <h5 class="pa-4 pb-0" v-if="user.id">{{ username }}</h5>
            <h5 class="pa-4 pt-0">{{ user.email }}</h5>

            <template v-for="(item, index) in items">
                <v-divider v-if="item.divider" :key="index" />
                <v-list-item v-else
                    :key="item.text"
                    :to="item.to"
                    exact
                    @click="(item.click && item.click(item)) || null">
                    <v-list-item-action>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>

        <template #append>
            <v-footer color="transparent">
                <span class="grey--text">{{ $t('base.powered') }}<br />v{{ $config.version }}</span>
                <v-spacer />
                <v-img src="/favicon.png" max-width="50" contain />
            </v-footer>
        </template>
    </v-navigation-drawer>
</template>

<script>
import VlabBaseLnSelector from '@/shared/components/base/VlabBaseLnSelector'

export default {
    name: 'hdv-base-menu',
    components: { VlabBaseLnSelector },
    props: {
        value: { type: Boolean }
    },
    computed: {
        drawer: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },
        user() {
            return this.$store.getters['auth/user']
        },
        username() {
            if (!this.user) {
                return ''
            }
            const n = [this.user.firstname, this.user.lastname].filter(v => !!v).join(' ')
            return n || this.user.alias
        },
        items() {
            const menu = []
            if (this.user.id) {
                menu.push(
                    { click: this.logoutUser, icon: '$vuetify.icons.logout', text: 'login.logout' }
                )
            }
            return menu
        }
    },
    methods: {

        logout() {
            this.show = false
            this.$router.push('/')
        },

        logoutUser() {
            return this.$store.dispatch('auth/logout')
                .then(() => {
                    this.show = false
                    this.$router.push({ name: 'login' })
                })
        }
    }
}
</script>
