<template>
    <v-alert
        v-if="current"
        dark
        tile
        dense
        small
        color="warning">
        <strong>{{ $t('salecurrent.title') }}</strong>
        <br />
        {{ current.title }}
    </v-alert>
</template>

<script>
export default {
    name: 'hdv-sale-current',
    computed: {
        current() {
            return this.$store.getters['sale/current']
        }
    },
    mounted() {
        return this.$store.dispatch('sale/getCurrentSale')
            .catch(err => this.$err(err))
    }
}
</script>
