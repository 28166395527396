<template>
    <v-container fluid>
        <v-row>
            <v-col class="pa-0" cols="12" lg="6" sm="7">
                <vlab-base-card-title :title="$t('teamlist.menu')" :dismissible="false" />
                <div class="my-5 px-5" v-marked="$t('teamlist.help')"></div>
                <hdv-team-list class="vlab-home--card" />
            </v-col>
            <v-col class="pa-0" cols="12" lg="6" sm="5">
                <router-view></router-view>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'
import HdvTeamList from '@/components/team-list/HdvTeamList'

export default {
    name: 'team-list',
    components: {
        HdvTeamList,
        VlabBaseCardTitle
    }
}
</script>
