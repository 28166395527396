<template>
    <v-row>
        <v-col>
            <vlab-base-upload-btn
                ref="upload"
                rounded
                :multiple="false"
                :auto-start="!!blockId"
                :loading="loading"
                :url="url"
                :label="$t(`contentblock.image`)"
                :axios="$api"
                :accept="['image/jpg', 'image/jpeg', 'image/png', 'image/gif']"
                @upload:start="$emit('loaded', true)"
                @upload:finish="$emit('loaded', false)"
                @upload:success="onSuccess" />
        </v-col>
        <v-col v-if="preview">
            <a target="_blank" :href="src">
                <v-img :src="preview | imagecrop({ w: 400, f: 'cover' })">
                    <template #placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-5" />
                        </v-row>
                    </template>
                </v-img>
            </a>
        </v-col>
    </v-row>
</template>

<script>
import VlabBaseUploadBtn from '@/shared/components/base/VlabBaseUploadBtn'

export default {
    name: 'hdv-content-block-image',
    components: { VlabBaseUploadBtn },
    props: {
        value: { type: String },
        contentId: { type: String },
        blockId: { type: String },
        preview: { type: String },
        src: { type: String }
    },
    data: () => ({
        loading: false
    }),
    computed: {
        url() {
            return `/contents/components/${this.contentId}/blocks/${this.blockId}/image`
        }
    },
    methods: {
        upload() {
            return this.$refs.upload.upload()
        },
        onSuccess(file) {
            this.$emit('update:preview', file.response.preview)
            this.$emit('update:src', file.response.url)
        }
    }
}
</script>
