import Content from '@/views/Content'
import ContentForm from '@/views/ContentForm'
import ContentFaq from '@/views/ContentFaq'
import ContentFaqForm from '@/views/ContentFaqForm'
import ContentPage from '@/views/ContentPage'
import ContentPageForm from '@/views/ContentPageForm'
import ContentStory from '@/views/ContentStory'
import ContentStoryForm from '@/views/ContentStoryForm'
import ContentCategory from '@/views/ContentCategory'
import ContentCategoryForm from '@/views/ContentCategoryForm'
import Department from '@/views/Department'
import DepartmentForm from '@/views/DepartmentForm'

export default [
    {
        path: '/content/pagetree',
        name: 'contentpage',
        component: ContentPage,
        props: true,
        meta: {
            title: 'contentpage.menu'
        },
        children: [
            {
                path: ':pageId',
                name: 'contentpageform',
                component: ContentPageForm,
                props: true,
                meta: {
                    title: 'contentpage.form'
                }
            }
        ]
    },
    {
        path: '/content/page',
        name: 'content',
        component: Content,
        props: true,
        meta: {
            title: 'content.menu'
        },
        children: [
            {
                path: ':contentId',
                name: 'contentform',
                component: ContentForm,
                props: true,
                meta: {
                    title: 'content.form'
                }
            }
        ]
    },
    {
        path: '/content/faq',
        name: 'contentfaq',
        component: ContentFaq,
        props: true,
        meta: {
            title: 'contentfaq.menu'
        },
        children: [
            {
                path: ':contentId',
                name: 'contentfaqform',
                component: ContentFaqForm,
                props: true,
                meta: {
                    title: 'contentfaq.form'
                }
            }
        ]
    },
    {
        path: '/content/categories',
        name: 'contentcategory',
        component: ContentCategory,
        props: true,
        meta: {
            title: 'contentcategory.menu'
        },
        children: [
            {
                path: ':categoryId',
                name: 'contentcategoryform',
                component: ContentCategoryForm,
                props: true,
                meta: {
                    title: 'contentcategory.form'
                }
            }
        ]
    },
    {
        path: '/content/stories',
        name: 'contentstory',
        component: ContentStory,
        props: true,
        meta: {
            title: 'contentstory.menu'
        },
        children: [
            {
                path: ':contentId',
                name: 'contentstoryform',
                component: ContentStoryForm,
                props: true,
                meta: {
                    title: 'contentstory.form'
                }
            }
        ]
    },
    {
        path: '/departments',
        name: 'department',
        component: Department,
        props: true,
        meta: {
            title: 'department.menu'
        },
        children: [
            {
                path: ':departmentId',
                name: 'departmentform',
                component: DepartmentForm,
                props: true,
                meta: {
                    title: 'department.form'
                }
            }
        ]
    }
]
