<template>
    <v-row>
        <v-col cols="12" sm="6" md="8" class="pr-0">
            <hdv-sale-vacation-list
                ref="vacationList"
                :sale-id="saleId"
                @click:import="onImportVacation"
                @click:import-sale="onImportSale" />
        </v-col>
        <v-col cols="12" sm="6" md="4" class="pl-0">
            <v-card>
                <hdv-sale-lot-list
                    ref="lotList"
                    :sale-id="saleId"
                    @click:import="onImportVacation"
                    @remove:done="onImportDone" />
            </v-card>
        </v-col>
        <hdv-sale-lot-list-import
            ref="importer"
            :sale-id="saleId"
            @import:done="onImportDone" />
    </v-row>
</template>

<script>
import HdvSaleLotList from '@/components/sale-lot-list/HdvSaleLotList'
import HdvSaleVacationList from '@/components/sale-vacation-list/HdvSaleVacationList'
import HdvSaleLotListImport from '@/components/sale-lot-list/HdvSaleLotListImport'

export default {
    name: 'sale-lot',
    components: { HdvSaleLotList, HdvSaleVacationList, HdvSaleLotListImport },
    props: {
        saleId: { type: [String, Number] },
        disabled: { type: Boolean }
    },
    methods: {
        onImportVacation(ev) {
            this.$refs.importer.start(ev)
        },

        onImportSale(ev) {
            this.$refs.importer.start(ev)
        },

        onImportDone() {
            this.$refs.vacationList.init()
            this.$refs.lotList.load()
        }
    }
}
</script>
