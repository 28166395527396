import Vue from 'vue'

export default {
    namespaced: true,

    actions: {

        async addItem(context, { nxItemId, saleId, description }) {
            const res = await Vue.prototype.$api.post('/items', {
                nx_id: nxItemId,
                sale_id: saleId,
                description: description.fr,
                description_i18n: description
            })
            return res.data
        },

        remove(context, { id }) {
            return Vue.prototype.$api.delete(`/items/${id}`)
        }
    }
}
