<template>
    <hdv-base-actionable-list-item
        :enabled="action"
        :ripple="!sorting"
        :class="{ sorting: !sorting }"
        @click="$emit('click', content)"
        class="draggable-item">
        <v-list-item-content>
            <v-list-item-title>{{ content.title }} <span v-if="content.alias" class="caption">({{ content.alias }})</span></v-list-item-title>
            <v-list-item-subtitle v-if="params.length">
                <span v-for="p in params" :key="p.param" class="caption">
                    [ {{ p.param }}: <em>{{ p.value }}</em> ]&nbsp;
                </span>
            </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="unactionable">
            <v-btn icon>
                <v-icon v-if="!sorting" color="primary">$vuetify.icons.baseRight</v-icon>
                <v-icon v-else color="primary">$vuetify.icons.baseSort</v-icon>
            </v-btn>
        </v-list-item-action>
    </hdv-base-actionable-list-item>
</template>

<script>
import HdvBaseActionableListItem from '@/components/base/HdvBaseActionableListItem'

export default {
    name: 'hdv-content-page-component-list-item',
    components: { HdvBaseActionableListItem },
    props: {
        content: { type: Object },
        action: { type: Boolean },
        sorting: { type: Boolean }
    },

    computed: {
        params() {
            return Object.entries(this.content.params || {}).map(([param, value]) => ({
                param,
                value
            }))
        }
    }
}
</script>

<style lang="sass">
.sorting
    cursor: default !important
</style>
