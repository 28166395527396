<template>
    <v-alert
        class="vlab-base-empty-list mx-4"
        dense
        outlined
        color="grey"
        :value="value">
        <v-icon size="80" color="grey lighten-2">mdi-format-list-checkbox</v-icon>
        <br />
        {{ text }}
    </v-alert>
</template>

<script>
export default {
    name: 'hdv-base-empty-list',
    props: {
        value: { type: Boolean },
        text: { type: String }
    }
}
</script>

<style lang="sass">
.vlab-base-empty-list.v-alert--outlined
    border: none !important
    text-align: center
    padding: 1em

</style>
