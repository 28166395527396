<template>
    <v-text-field
        v-model="slug"
        outlined
        :label="$t('content.slug') + (required ? '*' : '')"
        :rules="required ? [$required] : []"
        @keypress="checkKey"
        :name="name"
        :error-messages="errorMessages" />
</template>

<script>
import FieldError from '@/shared/mixins/FieldError'

export default {
    name: 'hdv-content-slug',
    props: {
        value: { type: String },
        required: { type: Boolean }
    },
    mixins: [FieldError],

    computed: {
        slug: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },
    methods: {
        checkKey(event) {
            const char = String.fromCharCode(event.keyCode)
            if (/^[A-Za-z0-9_/-]+$/.test(char)) {
                return true
            }
            if (['Enter'].includes(event.code)) {
                return true
            }
            event.preventDefault()
        }
    }
}
</script>
