<template>
    <v-card flat :loading="loading">
        <vlab-base-card-title :title="$t('salelotlist.title')" :dismissible="false" />
        <v-tabs v-model="tab" centered text background-color="grey lighten-5">
            <v-tab :href="`#lotnb`">
                {{ $t('salelotlist.lotnbTab') }}
            </v-tab>
            <v-tab :href="`#latest`">
                {{ $t('salelotlist.latestTab') }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item value="lotnb">
                <v-card-text>
                    <div v-marked="$t('salelotlist.text')" class="mb-5"></div>

                    <hdv-sale-lot-list-search
                        :disabled="loading"
                        v-model="search"
                        @search="onSearch"
                    />
                </v-card-text>
            </v-tab-item>
            <v-tab-item value="latest">
                <v-card-text>
                    <div v-marked="$t('salelotlist.textLatest')" class="mb-5"></div>

                    <hdv-sale-lot-list-search-latest
                        :disabled="loading"
                        v-model="latestDateFrom"
                        @search="onSearch"
                    />
                </v-card-text>
            </v-tab-item>
        </v-tabs-items>

        <hdv-sale-lot-list-actions :web="true" @click:import="importLots" @click:remove="removeLots(lots)">
            <template #activator="{ on, attrs }">
                <div class="mx-4" v-on="on" v-bind="attrs">
                    <v-btn small rounded block color="primary" :disabled="!lots.length">
                        {{ $t('salelotlist.groupActions', { count: total }) }}
                    </v-btn>
                </div>
            </template>
        </hdv-sale-lot-list-actions>

        <v-pagination
            v-if="lots.length"
            v-model="page"
            color="primary"
            class="my-4"
            total-visible="8"
            :disabled="loading"
            :length="paginationLength"
        />

        <v-list three-line class="pt-0" :disabled="loading">
            <template v-for="lot in lots">
                <v-divider :key="`i-${lot.id}`" />
                <hdv-sale-lot-list-item
                    :key="lot.id"
                    :lot="lot"
                    @click:import="ev => $emit('click:import', ev)"
                    @click:remove="ev => removeLots([ev.lot])" />
            </template>
            <hdv-base-empty-list :value="!loading && !lots.length" :text="$t('salelotlist.empty')" />
            <vlab-base-loader v-model="loading" />
        </v-list>

        <hdv-sale-lot-remove-popup
            v-model="removePopup"
            :sale-id="saleId"
            :lot-ids="removeLotIds"
            @remove:done="removeLotsDone" />
    </v-card>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'
import HdvSaleLotListSearch from '@/components/sale-lot-list/HdvSaleLotListSearch'
import HdvSaleLotListSearchLatest from '@/components/sale-lot-list/HdvSaleLotListSearchLatest'
import HdvSaleLotListItem from '@/components/sale-lot-list/HdvSaleLotListItem'
import HdvBaseEmptyList from '@/components/base/HdvBaseEmptyList'
import HdvSaleLotListActions from '@/components/sale-lot-list/HdvSaleLotListActions'
import HdvSaleLotRemovePopup from '@/components/sale-lot/HdvSaleLotRemovePopup'

export default {
    name: 'hdv-sale-lot-list',

    components: {
        VlabBaseCardTitle,
        HdvSaleLotListSearch,
        HdvSaleLotListSearchLatest,
        HdvSaleLotListItem,
        HdvBaseEmptyList,
        HdvSaleLotListActions,
        HdvSaleLotRemovePopup
    },

    props: {
        saleId: { type: String }
    },

    data: () => ({
        loading: false,
        search: '',
        latestDateFrom: null,
        tab: 'lotnb',
        page: 1,
        limit: 10,
        total: 0,
        lots: [],
        removePopup: false,
        removeLotIds: []
    }),

    computed: {
        paginationLength() {
            return Math.ceil(this.total / this.limit)
        }
    },

    watch: {
        page: 'load'
    },

    methods: {
        load() {
            if (this.disabled || (!this.search && this.tab === 'lotnb')) {
                return
            }
            const data = {
                search: this.tab === 'lotnb' ? this.search : undefined,
                updated_at: this.tab !== 'lotnb' ? this.latestDateFrom.toISOString() : undefined
            }

            this.loading = true
            const start = (this.page - 1) * this.limit
            return this.$store.dispatch('lot/listWithNx', { saleId: this.saleId, offset: start, limit: this.limit, ...data })
                .then(({ lots, count }) => {
                    this.lots = lots
                    if (count !== null) {
                        this.total = count
                    }
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        onSearch() {
            if (this.page === 1) {
                return this.load()
            }
            // on est sur une page suivante. Le fait de changer de page appellera load()
            this.page = 1
        },

        removeLots(lots) {
            this.removePopup = !!lots.length
            this.removeLotIds = lots.map(lot => lot.id)
        },

        removeLotsDone(lotIds) {
            lotIds.forEach(lot => {
                const index = this.lots.findIndex(l => lot.id === l.id)
                if (index !== -1) {
                    this.lots.splice(index, 1)
                }
            })
            this.removePopup = false
            this.removeLotIds = []
            this.$emit('remove:done')
        },

        importLots(ev) {
            ev.total = this.total
            ev.lots_range = this.tab === 'lotnb' ? this.search : undefined
            ev.updated_at = this.tab !== 'lotnb' ? this.latestDateFrom.toISOString() : undefined
            this.$emit('click:import', ev)
        }
    }
}
</script>
