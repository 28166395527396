<template>
    <div>
        <h4>{{ $t('seo.header') }}</h4>
        <p>{{ $t('seo.help') }}</p>
        <hdv-base-i18n-area v-if="seo.title_i18n">
            <template #default="{ ln }">
                <hdv-seo-title v-model="seo.title_i18n[ln]" :name="`title_i18n-${ln}`" />
                <hdv-seo-description v-model="seo.description_i18n[ln]" :name="`description_i18n-${ln}`" />
                <hdv-seo-keywords v-model="seo.keywords_i18n[ln]" :name="`keywords_i18n-${ln}`" />
            </template>
        </hdv-base-i18n-area>
    </div>
</template>

<script>
import HdvBaseI18nArea from '@/components/base/HdvBaseI18nArea'
import HdvSeoTitle from '@/components/seo/HdvSeoTitle'
import HdvSeoDescription from '@/components/seo/HdvSeoDescription'
import HdvSeoKeywords from '@/components/seo/HdvSeoKeywords'

export default {
    name: 'hdv-seo-form',
    components: { HdvSeoTitle, HdvSeoDescription, HdvSeoKeywords, HdvBaseI18nArea },

    props: {
        type: { type: String },
        id: { type: String }
    },

    data: () => ({
        loading: false,
        seo: {}
    }),

    watch: {
        loading(v) {
            this.$emit('loading', v)
        },
        id: 'load'
    },

    mounted() {
        return this.load()
    },

    methods: {
        load() {
            this.loading = true
            return this.$store.dispatch('seo/get', { type: this.type, id: this.id })
                .then(seo => {
                    this.seo = seo || {
                        title_i18n: {},
                        description_i18n: {},
                        keywords_i18n: {}
                    }
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        submit(form) {
            this.loading = true
            return this.$store.dispatch('seo/set', { type: this.type, id: this.id, seo: this.seo })
                .then(res => this.$success(res, { message: 'seo.saved' }))
                .catch(err => this.$err(err, null, form))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
