<template>
    <v-text-field
        v-model="model"
        :label="label"
        dense
        outlined
    />
</template>

<script>
export default {
    name: 'hdv-content-page-component-param',
    props: {
        value: { type: [String, Number, Boolean] },
        label: { type: String }
    },

    computed: {
        model: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    }
}
</script>
