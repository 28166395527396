<template>
    <v-autocomplete
        v-model="selected"
        :label="$t('vacationhighlight.label')"
        :items="lots"
        :search-input.sync="search"
        :loading="loading"
        item-text="lot_nb"
        item-value="id"
        solo
        hide-details>
        <template #item="{ item }">
            <div class="py-3 d-flex">
                <v-avatar
                    rounded
                    size="52"
                    color="grey lighten-3">
                    <v-img v-if="item.item.images.length" :src="item.item.images[0].path_preview | imagecrop({ w: 52, h: 52, f: 'cover' })" alt="image" />
                </v-avatar>
                <div class="pl-3">
                    <v-list-item-title>{{ item.item.title }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('salehighlight.estimates', { down: item.item.estimate_down, up: item.item.estimate_up }) }}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{ $t('salehighlight.lotnb', { lot_nb: item.lot_nb }) }}</v-list-item-subtitle>
                </div>
            </div>
        </template>
        <template #selection="{ item }">
            <div class="d-flex align-center">
                <v-avatar
                    rounded
                    size="26"
                    color="grey lighten-3">
                    <v-img v-if="item.item.images.length" :src="item.item.images[0].path_preview | imagecrop({ w: 52, h: 52, f: 'cover' })" alt="image" />
                </v-avatar>
                <div class="pl-4">{{ $t('vacationhighlight.lot', { lot_nb: item.lot_nb }) }}</div>
            </div>
        </template>
    </v-autocomplete>
</template>

<script>
import lodash from 'lodash'

export default {
    name: 'hdv-sale-vacation-highlight-selector',
    props: {
        saleId: { type: String },
        vacationId: { type: String },
        lotId: { type: String }
    },

    data: () => ({
        loading: false,
        search: null,
        lots: [],
        selected: null
    }),

    watch: {
        lotId: 'init',

        selected: 'save',

        search: lodash.debounce(function(v) {
            this.load(v)
        }, 500)
    },

    mounted() {
        return this.init()
    },

    methods: {
        load(lotNb) {
            if (!lotNb) {
                return
            }
            this.loading = true
            return this.$store.dispatch('high/searchLots', { saleId: this.saleId, vacationId: this.vacationId, lotNb: lotNb })
                .then(lots => {
                    this.lots = lots.map(lot => {
                        lot.lot_nb = lot.lot_nb + lot.lot_nb_ext
                        return lot
                    })
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        init() {
            if (!this.lotId) {
                return
            }
            this.loading = true
            return this.$store.dispatch('lot/get', { saleId: this.saleId, lotId: this.lotId })
                .then(lot => {
                    this.selected = lot.id
                    this.lots = [lot]
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        save() {
            if (this.selected === this.lotId) {
                return
            }
            this.loading = true
            return this.$store.dispatch('sale/setVacationHighlight', { saleId: this.saleId, vacationId: this.vacationId, lotId: this.selected })
                .then(res => this.$success(res, { message: 'vacationhighlight.saved' }))
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
