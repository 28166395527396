<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        min-width="auto">
        <template #activator="{ on, attrs }">
            <v-text-field
                v-model="dateFormatted"
                :label="$t('sale.catalogDate')"
                outlined
                readonly
                v-bind="attrs"
                v-on="on" />
        </template>
        <vlab-base-card-title :title="$t('sale.catalogDate')" @close="menu = false" />
        <v-date-picker v-model="date" no-title scrollable />
    </v-menu>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'

export default {
    name: 'hdv-sale-catalog-date',
    components: { VlabBaseCardTitle },
    props: {
        value: { type: String }
    },
    data: () => ({
        menu: false
    }),
    computed: {
        date: {
            get() {
                return this.value
            },
            set(v) {
                this.menu = false
                this.$emit('input', v)
            }
        },

        dateFormatted() {
            if (!this.date) {
                return null
            }
            return this.$i18n.t('sale.catalogDateFormat', { date: this.date })
        }
    }
}
</script>
