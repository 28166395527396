import Vue from 'vue'
import VueRouter from 'vue-router'
import NotFound from '@/views/NotFound.vue'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'

import store from '@/store'

import events from './events'
import sales from './sales'
import contents from './contents'
import teams from './teams'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
            throw err
        }
    })
}
Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: []
        .concat(events)
        .concat(sales)
        .concat(teams)
        .concat(contents)
        .concat([{
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                title: '',
                dense: true
            }
        }, {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                title: '',
                dense: true,
                public: true
            }
        }, {
            path: '*',
            component: NotFound
        }])
})

router.beforeEach((to, from, next) => {
    if (!to.meta.public && !store.getters['auth/connected']) {
        const query = {
            ...to.query,
            p: to.path
        }
        return next({ name: 'login', query: query })
    }
    if (to.meta.public && store.getters['auth/connected']) {
        return next({ name: 'home' })
    }
    return next()
})

export default router
