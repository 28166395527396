<template>
    <div>
        <v-divider class="my-5" />

        <div class="d-flex">
            <hdv-event-date v-model="dateLocal" :min="minDate" :max="maxDate">
                <template #append-outer>
                    <v-icon color="red" @click="$emit('remove', id)">$vuetify.icons.baseClose</v-icon>
                </template>
            </hdv-event-date>
        </div>

        <div class="d-flex">
            <v-card v-for="(s, i) in slotsLocal" :key="i">
                <vlab-base-card-title :title="$t('event.slot', { index: i + 1 })" @close="removeSlot(i)" />
                <v-card-text>
                    <hdv-event-timeslots
                        v-model="s.start"
                        :end.sync="s.end"
                        @update="updateDateRange(i)" />

                    <v-row justify="space-around" align="start" class="mt-0">
                        <v-col class="pb-0">
                            <hdv-event-timeslot
                                v-model="s.duration"
                                :rules="[$required]"
                                :label="$t('event.duration')" />
                        </v-col>
                        <v-col class="pb-0">
                            <v-text-field
                                v-model.number="s.max"
                                type="number"
                                step="1"
                                dense
                                outlined
                                :rules="[$required, $ranged(0, 9999)]"
                                :label="$t('event.max') + ' *'" />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>

        <v-btn outlined rounded @click="addSlot">{{ $t('event.slotadd') }}</v-btn>
    </div>
</template>

<script>
import HdvEventTimeslots from '@/components/event/HdvEventTimeslots'
import HdvEventTimeslot from '@/components/event/HdvEventTimeslot'
import HdvEventDate from '@/components/event/HdvEventDate'
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'

export default {
    name: 'hdv-event-date-slot',
    props: {
        id: { type: [String, Number], default: null },
        date: { type: String },
        dateStart: { type: String },
        dateEnd: { type: String },
        timezone: { type: String },
        slots: { type: Array, default: () => [] },
        minDate: { type: [String, Date] },
        maxDate: { type: [String, Date] }
    },
    components: {
        VlabBaseCardTitle,
        HdvEventTimeslots,
        HdvEventTimeslot,
        HdvEventDate
    },
    computed: {
        dateLocal: {
            get() {
                return this.date
            },
            set(v) {
                this.updateDateRange(-1)
                for (let i = 0; i < this.slotsLocal.length; i += 1) {
                    this.updateDateRangeForSlot(i)
                }
                this.$emit('update:date', v)
            }
        },
        slotsLocal: {
            get() {
                return this.slots
            },
            set(v) {
                this.$emit('update:slots', v)
            }
        }
    },
    methods: {
        addSlot() {
            this.slotsLocal.push({
                date_start: null,
                start: null,
                date_end: null,
                end: null,
                timezone: this.timezone,
                duration: null,
                max: null
            })
        },

        removeSlot(index) {
            this.slotsLocal.splice(index, 1)
            this.updateDateRange()
        },

        async updateDateRange(slotIndex) {
            await this.$nextTick()
            if (!this.slotsLocal.length) {
                this.$emit('update:date-start', null)
                this.$emit('update:date-end', null)
                return
            }
            const firstHour = this.slotsLocal[0].start
            const lastHour = this.slotsLocal[this.slotsLocal.length - 1].end
            if (!firstHour || !lastHour) {
                this.$emit('update:date-start', null)
                this.$emit('update:date-end', null)
                return
            }

            const offset = this.getTimezoneOffset()
            const start = new Date(new Date(Date.parse(`${this.dateLocal}T${firstHour}:00Z`).valueOf() + offset)).toISOString()
            const end = new Date(new Date(Date.parse(`${this.dateLocal}T${lastHour}:00Z`).valueOf() + offset)).toISOString()

            this.$emit('update:date-start', start)
            this.$emit('update:date-end', end)

            // set des données dans le slot
            return slotIndex !== -1 && this.updateDateRangeForSlot(slotIndex)
        },

        updateDateRangeForSlot(slotIndex) {
            const slot = this.slotsLocal[slotIndex]
            if (!slot.start || !slot.end) {
                this.date_start = null
                this.date_end = null
                return
            }

            const offset = this.getTimezoneOffset()
            slot.date_start = new Date(new Date(Date.parse(`${this.dateLocal}T${slot.start}:00Z`).valueOf() + offset)).toISOString()
            slot.date_end = new Date(new Date(Date.parse(`${this.dateLocal}T${slot.end}:00Z`).valueOf() + offset)).toISOString()
            this.$emit('update:slots', this.slotsLocal)
        },

        getTimezoneOffset() {
            const date = new Date()
            const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }))
            const tzDate = new Date(date.toLocaleString('en-US', { timeZone: this.timezone }))
            return ((tzDate.getTime() - utcDate.getTime()) / 6e4) * 60 * 1000 * -1
        }
    }
}
</script>
