<template>
    <div :class="{ 'd-flex': inline }">
        <vlab-base-upload-btn
            class="grow mr-3"
            :block="!inline"
            :multiple="false"
            :loading="loading"
            :url="url"
            :label="$t(`salecatalog.upload${type}`)"
            :axios="$api"
            :accept="accept"
            rounded
            @upload:start="$emit('loaded', true)"
            @upload:success="onSuccess"
            @upload:finish="$emit('loaded', false)" />

        <slot name="append"></slot>
    </div>
</template>

<script>
import VlabBaseUploadBtn from '@/shared/components/base/VlabBaseUploadBtn'

export default {
    name: 'hdv-sale-vacation-upload',
    components: { VlabBaseUploadBtn },
    props: {
        value: { type: String },
        saleId: { type: String },
        vacationId: { type: String },
        inline: { type: Boolean },
        image: { type: Boolean },
        pdf: { type: Boolean },
        type: { type: String }
    },
    data: () => ({
        loading: false
    }),
    computed: {
        url() {
            return `/sales/${this.saleId}/vacations/${this.vacationId}/${this.type}`
        },
        accept() {
            if (this.pdf) {
                return ['application/pdf']
            }
            if (this.image) {
                return ['image/jpg', 'image/jpeg', 'image/png', 'image/gif']
            }
            return []
        }
    },
    methods: {
        onSuccess(file) {
            this.$emit('input', file.response.url)
            this.$emit('success', file.response)
        }
    }
}
</script>
