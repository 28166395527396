<template>
    <v-text-field
        v-model="title"
        outlined
        :label="$t('sale.title')"
        :name="name"
        :error-messages="errorMessages" />
</template>

<script>
import FieldError from '@/shared/mixins/FieldError'

export default {
    name: 'hdv-sale-title',

    props: {
        value: { type: String }
    },

    mixins: [FieldError],

    computed: {
        title: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    }
}
</script>
