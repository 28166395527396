<template>
    <v-card flat :loading="loading">
        <v-card-text>
            <p>{{ $t('salehighlight.textItem') }}</p>

            <hdv-sale-highlight-item-search
                :nx-sale-id="nxSaleId"
                :disabled="disabled"
                v-model="highlight" />

        </v-card-text>
        <v-list three-line class="pt-0" :disabled="loading">

            <hdv-base-actionable-list
                v-model="action"
                :total="highlights.length"
                :selected.sync="selected"
                @close="selected = []"
                @remove="removeItems"
                :force-visible="true"
                :disabled="loading">
                <template v-if="selected" #remove>
                    <v-card-text>{{ $t('salehighlight.removeText', { count: selected.length, name: selected.length && selected[0].title }) }}</v-card-text>
                </template>

                <template #search>
                    <v-btn rounded outlined small @click="toggleSort" :disabled="loading">
                        {{ $t(sorting ? 'base.sorting' : 'base.sort') }}
                    </v-btn>
                </template>
            </hdv-base-actionable-list>

            <v-list-item-group v-model="selected" :multiple="action">
                <draggable
                    :list="highlights"
                    :disabled="!sorting || loading"
                    class="list-group"
                    v-bind="dragOptions"
                    @start="$emit('drag', true)"
                    @end="$emit('drag', false)">
                    <div v-for="highlight in highlights" :key="highlight.item.id">
                        <v-divider />
                        <hdv-sale-highlight-list-item
                            :action="action"
                            :sorting="sorting"
                            :highlight="highlight"
                            :item="highlight.item"
                            @star:change="toggleStar" />
                    </div>
                </draggable>
            </v-list-item-group>
        </v-list>

        <hdv-sale-highlight-popup
            v-if="editItem"
            v-model="show"
            :item="editItem"
            :description.sync="editItem.description_i18n"
            :sale-id="saleId" />
    </v-card>
</template>

<script>
import HdvSaleHighlightItemSearch from '@/components/sale-highlight/HdvSaleHighlightItemSearch'
import HdvSaleHighlightPopup from '@/components/sale-highlight/HdvSaleHighlightPopup'
import HdvSaleHighlightListItem from '@/components/sale-highlight/HdvSaleHighlightListItem'
import HdvBaseActionableList from '@/components/base/HdvBaseActionableList'

import draggable from 'vuedraggable'

export default {
    name: 'hdv-sale-highlight-tab',
    components: {
        HdvBaseActionableList,
        HdvSaleHighlightPopup,
        HdvSaleHighlightItemSearch,
        HdvSaleHighlightListItem,
        draggable
    },
    props: {
        saleId: { type: [String, Number] },
        disabled: { type: Boolean }
    },
    data: () => ({
        loading: false,
        sorting: false,
        nxSaleId: null,
        show: false,
        editItem: null,
        highlight: null,
        dragOptions: {
            animation: 0,
            group: 'description',
            disabled: false,
            ghostClass: 'ghost'
        },
        // actionable list
        action: false,
        selected: []
    }),
    computed: {
        highlights() {
            return this.$store.getters['high/items']
        }
    },
    watch: {
        saleId: 'load',
        highlight(v) {
            v && this.showAddPopup(v)
        }
    },
    mounted() {
        this.load()
    },
    methods: {
        load() {
            if (this.disabled) {
                return
            }
            this.loading = true
            return Promise
                .all([
                    this.$store.dispatch('sale/get', { id: this.saleId, include_vacations: true }),
                    this.$store.dispatch('high/listItems', { saleId: this.saleId })
                ])
                .then(res => {
                    this.nxSaleId = res[0].nx_id
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        removeItems() {
            if (this.sorting) {
                return
            }
            this.loading = true
            return this.$store.dispatch('high/removeItemByIndexes', { saleId: this.saleId, indexes: this.selected })
                .then(res => {
                    this.selected = []
                    this.$success(res, { message: 'salehighlight.removed', count: this.selected.length })
                    this.action = false
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        showAddPopup(item) {
            this.show = true
            this.editItem = item
        },

        toggleSort() {
            if (!this.sorting) {
                this.sorting = true
                return
            }
            this.loading = true
            return this.$store
                .dispatch('high/sortItems', {
                    saleId: this.saleId,
                    itemIds: this.highlights.map(high => high.id)
                })
                .then(res => {
                    this.sorting = false
                    return this.$success(res, { message: this.$i18n.t('salehighlight.sortSaved') })
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        toggleStar({ highlight, isStar }) {
            this.loading = true
            return this.$store
                .dispatch('high/setStarItem', {
                    saleId: this.saleId,
                    itemId: highlight.item.id,
                    isStar
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>

<style lang="sass">
.ghost
    opacity: 0.5
    background: #eee

.list-group
    min-height: 20px

.list-group-item
    cursor: move

.list-group-item i
    cursor: pointer

</style>
