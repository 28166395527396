<template>
    <v-card>
        <v-form v-model="valid" @submit.prevent.stop="submit" :disabled="loading">
            <vlab-base-card-title :title="$t('contentcategory.form')" :dismissible="!loading" @close="back" />
            <v-card-text v-if="content">
                <hdv-content-category-alias v-model="content.alias" />

                <hdv-content-category-name
                    v-model="content.name"
                    required />

                <hdv-content-category-blocks
                    v-model="content.blocks"  />
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    rounded
                    color="primary"
                    type="submit"
                    :disabled="!valid"
                    :loading="loading">
                    {{ $t('base.save') }}
                </v-btn>
                <v-spacer />
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'
import HdvContentCategoryName from '@/components/content-category/HdvContentCategoryName'
import HdvContentCategoryAlias from '@/components/content-category/HdvContentCategoryAlias'
import HdvContentCategoryBlocks from '@/components/content-category/HdvContentCategoryBlocks'

export default {
    name: 'content-category-form',
    components: {
        VlabBaseCardTitle,
        HdvContentCategoryName,
        HdvContentCategoryAlias,
        HdvContentCategoryBlocks
    },
    props: {
        categoryId: { type: String }
    },
    data: () => ({
        loading: false,
        valid: false,
        content: null
    }),
    computed: {
        editMode() {
            return this.categoryId !== 'add'
        }
    },
    watch: {
        categoryId: 'load'
    },
    mounted() {
        this.load()
    },
    methods: {
        load() {
            this.loading = true
            return Promise
                .all([
                    this.editMode ? this.$store.dispatch('cat/get', { categoryId: this.categoryId }) : null
                ])
                .then(data => {
                    this.content = data[0] || {
                        id: null
                    }
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        submit() {
            this.loading = true
            const action = this.editMode
                ? 'cat/update'
                : 'cat/add'

            return this.$store.dispatch(action, { categoryId: this.content.id, category: this.content })
                .then(content => {
                    this.$success(content, { message: 'contentcategory.saved' })
                    if (!this.editMode) {
                        return this.$router.push({ name: 'contentcategoryform', params: { categoryId: content.id } })
                    }
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        back() {
            this.$router.push({ name: 'contentcategory' })
        }
    }
}
</script>
