import Vue from 'vue'
import App from './App.vue'
import Err from './Err.vue'
import './shared/plugins/storage'
import './shared/plugins/axios'
import './shared/plugins/marked'
import './shared/plugins/rules'
import './shared/plugins/messages'
import './shared/plugins/moment'
import i18nBoot from './shared/plugins/i18n'
import store from './store'
import './shared/plugins/can'
import './plugins/filters'
import router from './router'
import vuetify from './plugins/vuetify'
import config from './config'
import './registerServiceWorker'

import VlabBaseLoader from '@/shared/components/base/VlabBaseLoader'
import HdvSaleMainMenu from '@/components/sale/HdvSaleMainMenu'

Vue.config.productionTip = false

config.mount()
    .then(config => i18nBoot.init(config, store))
    .then(i18n => {
        Vue.component('vlab-base-loader', VlabBaseLoader)
        Vue.component('hdv-sale-main-menu', HdvSaleMainMenu)
        new Vue({
            i18n,
            router,
            store,
            vuetify,
            render: h => h(App)
        }).$mount('#app')
    })
    .catch(err => {
        Vue.prototype.currentError = err
        new Vue({
            vuetify,
            render: h => h(Err)
        }).$mount('#app')
    })
