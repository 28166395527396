<template>
    <hdv-base-actionable-list-item
        :enabled="action"
        :to="{ name: 'eventform', params: { id: event.id } }">
        <v-list-item-avatar rounded>
            <v-img v-if="event.image_url_preview" :src="event.image_url_preview | imagecrop({ w: 80, f: 'cover' })" />
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title>{{ event.name }}</v-list-item-title>
            <v-list-item-subtitle><span class="caption">[{{ $t(`event.types.${event.type}`) }}]</span> {{ event.city }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ $t('event.dateformat', { date: date }) }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="unactionable">
            <v-btn icon>
                <v-icon color="primary">$vuetify.icons.baseRight</v-icon>
            </v-btn>
        </v-list-item-action>
    </hdv-base-actionable-list-item>
</template>

<script>
import HdvBaseActionableListItem from '@/components/base/HdvBaseActionableListItem'

export default {
    name: 'acs-event-list-item',
    components: { HdvBaseActionableListItem },
    props: {
        event: { type: Object },
        action: { type: Boolean }
    },
    computed: {
        date() {
            return this.event.date_start
        }
    }
}
</script>
