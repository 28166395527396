<template>
    <v-card>
        <v-form v-model="valid" @submit.prevent.stop="submit" :disabled="loading">
            <vlab-base-card-title :title="$t('contentfaq.form')" :dismissible="!loading" @close="back" />
            <v-card-text v-if="content">
                <hdv-content-faq-disabled v-model="content.disabled" />
                <hdv-base-i18n-area>
                    <template #default="{ ln, isDefault }">
                        <hdv-content-faq-alias v-model="content.alias_i18n[ln]" />

                        <hdv-content-faq-title
                            v-model="content.title_i18n[ln]"
                            @input="v => onTitleChange(v, isDefault)"
                            :required="isDefault" />

                        <hdv-content-faq-description
                            v-model="content.content_i18n[ln]"
                            :id="content.id" />
                    </template>
                </hdv-base-i18n-area>

            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    rounded
                    color="primary"
                    type="submit"
                    :disabled="!valid"
                    :loading="loading">
                    {{ $t('base.save') }}
                </v-btn>
                <v-spacer />
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'
import HdvBaseI18nArea from '@/components/base/HdvBaseI18nArea'
import HdvContentFaqDisabled from '@/components/content-faq/HdvContentFaqDisabled'
import HdvContentFaqTitle from '@/components/content-faq/HdvContentFaqTitle'
import HdvContentFaqAlias from '@/components/content-faq/HdvContentFaqAlias'
import HdvContentFaqDescription from '@/components/content-faq/HdvContentFaqDescription'

export default {
    name: 'content-faq-form',
    components: {
        VlabBaseCardTitle,
        HdvBaseI18nArea,
        HdvContentFaqDisabled,
        HdvContentFaqTitle,
        HdvContentFaqAlias,
        HdvContentFaqDescription
    },
    props: {
        contentId: { type: String }
    },
    data: () => ({
        loading: false,
        valid: false,
        content: null
    }),
    computed: {
        editMode() {
            return this.contentId !== 'add'
        }
    },
    watch: {
        contentId: 'load'
    },
    mounted() {
        this.load()
    },
    methods: {
        load() {
            this.loading = true
            return Promise
                .all([
                    this.editMode ? this.$store.dispatch('faq/get', { faqId: this.contentId }) : null
                ])
                .then(data => {
                    this.content = data[0] || {
                        id: null,
                        alias_i18n: {},
                        title_i18n: {},
                        content_i18n: {}
                    }
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        submit() {
            this.loading = true
            const action = this.editMode
                ? 'faq/update'
                : 'faq/add'

            return this.$store.dispatch(action, { faqId: this.content.id, faq: this.content })
                .then(content => {
                    this.$success(content, { message: 'contentfaq.saved' })
                    if (!this.editMode) {
                        return this.$router.push({ name: 'contentfaqform', params: { contentId: content.id } })
                    }
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        onTitleChange(value, isDefault) {
            if (isDefault) {
                this.content.title = value
            }
        },

        back() {
            this.$router.push({ name: 'contentfaq' })
        }
    }
}
</script>
