<template>
    <hdv-base-actionable-list-item
        :enabled="action"
        :to="{ name: 'contentcategoryform', params: { categoryId: category.id } }">
        <v-list-item-content>
            <v-list-item-title>{{ category.name }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="unactionable">
            <v-btn icon>
                <v-icon color="primary">$vuetify.icons.baseRight</v-icon>
            </v-btn>
        </v-list-item-action>
    </hdv-base-actionable-list-item>
</template>

<script>
import HdvBaseActionableListItem from '@/components/base/HdvBaseActionableListItem'

export default {
    name: 'acs-content-category-list-item',
    components: { HdvBaseActionableListItem },
    props: {
        category: { type: Object },
        action: { type: Boolean }
    }
}
</script>

<style lang="sass">
.sorting
    cursor: default !important
</style>
