import TeamList from '@/views/TeamList'
import TeamForm from '@/views/TeamForm'

export default [
    {
        path: '/teams',
        name: 'teamlist',
        component: TeamList,
        props: true,
        meta: {
            title: 'team.menu'
        },
        children: [
            {
                path: ':teamId',
                name: 'teamform',
                component: TeamForm,
                props: route => ({
                    teamId: route.params.teamId,
                    nx: false
                }),
                meta: {
                    title: 'team.form'
                }
            },
            {
                path: ':teamId/import',
                name: 'teamadd',
                component: TeamForm,
                props: route => ({
                    teamId: route.params.teamId,
                    nx: true
                }),
                meta: {
                    title: 'team.form'
                }
            }
        ]
    }
]
