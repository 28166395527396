<template>
    <v-card>
        <v-form ref="form" v-model="valid" @submit.prevent.stop="submit" :disabled="loading">
            <vlab-base-card-title :title="$t('contentstory.form')" :dismissible="!loading" @close="back" />
            <v-card-text v-if="content">
                <hdv-content-story-disabled v-model="content.disabled" />
                <hdv-base-i18n-area :loading="loading">
                    <template #default="{ ln, isDefault }">
                        <hdv-content-story-title
                            v-model="content.title_i18n[ln]"
                            @input="v => onTitleChange(v, isDefault)"
                            :required="isDefault"
                            :name="`title_i18n-${ln}`" />

                        <hdv-base-slug-field
                            v-model="content.slug_i18n[ln]"
                            :required="isDefault"
                            :auto-fill="content.title_i18n[ln]"
                            :name="`slug_i18n-${ln}`" />

                        <hdv-content-story-description
                            v-model="content.content_i18n[ln]"
                            :id="content.id" />
                    </template>
                </hdv-base-i18n-area>

                <hdv-content-story-type v-model="content.type" />
                <hdv-content-story-link v-model="content.link" />
                <hdv-content-story-date v-model="content.date" />

                <hdv-content-story-image
                    ref="upload"
                    :story-id="content.id"
                    :preview.sync="content.image_url_preview"
                    :src.sync="content.image_url"
                    @loaded="v => loading = v" />

            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    rounded
                    color="primary"
                    type="submit"
                    :disabled="!valid"
                    :loading="loading">
                    {{ $t('base.save') }}
                </v-btn>
                <v-spacer />
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'
import HdvBaseI18nArea from '@/components/base/HdvBaseI18nArea'
import HdvContentStoryDisabled from '@/components/content-story/HdvContentStoryDisabled'
import HdvContentStoryTitle from '@/components/content-story/HdvContentStoryTitle'
import HdvContentStoryDescription from '@/components/content-story/HdvContentStoryDescription'
import HdvContentStoryImage from '@/components/content-story/HdvContentStoryImage'
import HdvContentStoryLink from '@/components/content-story/HdvContentStoryLink'
import HdvContentStoryDate from '@/components/content-story/HdvContentStoryDate'
import HdvContentStoryType from '@/components/content-story/HdvContentStoryType'
import HdvBaseSlugField from '@/components/base/HdvBaseSlugField'

export default {
    name: 'content-story-form',
    components: {
        VlabBaseCardTitle,
        HdvBaseI18nArea,
        HdvContentStoryDisabled,
        HdvContentStoryTitle,
        HdvBaseSlugField,
        HdvContentStoryImage,
        HdvContentStoryLink,
        HdvContentStoryDate,
        HdvContentStoryType,
        HdvContentStoryDescription
    },
    props: {
        contentId: { type: String }
    },
    data: () => ({
        loading: false,
        valid: false,
        content: null
    }),
    computed: {
        editMode() {
            return this.contentId !== 'add'
        }
    },
    watch: {
        contentId: 'load'
    },
    mounted() {
        this.load()
    },
    methods: {
        load() {
            this.loading = true
            return Promise
                .all([
                    this.editMode ? this.$store.dispatch('story/get', { storyId: this.contentId }) : null
                ])
                .then(data => {
                    this.content = data[0] || {
                        id: null,
                        slug_i18n: {},
                        title_i18n: {},
                        content_i18n: {}
                    }
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        submit() {
            this.loading = true
            const action = this.editMode
                ? 'story/update'
                : 'story/add'

            return this.$store.dispatch(action, { storyId: this.content.id, story: this.content })
                .then(content => {
                    this.$success(content, { message: 'contentstory.saved' })
                    if (!this.editMode) {
                        return this.$router.push({ name: 'contentstoryform', params: { contentId: content.id } })
                    }
                })
                .catch(err => this.$err(err, null, this.$refs.form))
                .finally(() => (this.loading = false))
        },

        onTitleChange(value, isDefault) {
            if (isDefault) {
                this.content.title = value
            }
        },

        back() {
            this.$router.push({ name: 'contentstory' })
        }
    }
}
</script>
