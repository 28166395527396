<template>
    <v-card flat>
        <vlab-base-card-title :title="$t('contentfaq.list')" :dismissible="false" hide-spacer>
            <template #buttons>
                <v-spacer />
                <v-btn rounded outlined dark small :to="{ name: 'contentfaqform', params: { contentId: 'add' } }">
                    {{ $t('base.add') }}
                </v-btn>
            </template>
        </vlab-base-card-title>
        <div class="my-5 px-5" v-marked="$t('contentfaq.help')"></div>
        <v-list>
            <hdv-base-actionable-list
                v-model="action"
                :total="contents.length"
                :selected.sync="selected"
                @close="selected = []"
                @remove="removeItems"
                :force-visible="true"
                :disabled="loading">
                <template v-if="selected" #remove>
                    <v-card-text>{{ $t('contentfaq.removeText', { count: selected.length, name: selected.length && selected[0].title }) }}</v-card-text>
                </template>

                <template #search>
                    <v-btn rounded outlined small @click="toggleSort" :disabled="loading">
                        {{ $t(sorting ? 'base.sorting' : 'base.sort') }}
                    </v-btn>
                </template>
            </hdv-base-actionable-list>

            <v-list-item-group v-model="selected" :multiple="action">

                <draggable
                    :list="faqs"
                    :disabled="!sorting || loading"
                    class="list-group"
                    v-bind="dragOptions"
                    @start="$emit('drag', true)"
                    @end="$emit('drag', false)">
                    <template v-for="(content, i) in contents">
                        <hdv-content-faq-list-item
                            :key="`${i}-${content.id}`"
                            :action="action"
                            :content="content"
                            :sorting="sorting"
                            @click="!sorting && $emit('select', content)" />
                        <v-divider :key="i" />
                    </template>
                </draggable>

            </v-list-item-group>
            <hdv-base-empty-list :value="!loading && !contents.length" :text="$t('contentfaq.empty')" />
            <vlab-base-loader v-model="loading" />
        </v-list>
    </v-card>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'
import HdvContentFaqListItem from '@/components/content-faq-list/HdvContentFaqListItem'
import HdvBaseEmptyList from '@/components/base/HdvBaseEmptyList'
import HdvBaseActionableList from '@/components/base/HdvBaseActionableList'
import draggable from 'vuedraggable'

import lodash from 'lodash'

export default {
    name: 'hdv-content-faq-list',
    components: { VlabBaseCardTitle, HdvContentFaqListItem, HdvBaseEmptyList, HdvBaseActionableList, draggable },
    props: {
        disabled: { type: Boolean }
    },
    data: () => ({
        loading: false,
        search: null,
        filter: null,
        sorting: false,
        dragOptions: {
            animation: 0,
            group: 'description',
            disabled: false,
            ghostClass: 'ghost'
        },
        // actionable list
        action: false,
        selected: []
    }),
    computed: {
        faqs() {
            return this.$store.getters['faq/faq']
        },

        contents() {
            const tokens = lodash.deburr(lodash.trim(this.filter)).toLowerCase().split(' ').filter(t => t.length >= 3)
            return this.$store.getters['faq/faq'].filter(content => {
                if (!tokens.length) {
                    return true
                }
                const query = lodash.deburr(`${content.title}`).toLowerCase()
                for (let i = 0; i < tokens.length; i += 1) {
                    if (query.indexOf(tokens[i]) === -1) {
                        return false
                    }
                }
                return true
            })
        },
        language() {
            return this.$store.getters['ln/current']
        }
    },
    watch: {
        search: 'doSearch'
    },
    mounted() {
        this.load()
    },
    methods: {
        load() {
            if (this.disabled) {
                return
            }
            this.loading = true
            return this.$store.dispatch('faq/list', {})
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        removeItems() {
            this.loading = true
            return this.$store.dispatch('faq/removeByIndexes', { indexes: this.selected })
                .then(res => {
                    this.selected = []
                    this.$success(res, { message: 'contentfaq.removed', count: this.selected.length })
                    this.action = false
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        doSearch: lodash.debounce(function() {
            this.filter = this.search
        }, 500),

        toggleSort() {
            if (!this.sorting) {
                this.sorting = true
                return
            }
            // on enregistre l'état du sort
            this.sorting = false
            this.loading = true
            return this.$store.dispatch('faq/sorts', { faqIds: this.faqs.map(c => c.id) })
                .then(res => this.$success(res, { message: 'contentfaq.sortsaved' }))
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
