<template>
    <v-text-field
        v-model="alias"
        outlined
        :dense="dense"
        :disabled="disabled"
        :label="(label || $t('content.alias')) + (required ? '*' : '')"
        :rules="rules"
        @keypress="checkKey" />
</template>

<script>

export default {
    name: 'hdv-base-alias-field',
    props: {
        value: { type: String },
        label: { type: String },
        disabled: { type: Boolean },
        required: { type: Boolean },
        dense: { type: Boolean }
    },
    computed: {
        alias: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },

        rules() {
            const rules = [this.$length(100)]
            if (this.required) {
                rules.push(this.$required)
            }
            return rules
        }
    },
    methods: {
        checkKey(event) {
            const char = String.fromCharCode(event.keyCode)
            if (/^[A-Za-z0-9_]+$/.test(char)) {
                return true
            }
            if (['Enter'].includes(event.code)) {
                return true
            }
            event.preventDefault()
        }
    }
}
</script>
