<template>
    <v-row>
        <v-col class="pr-0" cols="12" lg="4" sm="6">
            <hdv-content-faq-list :disabled="disabled" />
        </v-col>
        <v-col class="px-0" cols="12" lg="8" sm="6">
            <router-view></router-view>
        </v-col>
    </v-row>
</template>

<script>
import HdvContentFaqList from '@/components/content-faq-list/HdvContentFaqList'

export default {
    name: 'content-faq',
    components: {
        HdvContentFaqList
    },
    props: {
        disabled: { type: Boolean }
    }
}
</script>
