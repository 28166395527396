<template>
    <v-container fluid>
        <v-row>
            <v-col class="pa-0" cols="12" lg="7" sm="6">
                <vlab-base-card-title :title="$t('eventlist.menu')" :dismissible="false" hide-spacer>
                    <template #buttons>
                        <v-spacer />
                        <v-btn rounded outlined dark small
                            :to="{ name: 'eventform', params: { id: 'add' } }">
                            {{ $t('eventlist.add') }}
                        </v-btn>
                    </template>
                </vlab-base-card-title>
                <div class="my-5 px-5" v-marked="$t('eventlist.help')"></div>
                <hdv-event-list class="vlab-home--card" />
            </v-col>
            <v-col class="pa-0" cols="12" lg="5" sm="6">
                <router-view></router-view>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'
import HdvEventList from '@/components/event-list/HdvEventList'

export default {
    name: 'event-list',
    components: {
        HdvEventList,
        VlabBaseCardTitle
    }
}
</script>
