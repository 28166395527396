<template>
    <v-dialog v-model="show" max-width="500" :persistent="loading" scrollable>
        <template #activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" small rounded color="primary" class="ml-2">{{ $t('teamcategory.title') }}</v-btn>
        </template>
        <v-card>
            <vlab-base-card-title :title="$t('teamcategory.title')" :dismissible="!loading" @close="show = false" hide-spacer>
                <template #buttons>
                    <v-spacer />
                    <v-btn v-if="!selected" small rounded color="primary" :disabled="loading" @click="add">{{ $t('base.add') }}</v-btn>
                    <v-icon v-if="selected" dark small class="mx-2" :disabled="loading" @click="selected = null">$vuetify.icons.baseLeft</v-icon>
                </template>
            </vlab-base-card-title>
            <v-card-text class="pa-0">
                <v-list v-show="!selected">
                    <v-list-item v-for="category in categories" :key="category.id" @click="select(category)">
                        <v-list-item-content>
                            <v-list-item-title>{{ category.name }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-icon>$vuetify.icons.baseRight</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>

                <hdv-base-empty-list :value="!loading && !categories.length" :text="$t('teamcategory.empty')" />
                <vlab-base-loader v-model="loading" />

                <v-card v-if="selected">
                    <v-form v-model="valid" @submit.stop.prevent="save" :disabled="loading">
                        <v-card-text>
                            <hdv-base-i18n-area :loading="loading">
                                <template #default="{ ln, isDefault }">
                                    <v-text-field
                                        v-model="selected.name_i18n[ln]"
                                        :rules="isDefault ? [$required] : []"
                                        :label="$t('teamcategory.name')"
                                        outlined />
                                </template>
                            </hdv-base-i18n-area>

                            <v-list dense :disabled="loading">
                                <v-subheader v-if="teams.length">{{ $t('teamcategory.teams') }}</v-subheader>
                                <v-list-item-group>
                                    <v-list-item v-for="team in teams" :key="team.id">
                                        <v-list-item-content>
                                            <v-list-item-title>{{ team.firstname }} {{ team.lastname }}</v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-btn small icon color="red" :disabled="loading" @click="removeLink(selected, team)">
                                                <v-icon small>$vuetify.icons.baseDelete</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list-item-group>
                                <hdv-base-empty-list :value="!loading && !teams.length" :text="$t('teamcategory.teamsEmpty')" />
                            </v-list>
                        </v-card-text>
                        <v-divider />
                        <v-card-actions>
                            <v-btn
                                block
                                rounded
                                color="primary"
                                type="submit"
                                :loading="loading"
                                :disabled="loading">
                                {{ $t('base.save') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'
import HdvBaseI18nArea from '@/components/base/HdvBaseI18nArea'
import HdvBaseEmptyList from '@/components/base/HdvBaseEmptyList'

export default {
    name: 'hdv-team-category-list',

    components: { VlabBaseCardTitle, HdvBaseI18nArea, HdvBaseEmptyList },

    data: () => ({
        loading: false,
        show: false,
        valid: false,
        selected: null,
        teams: []
    }),

    computed: {
        categories() {
            return this.$store.getters['team/categories']
        }
    },

    watch: {
        show(v) {
            if (v) {
                this.selected = null
            }
        }
    },

    mounted() {
        this.loading = true
        return this.$store.dispatch('team/categories')
            .catch(err => this.$err(err))
            .finally(() => (this.loading = false))
    },

    methods: {
        add() {
            this.teams = []
            this.selected = {
                name_i18n: {}
            }
        },

        select(category) {
            this.loading = true
            this.selected = null
            return this.$store.dispatch('team/getCategory', { id: category.id })
                .then(c => {
                    this.selected = c
                    this.teams = c.teams
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        save() {
            const promise = this.selected.id
                ? this.$store.dispatch('team/updateCategory', { id: this.selected.id, category: this.selected })
                : this.$store.dispatch('team/addCategory', { category: this.selected })

            this.loading = true
            return promise
                .then(() => {
                    this.selected = null
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        removeLink(category, team) {
            this.loading = true

            const category_ids = team.categories.map(c => c.id).filter(id => id !== category.id)

            return this.$store.dispatch('team/update', { id: team.id, team: { category_ids } })
                .then(() => {
                    const index = this.teams.findIndex(t => t.id === team.id)
                    if (index !== -1) {
                        this.teams.splice(index, 1)
                    }
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
