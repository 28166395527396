<template>
    <v-data-table
        :headers="headers"
        :items="jobs"
        :loading="loading">

        <template #top>
            <div class="d-flex mr-3">
                <v-spacer />
                <v-btn x-small color="error" @click="clean">{{ $t('queue.am.clean')}}</v-btn>
            </div>
        </template>

        <template #item.name="{ item }">
            {{ item.name }}
            <p v-if="item.error" class="error--text my-0">{{ item.error }}</p>
        </template>

        <template #item.image="{ item }">
            <v-icon small :color="item.data.with_images ? 'success' : 'error'">
                {{ item.data.with_images ? '$vuetify.icons.importImgYes' : '$vuetify.icons.importImgNo' }}
            </v-icon>
        </template>

        <template #item.started_at="{ item }">
            {{ displayTimestamp(item.started_at) }}
        </template>

        <template #item.ended_at="{ item }">
            {{ displayTimestamp(item.ended_at) }}
        </template>

        <template #item.progress="{ item }">
            <v-progress-linear
                height="20"
                :value="item.progress * 100"
                :color="item.error ? 'error' : (item.progress < 1 ? 'warning' : 'success')">
                <template #default>
                   <span class="hdv-job-progress--counter">{{ item.data.done }} / {{ item.data.total }}</span>
                </template>
            </v-progress-linear>
        </template>

        <template #item.actions="{ item }">
            <v-btn v-if="!item.ended_at && !item.error" x-small icon color="error" @click="removeJob(item.id)">
                <v-icon x-small>$vuetify.icons.baseDelete</v-icon>
            </v-btn>
        </template>

    </v-data-table>
</template>

<script>
export default {
    name: 'hdv-queue-jobs',

    props: {
        name: { type: String }
    },

    data: () => ({
        loading: false,
        interval: null,
        queue: null
    }),

    computed: {
        headers() {
            return [{
                text: this.$i18n.t('queue.am.name'),
                value: 'name'
            }, {
                text: this.$i18n.t('queue.am.image'),
                value: 'image',
                align: 'center',
                sortable: false
            }, {
                text: this.$i18n.t('queue.am.started_at'),
                value: 'started_at'
            }, {
                text: this.$i18n.t('queue.am.progress'),
                value: 'progress',
                sortable: false
            }, {
                text: this.$i18n.t('queue.am.ended_at'),
                value: 'ended_at'
            }, {
                text: '',
                value: 'actions',
                sortable: false
            }]
        },

        jobs() {
            return this.queue?.jobs || []
        },

        connected() {
            return this.$store.getters['auth/connected']
        }
    },

    watch: {
        connected: 'stop'
    },

    mounted() {
        return this.start()
    },

    beforeDestroy() {
        return this.stop()
    },

    methods: {
        displayTimestamp(created_at) {
            return this.$options.filters.date(created_at, 'LLL')
        },

        clean() {
            this.loading = true
            return this.$api.post(`/queues/${this.name}/clean`)
                .catch(err => this.$err(err))
                .then(() => this.load())
                .finally(() => (this.loading = false))
        },

        removeJob(jobId) {
            this.loading = true
            return this.$api.delete(`/queues/${this.name}/jobs/${jobId}`)
                .catch(err => this.$err(err))
                .then(() => this.load())
                .finally(() => (this.loading = false))
        },

        start() {
            this.interval = window.setInterval(() => this.load(), 5000)
            this.load(true)
        },

        load(showLoading) {
            this.loading = showLoading || false
            return this.$api.get(`/queues/${this.name}`)
                .then(res => res.data)
                .then(queue => (this.queue = queue))
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        stop() {
            window.clearInterval(this.interval)
        }
    }
}
</script>

<style lang="sass">
.hdv-job-progress--counter
    font-size: 10px !important
</style>
