<template>
    <v-dialog v-model="show" max-width="500" :persistent="loading">
        <v-card>
            <vlab-base-card-title :title="$t('salelotlist.removeTitle', { count: lotIds.length })" :dismissible="!loading" @close="show = false" />
            <v-card-text class="pt-4">
                {{ $t('salelotlist.removeHelp', { count: lotIds.length }) }}
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-btn
                    color="primary"
                    block
                    rounded
                    :disabled="loading"
                    :loading="loading"
                    @click="submit">
                    {{ $t('salelotlist.removeSubmit') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'

export default {
    name: 'hdv-sale-lot-remove-popup',

    components: { VlabBaseCardTitle },

    props: {
        value: { type: Boolean },
        saleId: { type: String },
        lotIds: { type: Array }
    },

    data: () => ({
        loading: false
    }),

    computed: {
        show: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },

    methods: {
        submit() {
            this.loading = true
            return Promise
                .allSettled(this.lotIds.map(lotId => this.$store.dispatch('lot/remove', { saleId: this.saleId, lotId })))
                .then(() => {
                    this.$success(this.lotIds, { message: this.$i18n.t('salelotlist.removed') })
                    this.$emit('remove:done', this.lotIds)
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
