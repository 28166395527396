<template>
    <v-text-field
        v-model="url"
        outlined
        :dense="dense"
        :disabled="disabled"
        :label="label || $t('content.apiUrl')" />
</template>

<script>

export default {
    name: 'hdv-content-api-url',
    props: {
        value: { type: String },
        label: { type: String },
        disabled: { type: Boolean },
        dense: { type: Boolean }
    },
    computed: {
        url: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    }
}
</script>
