<template>
    <v-switch :label="$t('contentpage.disabled')" v-model="disabled" />
</template>

<script>
export default {
    name: 'hdv-content-page-disabled',
    props: {
        value: { type: Boolean }
    },
    computed: {
        disabled: {
            get() {
                return !this.value
            },
            set(v) {
                this.$emit('input', !v)
            }
        }
    }
}
</script>
