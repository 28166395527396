<template>
    <v-app>
        <hdv-base-top-bar :right.sync="drawerRight" />
        <hdv-base-main-menu v-if="connected" />
        <hdv-base-menu v-model="drawerRight" />
        <v-main>
            <vlab-base-message />
            <vlab-base-env-marker />
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
import HdvBaseTopBar from '@/components/base/HdvBaseTopBar'
import HdvBaseMenu from '@/components/base/HdvBaseMenu'
import HdvBaseMainMenu from '@/components/base/HdvBaseMainMenu'
import VlabBaseMessage from '@/shared/components/base/VlabBaseMessage'
import VlabBaseEnvMarker from '@/shared/components/base/VlabBaseEnvMarker'

export default {
    name: 'App',
    components: {
        HdvBaseMenu,
        HdvBaseMainMenu,
        HdvBaseTopBar,
        VlabBaseMessage,
        VlabBaseEnvMarker
    },
    data: () => ({
        drawerRight: false
    }),
    computed: {
        connected() {
            return this.$store.getters['auth/connected']
        }
    },
    watch: {
        '$route.meta.bodyScroll': 'toggleHtmlScrollbar'
    },
    mounted() {
        // if lang is set in query, set as current language
        if (this.$route.query.lang) {
            this.$store.commit('ln/current', { current: this.$route.query.lang })
        }
        this.toggleHtmlScrollbar(this.$route.meta.bodyScroll)
    },
    methods: {
        toggleHtmlScrollbar(v) {
            const html = document.getElementsByTagName('html')[0]
            html.style.overflowY = v ? 'auto' : 'scroll'
        }
    }
}
</script>

<style lang="sass">
$primary: black
$breakpoint-tablet: 768px

div.v-application
    font-family: "Roboto", sans-serif

.vlab-home--card
    margin: 0 auto

.sidebar .v-navigation-drawer__content
    display: flex !important
    flex-direction: column !important
    padding-bottom: 20px !important

.v-card__title
    word-break: break-word !important

.vlab-image--full
    width: 100%

.v-subheader
    font-weight: bold

.vlab--btn
    font-weight: 800
    letter-spacing: 0.9px

.icon-append-outer--mt-1 .v-input__append-outer
    margin-top: 4px !important

.vlab-notification--update
    font-family: "Roboto", sans-serif
    position: fixed
    display: flex
    align-items: center
    bottom: 0
    left: 50%
    background-color: #FFFFFF
    justify-content: space-between
    color: #8B8B8B
    max-width: 100vw
    z-index: 9999
    padding: 10px 30px
    font-size: 0.8rem
    border-radius: 1px 1px 0 0
    box-shadow: 0 -3px 6px rgba(100, 100, 100, 0.3)
    line-height: 1rem

    button.button
        margin-left: 16px
        color: $primary

    &.button--loading
        color: transparentize($primary, 0.8)

        &:after
            border-color: $primary
            border-top-color: transparent

@media screen and (max-width: $breakpoint-tablet)
    .vlab-notification--update
        width: 100%

</style>
