<template>
    <v-autocomplete
        v-model="teams"
        :items="items"
        :loading="loading"
        :search-input.sync="filter"
        item-text="lastname"
        item-value="id"
        chips
        small-chips
        deletable-chips
        cache-items
        multiple
        hide-no-data
        outlined
        :label="$t('department.teams')">
        <template #selection="{ item }">
            <v-chip small close>{{ item.firstname }} {{ item.lastname }}</v-chip>
        </template>
        <template #item="{ item }">
            {{ item.firstname }} {{ item.lastname }}
        </template>
    </v-autocomplete>
</template>

<script>
import lodash from 'lodash'

export default {
    name: 'hdv-department-teams',
    props: {
        value: { type: Array },
        departmentId: { type: String }
    },
    data: () => ({
        loading: false,
        filter: null,
        items: []
    }),
    computed: {
        teams: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },
    watch: {
        filter: 'loadDebounce',
        departmentId: 'init'
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            if (this.teams) {
                this.loading = true
                return this.$store.dispatch('team/list', { ids: this.teams })
                    .then(teams => (this.items = teams))
                    .catch(err => this.$err(err))
                    .finally(() => (this.loading = false))
            }
        },

        load(filter) {
            if (filter && filter.length < 3) {
                return
            }
            this.loading = true
            return this.$store.dispatch('team/list', { search: filter })
                .then(teams => (this.items = teams))
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        loadDebounce: lodash.debounce(function(filter) {
            return this.load(filter)
        }, 500)
    }
}
</script>
