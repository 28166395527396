import EventList from '@/views/EventList'
import EventForm from '@/views/EventForm'

export default [
    {
        path: '/events',
        name: 'eventlist',
        component: EventList,
        props: true,
        meta: {
            title: 'event.menu'
        },
        children: [
            {
                path: ':id',
                name: 'eventform',
                component: EventForm,
                props: true,
                meta: {
                    title: 'event.form'
                }
            }
        ]
    }
]
