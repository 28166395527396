<template>
    <v-card flat>
        <vlab-base-card-title :title="$t('contentcategory.list')" :dismissible="false" hide-spacer>
            <template #buttons>
                <v-spacer />
                <v-btn rounded outlined dark small :to="{ name: 'contentcategoryform', params: { categoryId: 'add' } }">
                    {{ $t('base.add') }}
                </v-btn>
            </template>
        </vlab-base-card-title>
        <div class="my-5 px-5" v-marked="$t('contentcategory.help')"></div>
        <v-list>
            <hdv-base-actionable-list
                v-model="action"
                :total="categories.length"
                :selected.sync="selected"
                @close="selected = []"
                @remove="removeItems"
                :force-visible="true"
                :disabled="loading">
                <template v-if="selected" #remove>
                    <v-card-text>{{ $t('contentcategory.removeText', { count: selected.length, name: selected.length && selected[0].title }) }}</v-card-text>
                </template>
            </hdv-base-actionable-list>

            <v-list-item-group v-model="selected" :multiple="action">

                <template v-for="(category, i) in categories">
                    <hdv-content-category-list-item
                        :key="`${i}-${category.id}`"
                        :action="action"
                        :category="category"
                        @click="$emit('select', category)" />
                    <v-divider :key="i" />
                </template>

            </v-list-item-group>
            <hdv-base-empty-list :value="!loading && !categories.length" :text="$t('contentcategory.empty')" />
            <vlab-base-loader v-model="loading" />
        </v-list>
    </v-card>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'
import HdvContentCategoryListItem from '@/components/content-category-list/HdvContentCategoryListItem'
import HdvBaseEmptyList from '@/components/base/HdvBaseEmptyList'
import HdvBaseActionableList from '@/components/base/HdvBaseActionableList'

import lodash from 'lodash'

export default {
    name: 'hdv-content-category-list',
    components: { VlabBaseCardTitle, HdvContentCategoryListItem, HdvBaseEmptyList, HdvBaseActionableList },
    data: () => ({
        loading: false,
        search: null,
        filter: null,
        // actionable list
        action: false,
        selected: []
    }),
    computed: {
        categories() {
            return this.$store.getters['cat/categories']
        }
    },
    watch: {
        search: 'doSearch'
    },
    mounted() {
        this.load()
    },
    methods: {
        load() {
            this.loading = true
            return this.$store.dispatch('cat/list', {})
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        removeItems() {
            this.loading = true
            return this.$store.dispatch('cat/removeByIndexes', { indexes: this.selected })
                .then(res => {
                    this.selected = []
                    this.$success(res, { message: 'contentcategory.removed', count: this.selected.length })
                    this.action = false
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        doSearch: lodash.debounce(function() {
            this.filter = this.search
        }, 500)
    }
}
</script>
