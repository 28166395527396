<template>
    <v-btn
        depressed
        :block="block"
        :rounded="rounded"
        @click="onClick"
        class="acs-upload--btn"
        :color="dragover ? 'secondary' : 'primary'"
        ref="btn"
        :disabled="isDisabled"
        :loading="loading || uploading">
        {{ label }}
        <input
            ref="file"
            type="file"
            :multiple="multiple"
            :accept="acceptData"
            :name="name"
            @change="inputChange" />
    </v-btn>
</template>

<script>
import Axios from 'axios'
import lodash from 'lodash'

export default {
    name: 'acs-base-upload-btn',

    props: {
        // composant
        label: { type: String },
        disabled: { type: Boolean },
        block: { type: Boolean, default: true },
        rounded: { type: Boolean, default: false },
        autoStart: { type: Boolean, default: true },
        url: { type: String, required: true },
        loading: { type: Boolean },
        // input file HTML
        multiple: { type: Boolean, default: true },
        name: { type: String, default: 'file' },
        accept: {},
        // request
        params: { type: Object, default: () => ({}) },
        request: { type: Object, default: () => ({}) },
        axios: {}
    },

    data() {
        return {
            dropListener: e => this.onDrop(e),
            dragOverListener: e => this.onDragOver(e),
            dragOutListener: e => this.onDragOut(e),
            uploading: false,
            dragover: false,
            files: {}
        }
    },

    computed: {
        isDisabled() {
            return this.disabled || this.uploading
        },

        acceptData() {
            return this.accept && this.accept.join ? this.accept.join(',') : this.accept
        }
    },

    mounted() {
        const dom = this.$refs.btn.$el
        let a = ['dragenter', 'dragover']
        a.forEach(e => dom.addEventListener(e, this.dragOverListener, false))
        a = ['dragleave', 'drop']
        a.forEach(e => dom.addEventListener(e, this.dragOutListener, false))
        dom.addEventListener('drop', this.dropListener, false)
    },

    beforeDestroy() {
        const dom = this.$refs.btn.$el
        let a = ['dragenter', 'dragover']
        a.forEach(e => dom.removeEventListener(e, this.dragOverListener, false))
        a = ['dragleave', 'drop']
        a.forEach(e => dom.removeEventListener(e, this.dragOutListener, false))
        dom.removeEventListener('drop', this.dropListener, false)
    },

    methods: {
        onClick() {
            this.$refs.file.click()
        },

        onDrop(e) {
            e.preventDefault()
            e.stopPropagation()
            if (this.isDisabled || this.loading) {
                return
            }
            this.files = e.dataTransfer.files
            this.$emit('upload:change', this.files)
            this.upload()
        },

        onDragOver(e) {
            e.preventDefault()
            e.stopPropagation()
            if (this.isDisabled || this.loading) {
                return
            }
            this.dragover = true
        },

        onDragOut(e) {
            e.preventDefault()
            e.stopPropagation()
            this.dragover = false
        },

        inputChange() {
            if (!this.$refs.file.files.length) {
                return
            }
            this.files = this.$refs.file.files
            this.$emit('upload:change', this.files)
            if (this.autoStart) {
                this.upload()
            }
        },

        reset() {
            this.files = {}
            if (this.$refs.file) {
                this.$refs.file.value = ''
                this.$refs.file.files = null
            }
        },

        upload() {
            const files = Object.values(this.files).map((file, i) => ({
                id: i,
                file: file,
                params: this.params,
                lastModified: file.lastModified,
                lastModifiedDate: file.lastModifiedDate,
                name: file.name,
                size: file.size,
                type: file.type,
                progress: 0,
                active: false,
                success: false,
                error: false,
                pending: true,
                response: {}
            }))

            this.uploading = true
            this.$emit('upload:start', files)
            return files
                .reduce((promise, file) => {
                    return promise.then(() => this.uploadFile(file))
                }, Promise.resolve())
                .then(() => {
                    this.uploading = false
                    this.reset()
                    this.$emit('upload:end', files)
                    this.$emit('upload:finish')
                })
                .catch(err => {
                    this.uploading = false
                    this.reset()
                    this.$emit('upload:enderror', err)
                    this.$emit('upload:finish')
                    throw err
                })
        },

        uploadFile(file) {
            const form = new FormData()
            const self = this
            Object.entries(this.params).forEach(d => {
                form.append(d[0], d[1])
            })
            form.append('file', file.file)
            return (this.axios || Axios)
                .post(this.url, form, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress(ev) {
                        // ce progress semble être celui qui envoie les données
                        // au serveur, sans se soucier de savoir si le serveur
                        // a bien avancé lui-aussi dans son process. On arrive
                        // donc à 100% alors que le serveur tourne toujours de
                        // son coté pour uploader le doc dans l'OS
                        file.progress = parseInt(Math.round((ev.loaded * 100) / ev.total))
                        file.pending = false
                        file.active = true
                        self.$emit('upload:progress', file)
                    },
                    ...this.request
                })
                .then(res => {
                    file.response = res.data
                    // une fois terminé, on informe que le progress est terminé
                    file.active = false
                    file.success = true
                    file.error = false
                    this.$emit('upload:success', file)
                })
                .catch(err => {
                    file.response = err
                    file.active = false
                    file.success = false
                    file.error = true

                    const errorOptions = {}
                    if (lodash.get(err, 'response.data.error.code') === 'waitForJobCompletion') {
                        errorOptions.type = 'info'
                        errorOptions.timeout = 10000
                    }
                    this.$err(err, errorOptions)
                    this.$emit('upload:error', file)
                    throw err
                })
        }
    }
}
</script>

<style lang="sass" scoped>
.acs-upload--btn
    input[type=file]
        display: none

    button
        height: auto

</style>
