<template>
    <v-text-field
        class="mb-0"
        v-model="time"
        dense
        outlined
        :label="label + ' *'"
        :rules="[$required, $time(min, max), $neq(start, neqMsg)]"
        @keypress="sanitizeNumeric"
        @input="onChange" />
</template>

<script>
export default {
    name: 'hdv-event-timeslot',
    props: {
        value: { type: String },
        label: { type: String },
        min: { type: String },
        max: { type: String },
        disabled: { type: Boolean },
        start: { type: String },
        neqMsg: { type: String }
    },
    data: () => ({
        time: null
    }),
    mounted() {
        this.time = this.value
    },
    methods: {
        sanitizeNumeric(e) {
            if (!/^[0-9]$/.test(e.key)) {
                e.stopPropagation()
                e.preventDefault()
            }
        },

        update(v) {
            this.time = v
        },

        onChange(v) {
            if (this.time && this.time.length === 2) {
                this.time += ':'
            }
            if (this.time && this.time.length === 3 && !isNaN(this.time)) {
                this.time = `${this.time[0]}${this.time[1]}:${this.time[2]}`
            }
            const fn = this.$time(this.min, this.max)
            if (fn(this.time) === true) {
                this.$emit('input', this.time)
            }
        }
    }
}
</script>
