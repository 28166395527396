import moment from 'moment'

moment.fn.rangeDate = (from, to, formats) => {
    const s = moment(from)
    const e = moment(to)
    const d = [
        s.format('YYYY'),
        e.format('YYYY'),
        s.format('MM'),
        e.format('MM')
    ]
    const dates = {}
    formats = formats || {
        d: 'Do',
        dm: 'Do MMMM',
        dmy: 'Do MMMM YYYY'
    }
    if (from === to) {
        dates.start = s.format(formats.dmy)
        dates.end = null
    } else if (d[0] === d[1] && d[2] === d[3]) {
        dates.start = s.format(formats.d)
        dates.end = e.format(formats.dmy)
    } else if (d[0] === d[1] && d[2] !== d[3]) {
        dates.start = s.format(formats.dm)
        dates.end = e.format(formats.dmy)
    } else {
        dates.start = s.format(formats.dmy)
        dates.end = e.format(formats.dmy)
    }
    return dates
}
