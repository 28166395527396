<template>
    <v-autocomplete
        v-model="selected"
        :items="items"
        :loading="loading"
        :label="$t('Sélectionner une vacation')"
        item-text="title"
        item-value="id"
        outlined
    />
</template>

<script>
export default {
    name: 'hdv-sale-vacation-list-selector',

    props: {
        value: { type: String },
        saleId: { type: String }
    },

    data: () => ({
        loading: false,
        items: []
    }),

    computed: {
        selected: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },

    watch: {
        saleId(v) {
            if (v) {
                this.selected = null
                return this.load()
            }
        }
    },

    mounted() {
        return this.load()
    },

    methods: {
        load() {
            if (!this.saleId) {
                return
            }
            this.loading = true
            return this.$store.dispatch('sale/listVacations', { saleId: this.saleId })
                .then(items => (this.items = items))
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
