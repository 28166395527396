<template>
    <v-list three-line>
        <hdv-base-actionable-list
            v-model="action"
            :total="events.length"
            :selected.sync="selected"
            @close="selected = []"
            @remove="removeItems"
            :force-visible="!!search"
            :disabled="loading">
            <template v-if="selected" #remove>
                <v-card-text>{{ $t('event.removeText', { count: selected.length, name: selected.length && selected[0].name }) }}</v-card-text>
            </template>
            <template #buttons>
                <v-icon small
                    :disabled="loading || !selected.length"
                    class="mr-5"
                    color="white"
                    @click="duplicate">
                    $vuetify.icons.baseDuplicate
                </v-icon>
            </template>
            <template #search>
                <v-text-field
                    v-model="search"
                    hide-details
                    single-line
                    clearable
                    prepend-inner-icon="$vuetify.icons.baseSearch" />
            </template>
        </hdv-base-actionable-list>

        <v-pagination
            v-if="events.length"
            v-model="page"
            color="primary"
            class="my-4"
            :disabled="loading"
            :length="pages"
        />

        <v-list-item-group v-model="selected" :multiple="action">
            <template v-for="(event, i) in events">
                <hdv-event-list-item :key="`${i}-${event.id}`" :action="action" :event="event" />
                <v-divider :key="i" />
            </template>
        </v-list-item-group>
        <hdv-base-empty-list :value="!loading && !events.length" :text="$t('eventlist.empty')" />
        <vlab-base-loader v-model="loading" />
    </v-list>
</template>

<script>
import HdvEventListItem from '@/components/event-list/HdvEventListItem'
import HdvBaseEmptyList from '@/components/base/HdvBaseEmptyList'
import HdvBaseActionableList from '@/components/base/HdvBaseActionableList'
import lodash from 'lodash'

export default {
    name: 'hdv-event-list',
    components: { HdvEventListItem, HdvBaseEmptyList, HdvBaseActionableList },
    data: () => ({
        loading: false,
        search: null,
        filter: null,
        // actionable list
        action: false,
        selected: [],
        // pagination
        offset: 0,
        limit: 20,
        page: 1,
        total: 0
    }),
    computed: {
        events() {
            return this.$store.getters['res/events']
        },
        language() {
            return this.$store.getters['ln/current']
        },
        pages() {
            return Math.ceil(this.total / this.limit)
        }
    },
    watch: {
        search: 'doSearch'
    },
    mounted() {
        return this.load()
    },
    methods: {
        load() {
            this.loading = true
            return this.$store.dispatch('res/events', { search: this.search, offset: this.offset, limit: this.limit })
                .then(() => {
                    this.total = this.$store.getters['res/count']
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        removeItems() {
            this.loading = true
            return this.$store.dispatch('res/removeByIndexes', { indexes: this.selected })
                .then(res => {
                    this.selected = []
                    this.total = this.$store.getters['res/total']
                    this.$success(res, { message: 'event.removed' })
                    this.$router.push({ name: 'eventlist' })
                    this.action = false
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        duplicate() {
            this.loading = true
            return this.$store.dispatch('res/duplicateByIndexes', { indexes: this.selected, lng: this.language })
                .then(res => {
                    this.selected = []
                    this.$success({ data: { count: res.length } }, { message: 'event.duplicated' })
                    this.$router.push({ name: 'eventlist' })
                    this.action = false
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        doSearch: lodash.debounce(function() {
            this.load()
        }, 500)
    }
}
</script>
