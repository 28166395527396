<template>
    <v-text-field
        :value="value && value.join(', ')"
        outlined
        disabled
        :label="$t('team.tags')"
        persistent-hint
        :hint="$t('team.syncHint')" />
</template>

<script>

export default {
    name: 'hdv-team-tags',
    props: {
        value: { type: Array }
    }
}
</script>
