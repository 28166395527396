<template>
    <v-text-field
        v-model="search"
        :label="$t('salelotlist.search')"
        :disabled="disabled"
        append-icon="$vuetify.icons.baseSearch"
        @click:append="$emit('search', search)"
        @keypress.enter="$emit('search', search)"
        outlined />
</template>

<script>
export default {
    name: 'hdv-sale-lot-list-search',
    props: {
        value: { type: String },
        disabled: { type: Boolean }
    },
    computed: {
        search: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    }
}
</script>
