<template>
    <div>
        <v-text-field
            v-model="city"
            outlined
            :label="$t('event.city') + (required ? ' *' : '')"
            :rules="required ? [$required] : []"
        />

        <v-textarea
            v-model="streetLocal"
            outlined
            :rules="required ? [$required, $length(140)] : [$length(140)]"
            :label="$t('event.street') + (required ? ' * ' : '')"
            counter="140"
            rows="2"
        />

        <v-text-field
            v-model="zipLocal"
            outlined
            :rules="required ? [$required] : []"
            :label="$t('event.zip') + (required ? ' * ' : '')"
        />

        <hdv-base-markdown-editor
            v-model="infoLocal"
            :id="id"
            :label="$t('event.info')" />

    </div>
</template>

<script>
import HdvBaseMarkdownEditor from '@/components/base/HdvBaseMarkdownEditor'

export default {
    name: 'hdv-event-address',
    components: { HdvBaseMarkdownEditor },
    props: {
        id: { type: String },
        required: { type: Boolean },
        value: { type: String },
        street: { type: String },
        zip: { type: String },
        info: { type: String }
    },
    computed: {
        infoLocal: {
            get() {
                return this.info
            },
            set(v) {
                this.$emit('update:info', v)
            }
        },
        city: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },
        streetLocal: {
            get() {
                return this.street
            },
            set(v) {
                this.$emit('update:street', v)
            }
        },
        zipLocal: {
            get() {
                return this.zip
            },
            set(v) {
                this.$emit('update:zip', v)
            }
        }
    }
}
</script>
