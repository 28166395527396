<template>
    <v-menu
        offset-y
        :close-on-content-click="true"
        v-model="show">
        <template #activator="{ attrs }">
            <v-btn icon color="primary" v-bind="attrs" @click.stop.prevent="show = !show" :disabled="page.readonly">
                <v-icon>$vuetify.icons.baseMenuVert</v-icon>
            </v-btn>
        </template>
        <v-list dense>
            <v-list-item :to="{ name: 'contentpageform', params: { pageId: 'add', parent: page } }">
                <v-list-item-title>{{ $t('contentpagetree.add') }}</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'contentpageform', params: { pageId: page.id }}">
                <v-list-item-title>{{ $t('contentpagetree.edit') }}</v-list-item-title>
            </v-list-item>
            <v-divider />
            <vlab-base-message-list-item
                v-model="removing"
                @submit="emit('remove')"
                color="red"
                :label="$t('base.remove')"
                :message-title="$t('contentpagetree.remove')"
                :message-btn="$t('base.remove')"
                :message=" $t('contentpagetree.removeText', {
                    name: page.name
                })" />
        </v-list>
    </v-menu>
</template>

<script>
import VlabBaseMessageListItem from '@/shared/components/base/VlabBaseMessageListItem'

export default {
    name: 'hdv-content-page-tree-item-menu',
    components: { VlabBaseMessageListItem },
    props: {
        page: { type: Object }
    },
    data: () => ({
        removing: false,
        show: false
    }),
    watch: {
        removing(v) {
            this.show = v
        }
    },
    methods: {
        emit(event) {
            this.$emit(event)
            this.show = false
        }
    }
}
</script>
