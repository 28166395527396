import Vue from 'vue'
import lodash from 'lodash'

const toFrontStructure = tree => {
    if (Array.isArray(tree)) {
        tree.forEach((child, i) => {
            tree[i] = toFrontStructure(child)
        })

        return tree
    }

    tree.children = tree.children || []

    tree.children.forEach((child, i) => {
        tree.children[i] = toFrontStructure(child)
    })
    return tree
}

const getParent = (tree, child, parentItem) => {
    if (!Array.isArray(tree) && tree.id === child.id) {
        return (Array.isArray(parentItem) ? parentItem : parentItem.children) || []
    }

    const children = (Array.isArray(tree) ? tree : tree.children) || []

    for (let i = 0; i < children.length; i += 1) {
        const parent = getParent(children[i], child, tree)
        if (parent) {
            return parent
        }
    }
    return null
}

export default {
    namespaced: true,

    state: {
        pages: [],
        pagesFlat: []
    },
    getters: {
        pages: state => state.pages,
        pagesFlat: state => state.pagesFlat
    },
    mutations: {
        pages(state, data) {
            state.pages = data.pages
        },

        edit(state, data) {
            const parent = getParent(state.pages, data.page)
            const index = parent?.findIndex(child => child.id === data.page.id)
            if (parent && index !== -1) {
                data.page.products_count = parent[index].products_count
                parent.splice(index, 1, data.page)
            }
        },

        remove(state, data) {
            const parent = getParent(state.pages, data.page)
            if (parent) {
                const index = parent.findIndex(child => child.id === data.page.id)
                parent.splice(index, 1)
            }
        },

        reset(state) {
            state.pages = []
        }
    },
    actions: {
        async pages(context, data) {
            if (context.state.pages.length) {
                return context.state.pages
            }
            const res = await Vue.prototype.$api
                .get('/contents/pages', {
                    params: {
                        include_disabled: true
                    }
                })
                .catch(e => {
                    if (e.response?.status === 404) {
                        return { data: [] }
                    }
                    throw e
                })

            context.commit('pages', { pages: toFrontStructure(res.data) })
            return context.state.pages
        },

        async pagesFlat({ state }, data) {
            if (state.pagesFlat.length) {
                return state.pagesFlat
            }
            const res = await Vue.prototype.$api
                .get('/contents/pages', {
                    params: {
                        flat: true,
                        include_disabled: true
                    }
                })
                .catch(e => {
                    if (e.response?.status === 404) {
                        return { data: [] }
                    }
                    throw e
                })

            state.pagesFlat = res.data
            return state.pagesFlat
        },

        async get(context, { pageId }) {
            const res = await Vue.prototype.$api.get(`/contents/pages/${pageId}`, {
                meta: { lang: '*' },
                params: {
                    include_components: true
                }
            })
            return res.data
        },

        async add({ state }, { page, parent }) {
            const data = { ...page }
            data.parent_id = parent?.id || null

            const res = await Vue.prototype.$api.post('/contents/pages', {
                page: data
            })

            const p = Array.isArray(parent?.children) ? parent.children : state.pages
            p.push(toFrontStructure(res.data))

            return res.data
        },

        async edit(context, { pageId, page, moved }) {
            const data = { ...page }
            data.children = undefined
            await Vue.prototype.$api.put(`/contents/pages/${pageId}`, {
                page: data
            })

            data.children = page.children
            if (!moved) {
                context.commit('edit', { page: data })
            }
        },

        async remove(context, { pageId, page }) {
            await Vue.prototype.$api.delete(`/contents/pages/${pageId}`)
            context.commit('remove', { page })
        },

        sorts({ state }, data) {
            const pageIds = lodash.flatMapDeep(state.pages, function getIds(node) {
                const ids = [node.id]
                return Array.isArray(node.children) ? ids.concat(lodash.map(node.children, getIds)) : ids
            })

            return Vue.prototype.$api
                .post('/contents/pages-sort', {
                    page_ids: pageIds
                })
        },

        async addComponent(context, { pageId, component, components }) {
            const res = await Vue.prototype.$api.post(`/contents/pages/${pageId}/components`, component)

            components.push({ ...res.data })
            return res.data
        },

        async updateComponent(context, { pageId, componentId, component, components }) {
            await Vue.prototype.$api.put(`/contents/pages/${pageId}/components/${componentId}`, component)

            const index = components.findIndex(c => c.component_id === componentId)
            if (index !== -1) {
                components.splice(index, 1, {
                    ...components[index],
                    ...lodash.pick(component, ['content_id', 'params'])
                })
            }
        },

        async removeComponent(context, { pageId, componentId, components }) {
            await Vue.prototype.$api.delete(`/contents/pages/${pageId}/components/${componentId}`)

            const index = components.findIndex(c => c.component_id === componentId)
            if (index !== -1) {
                components.splice(index, 1)
            }
        },

        removeComponentByIndexes({ dispatch }, { pageId, components, indexes }) {
            const items = components.filter((_, i) => indexes.indexOf(i) !== -1).slice()
            return Promise.all(items.map(item => dispatch('removeComponent', { pageId, componentId: item.component_id, components })))
        },

        sortsComponents(context, { pageId, componentIds }) {
            return Vue.prototype.$api.post(`/contents/pages/${pageId}/components-sort`, { component_ids: componentIds })
        }
    }
}
