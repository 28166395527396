<template>
    <v-row justify="space-around" align="start">
        <v-col class="pb-0">
            <hdv-event-timeslot
                :disabled="disabled"
                v-model="start"
                :max="end2"
                :label="$t('event.start')" />
        </v-col>
        <v-col class="pb-0">
            <hdv-event-timeslot
                :disabled="disabled"
                v-model="end2"
                :start="start"
                :min="start"
                :neq-msg="$t('event.neqerror')"
                :label="$t('event.end')" />
        </v-col>
        <v-col class="flex-0" v-if="removable">
            <v-icon @click="$emit('remove')">$vuetify.icons.baseClose</v-icon>
        </v-col>
    </v-row>
</template>

<script>
import HdvEventTimeslot from '@/components/event/HdvEventTimeslot'

export default {
    name: 'hdv-event-timeslots',
    components: { HdvEventTimeslot },
    props: {
        value: { type: String },
        end: { type: String },
        disabled: { type: Boolean },
        removable: { type: Boolean }
    },
    computed: {
        start: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
                this.$emit('update')
            }
        },

        end2: {
            get() {
                return this.end
            },
            set(v) {
                this.$emit('update:end', v)
                this.$emit('update')
            }
        }
    }
}
</script>

<style lang="sass">
.flex-0
    flex: 0

</style>
