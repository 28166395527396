<template>
    <v-card>
        <v-tabs v-model="tab" centered text background-color="grey lighten-5">
            <v-tab :href="`#lots`">
                {{ $t(`salehighlight.titleLot`) }}
            </v-tab>
            <v-tab :href="`#items`">
                {{ $t(`salehighlight.titleItem`) }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item value="lots">
                <hdv-sale-highlight-lots :sale-id="saleId" :disabled="disabled" />
            </v-tab-item>
            <v-tab-item value="items">
                <hdv-sale-highlight-items :sale-id="saleId" :disabled="disabled" />
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>

<script>
import HdvSaleHighlightItems from '@/components/sale-highlight/HdvSaleHighlightItems'
import HdvSaleHighlightLots from '@/components/sale-highlight/HdvSaleHighlightLots'

export default {
    name: 'hdv-sale-highlight-tab',
    components: {
        HdvSaleHighlightItems,
        HdvSaleHighlightLots
    },
    props: {
        saleId: { type: [String, Number] },
        disabled: { type: Boolean }
    },
    data: () => ({
        tab: 'lots',
        loading: false
    }),
    mounted() {
        this.loading = true
        return this.$store.dispatch('sale/get', { id: this.saleId })
            .then(sale => {
                this.tab = sale.highlights_lots ? 'lots' : 'items'
            })
            .catch(err => this.$err(err))
            .finally(() => (this.loading = false))
    }
}
</script>
