<template>
    <v-text-field
        v-model="key"
        outlined
        :label="$t('event.key') + (required ? ' *' : '')"
        :rules="rules"
        :counter="length" />
</template>

<script>
export default {
    name: 'hdv-event-key',
    props: {
        value: { type: String },
        required: { type: Boolean },
        length: { type: [String, Number], default: 16 }
    },
    computed: {
        key: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },

        rules() {
            const rules = [this.$length(this.length)]
            if (this.required) {
                rules.push(this.$required)
            }
            return rules
        }
    }
}
</script>
