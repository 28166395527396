<template>
    <v-layout justify-center class="mt-5" v-if="loading">
        <v-progress-circular :color="colour" indeterminate />
    </v-layout>
</template>

<script>
import lodash from 'lodash'

export default {
    name: 'acs-base-loader',
    props: {
        value: { type: Boolean },
        colour: { type: String, default: 'primary' }
    },
    data: () => ({
        loading: false,
        stop: false
    }),
    watch: {
        value(v) {
            // astuce pour que le loading ne start pas immédiatement
            if (v) {
                this.stop = false
                return this.bounced()
            }
            this.stop = true
            this.loading = false
        }
    },
    methods: {
        bounced: lodash.debounce(function() {
            if (this.stop) {
                return
            }
            this.loading = true
        }, 500)
    }
}
</script>
