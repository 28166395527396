export default {
    namespaced: true,

    state: {
        groups: {},
        trigger: false
    },

    getters: {
        trigger: state => state.trigger,

        getErrors: state => name => state.groups[name] ? state.groups[name] : null
    },

    mutations: {
        addError(state, { name, error }) {
            state.groups[name] = error
            state.trigger = !state.trigger
        },

        resetError(state, { name }) {
            state.groups[name] = null
            state.trigger = !state.trigger
        }
    }
}
