<template>
    <v-list-item
        @click="e => !enabled && $emit('click', e)"
        :ripple="ripple"
        :class="{ 'vlab-actionable-active': enabled }"
        :to="!enabled && to ? to : null">
        <template #default="{ active }">
            <v-list-item-action v-if="enabled">
                <v-checkbox
                    :input-value="active"
                    color="primary" />
            </v-list-item-action>
            <slot></slot>
        </template>
    </v-list-item>
</template>

<script>
export default {
    name: 'hdv-base-actionable-list-item',
    props: {
        enabled: { type: Boolean },
        ripple: { type: Boolean, default: true },
        to: { type: [String, Object] }
    }
}
</script>

<style lang="sass">
.vlab-actionable-active .unactionable
    display: none
</style>
