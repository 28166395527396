<template>
    <v-dialog v-model="show" :max-width="maxWidth" :persistent="persistent">
        <template #activator="{ on }">
            <v-btn
                :color="color"
                :class="btnClass"
                rounded
                :outlined="!icon"
                primary
                :block="!icon"
                :icon="icon"
                v-on="on">
                <template v-if="!icon">{{ label }}</template>
                <v-icon v-else>$vuetify.icons.baseDelete</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-form v-model="valid" @submit.stop.prevent="submit" :disabled="persistent">
                <vlab-base-card-title :title="messageTitle" :color="color" @close="show = false" />
                <slot name="content">
                    <v-card-text v-marked="message"></v-card-text>
                </slot>
                <v-divider />
                <v-card-actions>
                    <v-btn
                        rounded outlined block
                        type="submit"
                        :color="color"
                        :disabled="!valid"
                        :loading="loading">
                        {{ messageBtn }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'

export default {
    name: 'acs-base-message-btn',
    components: { VlabBaseCardTitle },

    props: {
        maxWidth: { type: [String, Number], default: 400 },
        label: { type: String },
        color: { type: String, default: 'primary' },
        disabled: { type: Boolean },
        loading: { type: Boolean },
        icon: { type: Boolean },
        value: { type: Boolean, default: null },
        btnClass: { type: [String, Object] },
        messageTitle: { type: String },
        messageBtn: { type: String },
        message: { type: String }
    },

    data: () => ({
        valid: false,
        showLocal: false
    }),

    computed: {
        persistent() {
            return this.loading || this.disabled
        },

        show: {
            get() {
                if (this.value === null) {
                    return this.showLocal
                }
                return this.value
            },
            set(v) {
                if (this.value === null) {
                    this.showLocal = v
                    return
                }
                this.$emit('input', v)
            }
        }
    },

    methods: {
        async submit() {
            this.$emit('submit')
            await this.$nextTick()
            this.show = false
        }
    }
}
</script>
