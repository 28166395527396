<template>
    <v-row>
        <v-col class="pr-0" cols="12" lg="4" sm="6">
            <hdv-content-page-tree :disabled="disabled" />
        </v-col>
        <v-col class="px-0" cols="12" lg="8" sm="6">
            <router-view></router-view>
        </v-col>
    </v-row>
</template>

<script>
import HdvContentPageTree from '@/components/content-page-tree/HdvContentPageTree'

export default {
    name: 'content-page',
    components: {
        HdvContentPageTree
    },
    props: {
        disabled: { type: Boolean }
    }
}
</script>
