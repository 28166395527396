<template>
    <v-text-field
        v-model="title"
        outlined
        :label="$t('contentstory.title') + (required ? '*' : '')"
        :rules="required ? [$required] : []" />
</template>

<script>

export default {
    name: 'hdv-content-story-title',
    props: {
        value: { type: String },
        required: { type: Boolean }
    },
    computed: {
        title: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    }
}
</script>
