<template>
    <hdv-base-container>
        <v-card max-width="500" flat>
            <v-card-text>Bienvenue tout plein</v-card-text>
        </v-card>
    </hdv-base-container>
</template>

<script>
import HdvBaseContainer from '@/components/base/HdvBaseContainer'

export default {
    name: 'home',
    components: {
        HdvBaseContainer
    }
}
</script>
