<template>
    <v-card flat>
        <vlab-base-card-title :title="$t('department.list')" :dismissible="false" hide-spacer>
            <template #buttons>
                <v-spacer />
                <v-btn rounded outlined dark small :to="{ name: 'departmentform', params: { departmentId: 'add' } }">
                    {{ $t('base.add') }}
                </v-btn>
            </template>
        </vlab-base-card-title>
        <div class="my-5 px-5" v-marked="$t('department.help')"></div>
        <v-list>
            <hdv-base-actionable-list
                v-model="action"
                :total="departments.length"
                :selected.sync="selected"
                @close="selected = []"
                @remove="removeItems"
                :force-visible="true"
                :disabled="loading">
                <template v-if="selected" #remove>
                    <v-card-text>{{ $t('department.removeText', { count: selected.length, name: selected.length && selected[0].title }) }}</v-card-text>
                </template>
                <template #search>
                    <v-text-field
                        v-model="search"
                        hide-details
                        single-line
                        clearable
                        prepend-inner-icon="$vuetify.icons.baseSearch" />
                </template>
            </hdv-base-actionable-list>

            <v-pagination
                v-if="departments.length"
                v-model="page"
                color="primary"
                class="my-4"
                :disabled="loading"
                :length="pages"
            />

            <v-list-item-group v-model="selected" :multiple="action">
                <template v-for="(department, i) in departments">
                    <hdv-department-list-item
                        :key="`${i}-${department.id}`"
                        :action="action"
                        :department="department"
                        @click="$emit('select', department)" />
                    <v-divider :key="i" />
                </template>

            </v-list-item-group>
            <hdv-base-empty-list :value="!loading && !departments.length" :text="$t('department.empty')" />
            <vlab-base-loader v-model="loading" />
        </v-list>
    </v-card>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'
import HdvDepartmentListItem from '@/components/department-list/HdvDepartmentListItem'
import HdvBaseEmptyList from '@/components/base/HdvBaseEmptyList'
import HdvBaseActionableList from '@/components/base/HdvBaseActionableList'

import lodash from 'lodash'

export default {
    name: 'hdv-content-faq-list',
    components: { VlabBaseCardTitle, HdvDepartmentListItem, HdvBaseEmptyList, HdvBaseActionableList },
    data: () => ({
        loading: false,
        search: null,
        departments: [],
        // actionable list
        action: false,
        selected: [],
        // pagination
        limit: 20,
        page: 1,
        total: 0
    }),
    computed: {
        language() {
            return this.$store.getters['ln/current']
        },

        pages() {
            return Math.ceil(this.total / this.limit)
        }
    },
    watch: {
        search: 'doSearch',
        page: 'load'
    },
    mounted() {
        this.load()
    },
    methods: {
        load() {
            this.loading = true
            const start = (this.page - 1) * this.limit
            return this.$store.dispatch('dp/list', { offset: start, limit: this.limit, search: this.search })
                .then(({ items, total }) => {
                    this.departments = items
                    this.total = total
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        removeItems() {
            this.loading = true
            return this.$store.dispatch('dp/removeByIndexes', { indexes: this.selected })
                .then(res => {
                    this.selected = []
                    this.$success(res, { message: 'department.removed', count: this.selected.length })
                    this.action = false
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        doSearch: lodash.debounce(function() {
            if (this.page > 1) {
                this.page = 1 // load will be done by page watcher
                return
            }
            this.load()
        }, 500)
    }
}
</script>
