<template>
    <hdv-base-container>
        <v-card :loading="loading" max-width="500" flat class="centered">
            <v-form v-model="valid" @submit.prevent.stop="login" :disabled="loading">
                <v-img src="/img/logo-fr.svg" />
                <v-card-text>
                    <vlab-user-email-field v-model="user.email" />
                    <vlab-user-password-field v-model="user.password" required disableEntropy />
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        type="submit"
                        block
                        rounded
                        color="primary"
                        :disabled="!valid"
                        :loading="loading">
                        {{ $t('login.submit') }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </hdv-base-container>
</template>

<script>
import HdvBaseContainer from '@/components/base/HdvBaseContainer'
import VlabUserEmailField from '@/shared/components/user/VlabUserEmailField'
import VlabUserPasswordField from '@/shared/components/user/VlabUserPasswordField'

export default {
    name: 'login',
    components: {
        HdvBaseContainer,
        VlabUserEmailField,
        VlabUserPasswordField
    },
    data: () => ({
        loading: false,
        valid: false,
        user: {
            email: null,
            password: null
        }
    }),
    methods: {
        login() {
            this.loading = true
            return this.$store.dispatch('auth/getToken', { user: this.user })
                .then(() => this.$store.dispatch('auth/loadUser', { check_role: 'admin' }))
                .then(() => (this.$router.push('/')))
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>

<style lang="sass">
.centered
    margin: 0 auto
</style>
