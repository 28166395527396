<template>
    <v-card>
        <v-form v-model="valid" @submit.prevent.stop="submit" :disabled="loading">
            <vlab-base-card-title :title="$t('content.form')" :dismissible="!loading" @close="back" />
            <v-card-text v-if="content">

                <hdv-content-publication-date v-model="content.publication_date" />
                <hdv-content-category v-model="content.category_id" />

                <hdv-base-alias-field v-model="content.alias" />

                <hdv-base-i18n-area>
                    <template #default="{ ln, isDefault }">
                        <hdv-content-slug v-model="content.slug_i18n[ln]" />

                        <hdv-content-title
                            v-model="content.title_i18n[ln]"
                            @input="v => onTitleChange(v, isDefault)"
                            :required="isDefault" />

                        <hdv-content-description
                            v-model="content.content_i18n[ln]"
                            :id="content.id" />
                    </template>
                </hdv-base-i18n-area>

                <hdv-content-api-url v-model="content.api_url" />

                <hdv-content-param-definition v-model="content.params_definitions" />

            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    rounded
                    color="primary"
                    type="submit"
                    :disabled="!valid"
                    :loading="loading">
                    {{ $t('base.save') }}
                </v-btn>
                <v-spacer />
            </v-card-actions>

            <v-card-text v-if="content && content.id">
                <v-btn
                    text
                    color="primary"
                    @click="addBlock()">
                    {{ $t('content.addblock') }}
                </v-btn>
                <div class="d-flex flex-wrap">
                    <div v-for="(block, i) in content.blocks" :key="block.id">
                        <hdv-content-block
                            :block-data="block"
                            :block-id="block.id"
                            :content-id="content.id"
                            @remove="removeBlock(block.id, i)" />
                    </div>
                </div>
            </v-card-text>
        </v-form>
    </v-card>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'
import HdvBaseI18nArea from '@/components/base/HdvBaseI18nArea'
import HdvContentSlug from '@/components/content/HdvContentSlug'
import HdvContentTitle from '@/components/content/HdvContentTitle'
import HdvContentApiUrl from '@/components/content/HdvContentApiUrl'
import HdvContentDescription from '@/components/content/HdvContentDescription'
import HdvContentPublicationDate from '@/components/content/HdvContentPublicationDate'
import HdvContentParamDefinition from '@/components/content/HdvContentParamDefinition'
import HdvContentCategory from '@/components/content/HdvContentCategory'
import HdvContentBlock from '@/components/content/HdvContentBlock'
import HdvBaseAliasField from '@/components/base/HdvBaseAliasField'

import lodash from 'lodash'

export default {
    name: 'content-form',
    components: {
        VlabBaseCardTitle,
        HdvBaseI18nArea,
        HdvContentPublicationDate,
        HdvContentParamDefinition,
        HdvContentCategory,
        HdvContentSlug,
        HdvContentTitle,
        HdvContentApiUrl,
        HdvContentDescription,
        HdvContentBlock,
        HdvBaseAliasField
    },
    props: {
        contentId: { type: String }
    },
    data: () => ({
        loading: false,
        valid: false,
        content: null
    }),
    computed: {
        editMode() {
            return this.contentId !== 'add'
        }
    },
    watch: {
        contentId: 'load'
    },
    mounted() {
        this.load()
    },
    methods: {
        load() {
            this.loading = true
            return Promise
                .all([
                    this.editMode ? this.$store.dispatch('ct/get', { contentId: this.contentId }) : null
                ])
                .then(data => {
                    this.content = data[0] || {
                        id: null,
                        publication_date: new Date(),
                        slug_i18n: {},
                        title_i18n: {},
                        content_i18n: {},
                        params_definitions: [],
                        blocks: []
                    }
                    if (this.content.id && this.content.group && !this.content.blocks.length) {
                        const group = this.$store.getters['ct/groups'].find(g => g.value === this.content.group)
                        if (group && group.blockConfig) {
                            group.blockConfig.forEach(c => this.addBlock(c))
                        }
                    }
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        submit() {
            this.loading = true
            const action = this.editMode
                ? 'ct/update'
                : 'ct/add'

            return this.$store.dispatch(action, { contentId: this.content.id, content: lodash.omit(this.content, ['blocks']) })
                .then(content => {
                    this.$success(content, { message: 'content.saved' })
                    if (!this.editMode) {
                        return this.$router.push({ name: 'contentform', params: { contentId: content.id } })
                    }
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        onTitleChange(value, isDefault) {
            if (isDefault) {
                this.content.title = value
            }
        },

        addBlock(defaults = {}) {
            this.content.blocks.push({
                id: null,
                title_i18n: {},
                description_i18n: {},
                internal_slug_i18n: {},
                ...defaults
            })
        },

        async removeBlock(blockId, i) {
            // pas d'id, on supprime simplement du tableau
            if (!blockId) {
                this.content.blocks.splice(i, 1)
                return
            }
            this.loading = true
            try {
                // suppression du serveur
                await this.$store.dispatch('ct/removeBlock', {
                    contentId: this.content.id,
                    blockId: blockId
                })
                const index = this.content.blocks.findIndex(b => b.id === blockId)
                if (index !== -1) {
                    this.content.blocks.splice(index, 1)
                }
            } catch (err) {
                this.$err(err)
            } finally {
                this.loading = false
            }
        },

        back() {
            this.$router.push({ name: 'content' })
        }
    }
}
</script>
