<template>
    <v-app-bar
        v-if="title || title === ''"
        app
        flat
        :color="connected ? undefined : 'transparent'"
        light
        :class="headerCls">
        <v-btn icon v-if="back" :to="getTo(true)" @click="backFn" class="vlab-back-btn">
            <v-icon>$vuetify.icons.baseBack</v-icon>
        </v-btn>

        <v-spacer />
        <v-toolbar-title>{{ $tu(title, { ln: ln, ...titleParams }) }}</v-toolbar-title>
        <v-spacer />

        <hdv-queue-btn v-if="connected" />
        <v-btn v-if="menu" icon @click="$emit('update:right', !right)">
            <v-icon>$vuetify.icons.baseMenu</v-icon>
        </v-btn>
    </v-app-bar>
</template>

<script>
import HdvQueueBtn from '@/components/queue/HdvQueueBtn'

export default {
    name: 'hdv-base-top-bar',
    components: { HdvQueueBtn },
    props: {
        right: { type: Boolean }
    },
    data: () => ({
        title: '',
        back: null,
        backFn: () => null,
        menu: false
    }),
    computed: {
        btitle() {
            return this.$store.getters['top/title']
        },
        bback() {
            return this.$store.getters['top/back']
        },
        ln() {
            return this.$store.getters['ln/current']
        },
        titleParams() {
            return this.$store.getters['top/params']
        },
        reload() {
            return this.$store.getters['top/reload']
        },
        connected() {
            return this.$store.getters['auth/connected']
        },
        headerCls() {
            return this.$store.getters['cls/header']
        }
    },
    watch: {
        $route(to) {
            if (this.$route.hash) {
                return
            }
            this.applyMeta(to.meta || {})
        },
        btitle(title) {
            this.title = title
        },
        bback(back) {
            this.back = back
        },
        reload() {
            this.applyCurrent()
        }
    },
    created() {
        this.applyCurrent()
    },
    methods: {
        applyCurrent() {
            this.applyMeta(this.$router.currentRoute.meta || {})
        },

        applyMeta(meta) {
            this.$store.commit('top/title', {
                title: meta.title,
                params: Object.entries(meta.titleParams || {}).reduce((acc, v) => {
                    acc[v[0]] = this.$store.getters[v[1]]
                    return acc
                }, {}),
                ext: !!meta.titleCmp
            })
            this.title = meta.title
            this.back = meta.back
            this.backFn = () => null
            this.menu = meta.menu !== false
            if (typeof this.getTo() === 'function') {
                this.backFn = this.getTo()
            }
        },

        getTo(strict) {
            if (typeof this.back === 'string') {
                return { name: this.back }
            }
            if (typeof this.back === 'function') {
                const b = this.back({ store: this.$store, router: this.$router, route: this.$route, cmp: this })
                if (strict && typeof b === 'function') {
                    return null
                }
                return b
            }
        }
    }
}
</script>

<style lang="sass">
.v-toolbar__title, .v-toolbar .v-toolbar__title
    font-size: 16px

.vlab-back-btn.v-btn--active::before
    opacity: 0 !important

.v-toolbar.hdv-scrollable
    display: none

</style>
