<template>
    <v-card flat>
        <vlab-base-card-title :title="$t('content.list')" :dismissible="false" hide-spacer>
            <template #buttons>
                <v-spacer />
                <v-btn rounded outlined dark small :to="{ name: 'contentform', params: { contentId: 'add' } }">
                    {{ $t('base.add') }}
                </v-btn>
            </template>
        </vlab-base-card-title>
        <div class="my-5 px-5" v-marked="$t('content.help')"></div>
        <v-list two-line>
            <hdv-base-actionable-list
                v-model="action"
                :total="contents.length"
                :selected.sync="selected"
                @close="selected = []"
                @remove="removeItems"
                :force-visible="true"
                :disabled="loading">
                <template v-if="selected" #remove>
                    <v-card-text>{{ $t('content.removeText', { count: selected.length, name: selected.length && selected[0].title }) }}</v-card-text>
                </template>
                <template #search>
                    <v-text-field
                        v-model="search"
                        hide-details
                        single-line
                        clearable
                        prepend-inner-icon="$vuetify.icons.baseSearch" />
                </template>
            </hdv-base-actionable-list>

            <v-pagination
                v-if="contents.length"
                v-model="page"
                color="primary"
                class="my-4"
                :disabled="loading"
                :length="pages"
            />

            <v-list-item-group v-model="selected" :multiple="action">
                <template v-for="(content, i) in contents">
                    <hdv-content-list-item :key="`${i}-${content.id}`" :action="action" :content="content" />
                    <v-divider :key="i" />
                </template>
            </v-list-item-group>
            <hdv-base-empty-list :value="!loading && !contents.length" :text="$t('content.empty')" />
            <vlab-base-loader v-model="loading" />
        </v-list>
    </v-card>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'
import HdvContentListItem from '@/components/content-list/HdvContentListItem'
import HdvBaseEmptyList from '@/components/base/HdvBaseEmptyList'
import HdvBaseActionableList from '@/components/base/HdvBaseActionableList'
import lodash from 'lodash'

export default {
    name: 'hdv-content-list',
    components: { VlabBaseCardTitle, HdvContentListItem, HdvBaseEmptyList, HdvBaseActionableList },
    data: () => ({
        loading: false,
        search: null,
        contents: [],
        page: 1,
        total: 0,
        limit: 20,
        // actionable list
        action: false,
        selected: []
    }),
    computed: {
        language() {
            return this.$store.getters['ln/current']
        },
        pages() {
            return Math.ceil(this.total / this.limit)
        }
    },
    watch: {
        search: 'doSearch',
        page: 'load'
    },
    mounted() {
        this.load()
    },
    methods: {
        load(v) {
            if (this.loading) {
                return
            }
            this.loading = true
            const start = (this.page - 1) * this.limit
            return this.$store.dispatch('ct/list', { offset: start, limit: this.limit, search: this.search })
                .then(({ contents, total }) => {
                    this.contents = contents
                    this.total = total
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        removeItems() {
            this.loading = true
            return this.$store.dispatch('ct/removeByIndexes', { indexes: this.selected, contents: this.contents })
                .then(res => {
                    this.$success(res, { message: 'content.removed', count: this.selected.length })
                    this.selected = []
                    this.action = false
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        doSearch: lodash.debounce(function() {
            this.page = 1
            this.load()
        }, 500)
    }
}
</script>
