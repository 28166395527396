<template>
    <v-card>
        <vlab-base-card-title :title="$t('department.form')" :dismissible="!loading" @close="back" />
        <v-tabs v-model="tab" centered text background-color="grey lighten-5">
            <v-tab :href="`#form`">
                {{ $t('department.formTab') }}
            </v-tab>
            <v-tab v-if="department && department.id" :href="`#lots`">
                {{ $t('department.highlightTab') }}
            </v-tab>
            <v-tab v-if="department && department.id" :href="`#seo`">
                {{ $t('seo.menu') }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-if="department" v-model="tab">
            <v-tab-item value="form">
                <v-card>
                    <v-form ref="form" v-model="valid" @submit.prevent.stop="submit" :disabled="loading">
                        <v-card-text>
                            <hdv-base-i18n-area :loading="loading">
                                <template #default="{ ln, isDefault }">
                                    <hdv-department-title
                                        v-model="department.title_i18n[ln]"
                                        @input="v => onTitleChange(v, isDefault)"
                                        :required="isDefault"
                                        :name="`title_i18n-${ln}`" />

                                    <hdv-base-slug-field
                                        v-model="department.slug_i18n[ln]"
                                        :required="isDefault"
                                        :auto-fill="department.title_i18n[ln]"
                                        :name="`slug_i18n-${ln}`" />

                                    <hdv-department-description
                                        v-model="department.content_i18n[ln]"
                                        :id="departmentId" />
                                </template>
                            </hdv-base-i18n-area>

                            <hdv-department-tags v-model="department.tags" :department-id="department.id" />

                            <hdv-department-teams v-model="department.team_ids" :department-id="department.id" />

                            <hdv-base-image
                                ref="upload"
                                :label="$t(`department.image`)"
                                :auto-start="!!department.id"
                                :preview.sync="department.image_url_preview"
                                :src.sync="department.image_url"
                                :url="`/departments/${department.id}/image`"
                                @loaded="v => loading = v" />

                            <hdv-base-image
                                ref="uploadPhoto"
                                :label="$t(`department.photo`)"
                                :auto-start="!!department.id"
                                :preview.sync="department.photo_url_preview"
                                :src.sync="department.photo_url"
                                :url="`/departments/${department.id}/photo`"
                                @loaded="v => loading = v" />

                        </v-card-text>
                        <v-card-actions>
                            <v-spacer />
                            <v-btn
                                rounded
                                color="primary"
                                type="submit"
                                :disabled="!valid"
                                :loading="loading">
                                {{ $t('base.save') }}
                            </v-btn>
                            <v-spacer />
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-tab-item>
            <v-tab-item  v-if="department.id" value="lots">
                <hdv-department-highlight
                    :department-id="department.id"
                    :tags="department.tags"
                    :lots="department.highlights" />
            </v-tab-item>
            <v-tab-item  v-if="department.id" value="seo">
                <v-card>
                    <v-form v-model="validSeo" @submit.stop.prevent="submitSeo" ref="formSeo">
                        <v-card-text>
                            <hdv-seo-form
                                ref="seo"
                                type="department"
                                :id="department.id"
                                @loading="v => loading = v"
                            />
                        </v-card-text>
                        <v-card-actions>
                            <v-btn rounded block color="primary" type="submit" :disabled="!validSeo" :loading="loading">
                                {{ $t('base.save') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'
import HdvBaseI18nArea from '@/components/base/HdvBaseI18nArea'
import HdvBaseSlugField from '@/components/base/HdvBaseSlugField'
import HdvDepartmentTitle from '@/components/department/HdvDepartmentTitle'
import HdvDepartmentDescription from '@/components/department/HdvDepartmentDescription'
import HdvDepartmentTags from '@/components/department/HdvDepartmentTags'
import HdvDepartmentTeams from '@/components/department/HdvDepartmentTeams'
import HdvDepartmentHighlight from '@/components/department-highlight/HdvDepartmentHighlight'
import HdvBaseImage from '@/components/base/HdvBaseImage'
import HdvSeoForm from '@/components/seo/HdvSeoForm'

export default {
    name: 'Department-form',
    components: {
        VlabBaseCardTitle,
        HdvBaseI18nArea,
        HdvBaseSlugField,
        HdvDepartmentTitle,
        HdvDepartmentDescription,
        HdvDepartmentTags,
        HdvDepartmentTeams,
        HdvBaseImage,
        HdvDepartmentHighlight,
        HdvSeoForm
    },
    props: {
        departmentId: { type: String }
    },
    data: () => ({
        tab: 'form',
        loading: false,
        valid: false,
        validSeo: false,
        department: null
    }),
    computed: {
        editMode() {
            return this.departmentId !== 'add'
        }
    },
    watch: {
        $route: 'load'
    },
    mounted() {
        this.load()
    },
    methods: {
        load() {
            this.loading = true
            return Promise
                .all([
                    this.editMode ? this.$store.dispatch('dp/get', { departmentId: this.departmentId }) : null
                ])
                .then(data => {
                    this.department = data[0] || {
                        id: null,
                        slug_i18n: {},
                        title_i18n: {},
                        content_i18n: {}
                    }
                    this.department.team_ids = (this.department.teams || []).map(team => team.id)
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        submit() {
            this.loading = true
            const action = this.editMode
                ? 'dp/update'
                : 'dp/add'

            return this.$store.dispatch(action, { departmentId: this.department.id, department: this.department, image: this.image })
                .then(async department => {
                    const add = !this.editMode
                    if (add) {
                        this.department.id = department.id
                        await Promise.all([
                            this.$refs.upload.upload(),
                            this.$refs.uploadPhoto.upload()
                        ])
                    }
                    this.$success(department, { message: 'department.saved' })
                    if (add) {
                        return this.$router.push({ name: 'departmentform', params: { departmentId: department.id } })
                    }
                })
                .catch(err => this.$err(err, null, this.$refs.form))
                .finally(() => (this.loading = false))
        },

        onTitleChange(value, isDefault) {
            if (isDefault) {
                this.department.title = value
            }
        },

        back() {
            this.$router.push({ name: 'department' })
        }
    }
}
</script>
