<template>
    <v-select
        v-model="selected"
        outlined
        :items="items"
        :label="$t('salespecial.online')"
        item-value="id"
        item-text="text"
        clearable />
</template>

<script>

export default {
    name: 'hdv-sale-special-online',
    props: {
        value: { type: Boolean }
    },
    computed: {
        selected: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },

        items() {
            return [
                { id: true, text: this.$i18n.t('sale.types.online') },
                { id: false, text: this.$i18n.t('sale.types.onsite') }
            ]
        }
    }
}
</script>

<style lang="sass">
.v-select-list .v-subheader
    height: auto
    padding-top: 0.3em
    padding-bottom: 0.3em
    background-color: #eee
    border-top: 1px solid #ccc
    border-bottom: 1px solid #ccc
</style>
