<template>
    <v-app>
        <v-main>
            <v-container fluid>
                <div class="main-content">
                    <div class="main-content-center">
                        <h4>Serveur inaccessible</h4>
                        <p>{{ errorMsg }}</p>
                        <p><v-btn @click="reload()" color="primary" text>Réessayer</v-btn></p>
                    </div>
                </div>
            </v-container>
        </v-main>
        <v-footer app>
            <v-spacer />
            <span>
                &copy;{{ year }},
                <a href="https://www.piguet.com" target="_blank">Piguet HDV</a>
            </span>
        </v-footer>
    </v-app>
</template>

<script>
import moment from 'moment'

export default {
    name: 'hdv-error',
    data: () => ({
        year: moment().format('YYYY')
    }),
    computed: {
        errorMsg() {
            return this.currentError.message
        }
    },
    mounted() {
        // eslint-disable-next-line
        console.warn(this.currentError)
    },
    methods: {
        reload() {
            window.location.reload()
        }
    }
}
</script>
