<template>
    <div class="hdv-menu-secondary pa-0">
        <v-btn
            class="hdv-menu-secondary--btn"
            fab
            x-small
            absolute
            top
            right
            dark
            @click="showContent = !showContent">
            <v-icon small>{{ showContent ? '$vuetify.icons.baseMenuLeft' : '$vuetify.icons.baseMenuRight' }}</v-icon>
        </v-btn>
        <div v-show="showContent">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'hdv-base-secondary-menu',
    data: () => ({
        showContent: true
    })
}
</script>

<style lang="sass">

.hdv-menu-secondary
    position: relative
    max-width: 250px
    border-right: 1px solid #ccc
    margin-top: -64px
    z-index: 199

    &--btn
        top: 16px !important
        right: -16px !important

</style>
