<template>
    <v-select
        v-model="type"
        outlined
        item-text="title"
        item-value="id"
        :disabled="disabled"
        :dense="dense"
        :items="items"
        :label="$t('contentblock.type') + ' *'"
        :rules="[$required]">
        <template #item="{ item }">
            {{ $t(`contentblock.types.${item.id}`) }}
        </template>
        <template #selection="{ item }">
            {{ $t(`contentblock.types.${item.id}`) }}
        </template>
    </v-select>
</template>

<script>

export default {
    name: 'hdv-content-block-type',
    props: {
        value: { type: String },
        disabled: { type: Boolean },
        dense: { type: Boolean }
    },
    computed: {
        type: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },
        items() {
            return this.$store.getters['ct/blockTypes']
        }
    }
}
</script>
