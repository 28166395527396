<template>
    <v-dialog v-model="show" max-width="900" :persistent="loading">
        <v-card>
            <v-form v-model="valid" @submit.prevent.stop="submit">
                <vlab-base-card-title :title="$t('salehighlight.title')" @close="show = false" :dismissible="!loading" />
                <v-card-text>
                    <p>{{ $t('salehighlight.help') }}</p>
                    <hdv-base-i18n-area>
                        <template #default="{ ln, isDefault }">
                            <hdv-sale-lot-description
                                v-model="descriptionLocal[ln]"
                                :required="isDefault"
                                :id="saleId" />
                        </template>
                    </hdv-base-i18n-area>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        rounded
                        color="primary"
                        type="submit"
                        :disabled="!valid"
                        :loading="loading">
                        {{ $t('salehighlight.submit') }}
                    </v-btn>
                    <v-spacer />
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'
import HdvBaseI18nArea from '@/components/base/HdvBaseI18nArea'
import HdvSaleLotDescription from '@/components/sale-lot/HdvSaleLotDescription'

export default {
    name: 'hdv-sale-highlight-popup',
    components: {
        VlabBaseCardTitle,
        HdvSaleLotDescription,
        HdvBaseI18nArea
    },
    props: {
        value: { type: Boolean },
        saleId: { type: [String, Number] },
        item: { type: Object },
        description: { type: Object }
    },
    data: () => ({
        valid: false,
        loading: false
    }),
    computed: {
        show: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },

        descriptionLocal: {
            get() {
                return this.description
            },
            set(v) {
                this.$emit('update:description', v)
            }
        }
    },
    methods: {
        submit() {
            this.loading = true
            return this.$store
                .dispatch('item/addItem', {
                    saleId: this.saleId,
                    nxItemId: this.item.nx_item_id,
                    description: this.descriptionLocal
                })
                .then(item => this.$store.dispatch('high/addItem', { itemId: item.id, saleId: this.saleId }))
                .then(data => {
                    this.$success(data, { message: this.$i18n.t('salehighlight.saved') })
                    this.show = false
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
