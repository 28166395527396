<template>
    <v-tabs
        v-model="tab"
        vertical
        background-color="grey lighten-4"
        class="mb-5">
        <v-tab v-for="ln in languages" :key="ln" :href="`#${ln}`" :class="tabErrorCls[ln]">
            <v-avatar tile size="20" class="mr-5">
                <v-img
                    :src="getFlagUrl(ln)"
                    :alt="ln"
                    :title="ln" />
            </v-avatar>
            {{ ln.toUpperCase() }}
        </v-tab>
        <v-tabs-items v-model="tab" class="pl-5">
            <v-tab-item v-for="ln in languages" :key="ln" :value="ln" class="pt-2">
                <slot name="default"
                    :ln="ln"
                    :is-default="ln === defaultLn"></slot>
            </v-tab-item>
        </v-tabs-items>
    </v-tabs>
</template>

<script>
export default {
    name: 'hdv-base-i18n-area',

    props: {
        loading: { type: Boolean }
    },

    data: () => ({
        tab: null,
        tabErrorCls: {}
    }),

    computed: {
        languages() {
            return this.$store.getters['ln/available']
        },
        defaultLn() {
            return this.$store.getters['ln/defaultLn']
        },
        formTrigger() {
            return this.$store.getters['formerr/trigger']
        }
    },

    watch: {
        formTrigger: 'getFormFieldErrorCls',
        loading() {
            this.$set(this, 'tabErrorCls', {})
        }
    },

    mounted() {
        this.tab = this.defaultLn
    },

    methods: {
        getFlagUrl(ln) {
            return `https://www.piguet.com/img/${ln}.png`
        },

        async getFormFieldErrorCls() {
            const form = this.getForm(this.$parent)
            if (!form) {
                this.$set(this, 'tabErrorCls', {})
                return
            }

            await this.$nextTick()

            const fields = form.inputs.filter(i => {
                const name = i.$parent?.$options?.propsData?.name || ''
                return name.indexOf('_i18n-') > 0
            })

            const hasErrorlangs = fields.reduce((acc, field) => {
                if (field.$el.className.indexOf('error--text') !== -1) {
                    const ln = field.$parent.$options.propsData.name.match(/-([a-zA-Z]{2})/)[1]
                    if (!acc.includes(ln)) {
                        acc.push(ln)
                    }
                }
                return acc
            }, [])

            hasErrorlangs.forEach(ln => this.$set(this.tabErrorCls, ln, 'error'))
        },

        getForm(el) {
            if (!el?.$el?.tagName) {
                return null
            }
            if (el.$el.tagName.toLowerCase() === 'form') {
                return el
            }
            return this.getForm(el.$parent)
        }
    }
}
</script>
