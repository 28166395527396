<template>
    <v-select
        v-model="type"
        :items="items"
        :label="$t('event.type') + ' *'"
        item-text="text"
        item-value="value"
        outlined
        :rules="[$required]" />
</template>

<script>
export default {
    name: 'hdv-event-type',
    props: {
        value: { type: String }
    },
    computed: {
        type: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },
        items() {
            return this.$store.getters['res/types'].map(type => ({
                value: type.id,
                text: this.$i18n.t(`event.types.${type.id}`)
            }))
        }
    }
}
</script>
