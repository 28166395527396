<template>
    <v-radio-group v-model="type">
        <template #label>
            <div>{{ $t('sale.highlights') }}</div>
        </template>
        <v-radio value="item">
            <template #label>
                <div>{{ $t('sale.highlightsItems') }}</div>
            </template>
        </v-radio>
        <v-radio value="lot">
            <template #label>
                <div>{{ $t('sale.highlightsLots') }}</div>
            </template>
        </v-radio>
    </v-radio-group>
</template>

<script>

export default {
    name: 'hdv-sale-highlights-lots',
    props: {
        value: { type: Boolean }
    },
    computed: {
        type: {
            get() {
                return this.value ? 'lot' : 'item'
            },
            set(v) {
                this.$emit('input', v === 'lot')
            }
        }
    }
}
</script>
