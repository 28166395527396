<template>
    <v-card flat>
        <vlab-base-card-title :title="$t('contentstory.list')" :dismissible="false" hide-spacer>
            <template #buttons>
                <v-spacer />
                <v-btn rounded outlined dark small :to="{ name: 'contentstoryform', params: { contentId: 'add' } }">
                    {{ $t('base.add') }}
                </v-btn>
            </template>
        </vlab-base-card-title>
        <div class="my-5 px-5" v-marked="$t('contentstory.help')"></div>
        <v-list two-line>
            <hdv-base-actionable-list
                v-model="action"
                :total="stories.length"
                :selected.sync="selected"
                @close="selected = []"
                @remove="removeItems"
                :force-visible="true"
                :disabled="loading">
                <template v-if="selected" #remove>
                    <v-card-text>{{ $t('contentstory.removeText', { count: selected.length, name: selected.length && selected[0].title }) }}</v-card-text>
                </template>

                <template #search>
                    <v-text-field
                        v-model="search"
                        hide-details
                        single-line
                        clearable
                        prepend-inner-icon="$vuetify.icons.baseSearch" />
                </template>
            </hdv-base-actionable-list>

            <v-list-item-group v-model="selected" :multiple="action">
                <template v-for="(content, i) in stories">
                    <hdv-content-story-list-item
                        :key="`${i}-${content.id}`"
                        :action="action"
                        :content="content"
                        :sorting="sorting"
                        @click="!sorting && $emit('select', content)" />
                    <v-divider :key="i" />
                </template>
            </v-list-item-group>

            <hdv-base-empty-list :value="!loading && !stories.length" :text="$t('contentstory.empty')" />
            <vlab-base-loader v-model="loading" />
        </v-list>
    </v-card>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'
import HdvContentStoryListItem from '@/components/content-story-list/HdvContentStoryListItem'
import HdvBaseEmptyList from '@/components/base/HdvBaseEmptyList'
import HdvBaseActionableList from '@/components/base/HdvBaseActionableList'

import lodash from 'lodash'

export default {
    name: 'hdv-content-story-list',
    components: { VlabBaseCardTitle, HdvContentStoryListItem, HdvBaseEmptyList, HdvBaseActionableList },
    props: {
        disabled: { type: Boolean }
    },
    data: () => ({
        loading: false,
        search: null,
        filter: null,
        sorting: false,
        dragOptions: {
            animation: 0,
            group: 'description',
            disabled: false,
            ghostClass: 'ghost'
        },
        // actionable list
        action: false,
        selected: []
    }),
    computed: {
        stories() {
            return this.$store.getters['story/stories']
        }
    },
    watch: {
        search: 'doSearch'
    },
    mounted() {
        this.load()
    },
    methods: {
        load() {
            this.loading = true
            return this.$store.dispatch('story/list', { search: this.search })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        removeItems() {
            this.loading = true
            return this.$store.dispatch('story/removeByIndexes', { indexes: this.selected })
                .then(res => {
                    this.selected = []
                    this.$success(res, { message: 'contentstory.removed', count: this.selected.length })
                    this.action = false
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        doSearch: lodash.debounce(function() {
            this.load()
        }, 500)
    }
}
</script>
