<template>
    <v-menu>
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs"></slot>
        </template>
        <v-list dense>
            <hdv-seo-popup v-if="seo" type="lot" :id="lot.id" :title="lot.item.title" activator>
                <template #activator="{ on, attrs }">
                    <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-action>
                            <v-icon small color="blue">$vuetify.icons.seo</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title class="blue--text">{{ $t('salelotlist.importWeb')}}: {{ $t('seo.header') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                </template>
                <template #advanced>
                    <div class="d-flex hdv-lot-images">
                        <hdv-seo-popup
                            v-for="(img, key) in lot.item.images"
                            :key="img.path"
                            type="image"
                            :id="img.id"
                            :title="$t('salelotlist.imageseo', { number: key + 1, title: lot.item.title })"
                            close-on-save>
                            <template #activator="{ on, attrs }">
                                <v-hover v-slot="{ hover }">
                                    <img
                                        v-on="on"
                                        v-bind="attrs"
                                        :src="img.path_preview | imagecrop({ w: 52, h: 52, f: 'cover' })"
                                        :style="{ borderColor: hover ? 'black' : 'white' }" />
                                </v-hover>
                            </template>
                        </hdv-seo-popup>
                    </div>
                </template>
            </hdv-seo-popup>
            <v-list-item @click="importLot('all', false)">
                <v-list-item-action>
                    <v-icon small>$vuetify.icons.importAll</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('salelotlist.importAll')}}: {{ $t('salevacations.importImgNo') }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-icon small color="red">$vuetify.icons.importImgNo</v-icon>
                </v-list-item-action>
            </v-list-item>
            <v-divider />
            <v-list-item @click="importLot('web', true)">
                <v-list-item-action>
                    <v-icon small>$vuetify.icons.importSale</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('salelotlist.importWeb')}}: {{ $t('salevacations.importImgYes') }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-icon small color="green">$vuetify.icons.importImgYes</v-icon>
                </v-list-item-action>
            </v-list-item>
            <v-list-item @click="importLot('web', false)">
                <v-list-item-action>
                    <v-icon small>$vuetify.icons.importSale</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('salelotlist.importWeb')}}: {{ $t('salevacations.importImgNo') }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-icon small color="red">$vuetify.icons.importImgNo</v-icon>
                </v-list-item-action>
            </v-list-item>
            <v-divider />
            <v-list-item :disabled="!web" @click="$emit('click:remove', lot)">
                <v-list-item-action>
                    <v-icon small color="red">$vuetify.icons.importSale</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title :class="{ 'red--text': web }">{{ $t('salelotlist.remove') }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-icon small color="red">$vuetify.icons.baseDelete</v-icon>
                </v-list-item-action>
            </v-list-item>
            <v-divider />
            <v-list-item :disabled="!web" @click="importLot('am', true)">
                <v-list-item-action>
                    <v-icon small>$vuetify.icons.importAm</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('salelotlist.importAm')}}: {{ $t('salevacations.importImgYes') }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-icon small color="green">$vuetify.icons.importImgYes</v-icon>
                </v-list-item-action>
            </v-list-item>
            <v-list-item :disabled="!web" @click="importLot('am', false)">
                <v-list-item-action>
                    <v-icon small>$vuetify.icons.importAm</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('salelotlist.importAm')}}: {{ $t('salevacations.importImgNo') }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-icon small color="red">$vuetify.icons.importImgNo</v-icon>
                </v-list-item-action>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import HdvSeoPopup from '@/components/seo/HdvSeoPopup'

export default {
    name: 'hdv-sale-lot-list-actions',
    components: { HdvSeoPopup },
    props: {
        lot: { type: Object },
        web: { type: Boolean },
        seo: { type: Boolean }
    },
    methods: {
        importLot(to, withImages) {
            this.$emit('click:import', {
                to,
                withImages
            })
        }
    }
}
</script>

<style lang="sass">
.hdv-lot-images
    max-width: 100%
    flex-wrap: wrap

    img
        cursor: pointer
        padding: 1px
        border-width: 2px
        border-style: solid
</style>
