<template>
    <hdv-base-markdown-editor
        v-model="text"
        :id="id"
        :label="$t('department.description')"
        :max="0"
        auto-height />
</template>

<script>
import HdvBaseMarkdownEditor from '@/components/base/HdvBaseMarkdownEditor'

export default {
    name: 'hdv-department-description',
    components: { HdvBaseMarkdownEditor },
    props: {
        value: { type: String },
        id: { type: String }
    },
    computed: {
        text: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    }
}
</script>
